import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Form, Input, Modal, Table, Typography, notification, Space } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import styled from "styled-components";

import { callN8n2, fetchWith401, fetchWithJsonRpc } from "../helpers/helpers";
import { history } from "../history";
import { apiPath, DateFormats } from "../constants";
import { DateCellRenderer } from "./CellRenderer";
import { PageError } from "./PageError";
import { AsyncSelect2 } from "./FormInput";

const { Title, Text } = Typography;

const cachedUserDisplayNames = new Map();
async function getUserDisplayName(id_user, history) {
  if (cachedUserDisplayNames.has(id_user)) {
    return Promise.resolve(cachedUserDisplayNames.get(id_user));
  }

  // @fixme use n8n api
  // admin.user.details.v1
  return fetchWith401(
    `${apiPath}/user?id=eq.${id_user}&select=name,surname,phone`,
    {
      headers: {
        Accept: "application/vnd.pgrst.object+json",
      },
    },
    null,
    history
  ).then((user) => {
    let displayName = `${user.surname || ""} ${user.name || ""}`.trim();
    if (!displayName) {
      displayName = "Пользователь";
    }
    displayName += ` (${user.phone})`;

    cachedUserDisplayNames.set(id_user, displayName);
    return displayName;
  });
}

function SelectUserDisplaySelectedItem({ id, onRemove, isDisabled }) {
  const [dispName, setDispName] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getUserDisplayName(id, history).then((disp) => {
      setDispName(disp);
      setIsLoaded(true);
    });
  }, [id]);

  return (
    <div>
      {isLoaded && (
        <>
          <Link to={`/users/${id}`}>{dispName}</Link>
          {!isDisabled && <Button type="link" icon={<CloseCircleOutlined />} onClick={onRemove} />}
        </>
      )}
    </div>
  );
}

const NoSelectedContainer = styled.div`
  margin-bottom: 15px;
`;

function SelectUserDisplaySelected({ items, maxItemsToDisplay, onRemove, isDisabled }) {
  if (!Array.isArray(items) || !items.length) {
    return (
      <NoSelectedContainer>
        <Text type="secondary">Не выбран</Text>
      </NoSelectedContainer>
    );
  }

  const itemsNumber = items.length;
  let visibleItems = items;
  if (maxItemsToDisplay) {
    visibleItems = _.take(items, maxItemsToDisplay);
  }

  return (
    <>
      {visibleItems.map((item) => (
        <SelectUserDisplaySelectedItem key={item} id={item} onRemove={() => onRemove(item)} isDisabled={isDisabled} />
      ))}
      {maxItemsToDisplay && itemsNumber > maxItemsToDisplay ? (
        <>
          <div>...</div>
          <div>(всего выбрано: {itemsNumber})</div>
          <br />
        </>
      ) : null}
    </>
  );
}

function SelectUserButton({ selected, isMultiple, onClick, isDisabled }) {
  const shouldDisplayButton = isMultiple || selected.length < 1;
  if (isDisabled || !shouldDisplayButton) {
    return null;
  }

  const buttonText = selected.length > 0 ? "Добавить" : "Выбрать";

  return <Button onClick={onClick}>{buttonText}</Button>;
}

const SearchUserFormContainer = styled.div`
  background-color: white;
  padding: 20px;
  margin: 20px 0;
  width: max-content;
`;

const SearchUserFormContainerCompact = styled.div`
  width: max-content;
`;

const SearchUserFormInput = styled(Input)`
  width: 400px;
`;

export function SearchUserForm({ initialValue = "", onSubmit, isCompact, disabled, children }) {
  const Container = isCompact ? SearchUserFormContainerCompact : SearchUserFormContainer;

  return (
    <Container>
      {!isCompact && <Title level={5}>Найти пользователей</Title>}
      <Space direction="vertical" size={20}>
        <Form initialValues={{ search: initialValue }} layout="inline" onFinish={onSubmit}>
          <Form.Item name="search">
            <SearchUserFormInput disabled={disabled} placeholder="Имя, фамилия, email, телефон" allowClear />
          </Form.Item>
          <Form.Item style={{ marginRight: 0 }}>
            <Button type="primary" htmlType="submit" disabled={disabled}>
              Найти
            </Button>
          </Form.Item>
        </Form>
        {children}
      </Space>
    </Container>
  );
}

export function UserTable({
  segment,
  searchText,
  sortBy,
  sortOrder,
  pageSize = 10,
  page,
  handleTableChange,
  columns,
  onRefreshError,
  ...other
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const loadData = useCallback((params) => {
    const { limit, offset, sortBy, sortOrder, searchText, segment } = params || {};

    setIsLoaded(false);

    return callN8n2("admin.user.search.v3", {
      q: searchText,
      sortBy,
      orderBy: sortOrder,
      limit,
      offset,
      id_segment: segment,
    }).then((result) => {
      const [meta, ...items] = result;
      setData(items);
      setTotal(meta.count);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    const params = {
      segment,
      searchText,
      sortBy,
      sortOrder: sortOrder === "ascend" ? "asc" : "desc",
      limit: pageSize,
      offset: pageSize * (page - 1),
    };

    loadData(params)
      .then(() => {
        setError(null);
      })
      .catch((e) => {
        setError(e);
      });
  }, [loadData, pageSize, page, sortBy, sortOrder, searchText, segment]);

  if (error) {
    return <PageError onRefresh={onRefreshError} />;
  }

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      onChange={handleTableChange}
      pagination={{
        current: page,
        pageSize,
        total,
      }}
      loading={!isLoaded}
      {...other}
    />
  );
}

const SelectUserGroupContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-gap: 10px;
  margin-bottom: 20px;
`;

function SelectUserTable({ isMultiple, onSelect }) {
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const selectionType = isMultiple ? "checkbox" : "radio";

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (text, record) => <Link to={`/users/${record.id}`}>{text || "-"}</Link>,
    },
    {
      title: "Фамилия",
      dataIndex: "surname",
      key: "surname",
      render: (text, record) => <Link to={`/users/${record.id}`}>{text || "-"}</Link>,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (t) => t || "-",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (t) => t || "-",
    },
    {
      title: "Дата регистрации",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortBy === "created_at" && sortOrder ? sortOrder : undefined,
    },
    {
      title: "Дата авторизации",
      dataIndex: "last_login_by_sms",
      key: "last_login_by_sms",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortBy === "last_login_by_sms" && sortOrder ? sortOrder : undefined,
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (sorter.column && sorter.field && sorter.order) {
      setSortBy(sorter.field);
      setSortOrder(sorter.order);
    }
  };

  const handleSearch = (submission) => {
    setPage(1);
    setSearchText(submission.search || "");
  };

  const onRefreshError = () => {
    setPage(1);
    setSearchText("");
    setSortBy("created_at");
    setSortOrder("desc");
  };

  const setSelected = (keys) => {
    setSelectedRowKeys(keys);
    onSelect(keys);
  };

  const rowSelection = {
    selections: [Table.SELECTION_NONE],
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys) => {
      setSelected(selectedRowKeys);
    },
  };

  const onUserGroupSelect = (id, option) => {
    if (!id) {
      setSelected([]);
      return;
    }

    fetchWithJsonRpc("admin.user_group_item.search_by_group.v1", { q: "", id }).then((result) => {
      if (!Array.isArray(result)) {
        return;
      }
      setSelected(result.map((item) => item.id_user));

      notification.success({
        message: `Выбрано пользователей: ${result.length}`,
        description: `из группы "${_.get(option, "children")}"`,
      });
    });
  };

  return (
    <>
      <SearchUserForm initialValue={searchText} onSubmit={handleSearch} isCompact={true} />
      <Divider />
      <SelectUserGroupContainer>
        Выбрать всех пользователей из группы:
        <AsyncSelect2
          jrpcMethod="admin.user_group.list.v1"
          jrpcParams={{ sort: { by: "title", order: "asc" } }}
          style={{ width: 300 }}
          showSearch={true}
          filterOption={(input, option) => {
            const title = _.get(option, "props.children") || "";
            return title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          onChange={onUserGroupSelect}
          allowClear={true}
        />
      </SelectUserGroupContainer>

      <UserTable
        columns={columns}
        searchText={searchText}
        sortBy={sortBy}
        sortOrder={sortOrder}
        page={page}
        handleTableChange={handleTableChange}
        onRefreshError={onRefreshError}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        size="small"
      />
    </>
  );
}

export function SelectUserModal({ visible, onCancel, onSelect, isMultiple, ...other }) {
  const [selected, setSelected] = useState([]);

  const onSelectHandler = (selected) => {
    setSelected(selected);
  };

  return (
    <Modal
      title="Поиск пользователя"
      visible={visible}
      width="100vw"
      style={{ top: 10, padding: 0 }}
      bodyStyle={{ maxHeight: "calc(100vh - 130px)", overflowY: "auto" }}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            onSelect(selected);
          }}
        >
          Выбрать
        </Button>,
      ]}
      {...other}
    >
      <SelectUserTable onSelect={onSelectHandler} isMultiple={isMultiple} />
    </Modal>
  );
}

export function SelectUser(props) {
  const { value, onChange, isMultiple, maxItemsToDisplay, isDisabled } = props;
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    let newSelected = [];
    if (isMultiple) {
      newSelected = Array.isArray(value) ? value.filter((item) => _.isString(item)) : [];
    } else {
      newSelected = _.isString(value) ? [value] : [];
    }
    setSelected(newSelected);
  }, [value, isMultiple]);

  const setValue = (value) => {
    let newValue = value;
    if (!isMultiple) {
      newValue = _.isString(value[0]) ? value[0] : null;
    }
    onChange(newValue);
  };

  const onRemoveHandler = (id) => {
    const newSelected = selected.filter((item) => item !== id);
    setValue(newSelected);
  };

  const openSelectModal = () => {
    setIsModalOpen(true);
  };

  const closeSelectModal = () => {
    setIsModalOpen(false);
  };

  const onSelectHandler = (values) => {
    setValue(values);
    closeSelectModal();
  };

  return (
    <>
      <SelectUserDisplaySelected
        items={selected}
        maxItemsToDisplay={maxItemsToDisplay}
        onRemove={onRemoveHandler}
        isDisabled={isDisabled}
      />
      <SelectUserButton isMultiple={isMultiple} selected={selected} onClick={openSelectModal} isDisabled={isDisabled} />
      <SelectUserModal isMultiple={isMultiple} visible={isModalOpen} onCancel={closeSelectModal} onSelect={onSelectHandler} />
    </>
  );
}
