import { useContext } from "react";
import { Dropdown as AntdDropdown } from "antd";
import _ from "lodash";

import { JHTMLContext } from "../JHTMLContext";

export function Dropdown({ overlay, ...props }) {
  const { options, children, render } = useContext(JHTMLContext);

  const childrenOpts = _.merge(options, {
    vars: {},
  });

  const _overlay = render(overlay, childrenOpts);

  return (
    <AntdDropdown {...props} overlay={_overlay}>
      {render(children[0], childrenOpts)}
    </AntdDropdown>
  );
}
