export const addHeading1 = (editor, config) => {
  const bm = editor.BlockManager;

  bm.add("gt-heading1", {
    category: "Basic",
    label: "Heading 1",
    attributes: { class: "gjs-fonts gjs-f-h1p" },
    content: "<h1>Heading 1</h1>",
  });
};
