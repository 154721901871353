import { useContext } from "react";
import { Space, Table as AntdTable, Typography } from "antd";
import { Link } from "react-router-dom";
import _ from "lodash";

import { EditableTable as EditableTable1 } from "../../../components/EditableTable";
import SortableTable1 from "../../../components/SortableTable";
import { JHTMLContext } from "../JHTMLContext";
import {
  JHTML_COLUMN_TITLE_LINK,
  JHTML_COLUMN_TRAINING_LINK,
  JHTML_COLUMN_SCENARIO_LINK,
  JHTML_COLUMN_CELL_DESC,
  JHTML_COLUMN_PRICE_AND_MARKETING_PRICE,
  JHTML_COLUMN_TAG,
  JHTML_COLUMN_STREAM_LINK,
  JHTML_COLUMN_DESCRIPTIONS,
} from "./columns";
import { bindFunction, callFunction } from "../../helpers";

const { Text } = Typography;

export function SortableTable({ columns, ...props }) {
  const { render, options } = useContext(JHTMLContext);
  const { vars } = options || {};

  const _columns = makeColumns(columns, render, vars);
  const _props = {
    ...props,
    rowClassName: bindFunction(props.rowClassName, vars, ["record", "index"]),
    pagination: bindPagination(props.pagination, vars),
    expandable: bindExpandable(props.expandable, render, vars),
    onSort: bindFunction(props.onSort, vars, ["from", "to"]),
  };

  return <SortableTable1 {..._props} columns={_columns} />;
}

export function EditableTable({ columns, ...props }) {
  const { render, options } = useContext(JHTMLContext);
  const { vars } = options || {};

  const _columns = makeEditableColumns(columns, render, vars);
  const _props = {
    ...props,
    rowClassName: bindFunction(props.rowClassName, vars, ["record", "index"]),
    pagination: bindPagination(props.pagination, vars),
    expandable: bindExpandable(props.expandable, render, vars),
    handleDelete: bindFunction(props.handleDelete, vars, ["row", "succ"]),
    handleSave: bindFunction(props.handleSave, vars, ["row", "succ"]),
    handleCopy: bindFunction(props.handleCopy, vars, ["row", "succ"]),
    handleMove: bindFunction(props.handleMove, vars, ["from", "to", "succ"]),
    addExtraButtons: props.addExtraButtons
      ? () =>
          render(props.addExtraButtons, {
            vars: {
              ...vars,
            },
          })
      : undefined,
  };

  return <EditableTable1 {..._props} columns={_columns} />;
}

export function Table({ columns, ...props }) {
  const { render, options } = useContext(JHTMLContext);
  const { vars } = options || {};

  const _columns = makeColumns(columns, render, vars);
  const _props = {
    ...props,
    rowClassName: bindFunction(props.rowClassName, vars, ["record", "index"]),
    pagination: bindPagination(props.pagination, vars),
    expandable: bindExpandable(props.expandable, render, vars),
  };

  return <AntdTable {..._props} columns={_columns} />;
}

function makeColumns(columns, render, vars) {
  return columns.map((column) => ({
    ...column,
    render: column.jhtml_render ? getJHTMLColumnRender(column.jhtml_render, render, vars) : getColumnRender(column.render_type, render),
    title: !column.jhtml_title ? column.title : render(column.jhtml_title),
  }));
}

function makeEditableColumns(columns, render, vars) {
  return makeColumns(columns, render, vars).map((column) => ({
    ...column,
    inputNode: column.jhtml_input ? getJHTMLColumnInput(column.jhtml_input, render, vars) : column.inputNode,
    defaultValue: column.defaultValueFn ? callFunction(column.defaultValueFn, vars, []) : column.defaultValue,
    editable: column.editableFn ? bindFunction(column.editableFn, vars, ["record"]) : column.editable,
  }));
}

function bindExpandable(expandable, render, vars) {
  if (!_.isPlainObject(expandable)) {
    return expandable;
  }

  return {
    ...expandable,
    expandedRowRender: (record, index, indent, expanded) => {
      return render(expandable.expandedRowRender, {
        vars: {
          ...vars,
          record,
          index,
          indent,
          expanded,
        },
      });
    },
  };
}

function bindPagination(pagination, vars) {
  if (!_.isPlainObject(pagination)) {
    return pagination;
  }

  return {
    ...pagination,
    onShowSizeChange: bindFunction(pagination.onShowSizeChange, vars, ["current", "size"]),
  };
}

function getJHTMLColumnRender(json, render, vars) {
  return (cell, row) =>
    render(json, {
      vars: {
        ...vars,
        cell,
        row,
      },
    });
}

function getJHTMLColumnInput(json, render, vars) {
  return (ref, save, row) =>
    render(json, {
      vars: {
        ...vars,
        ref,
        save,
        row,
      },
    });
}

function getColumnRender(type, render) {
  if (!type) {
    return (value) => value;
  }

  switch (type) {
    case "order_item":
      return (value, record) => (
        <Space direction="vertical" size={0}>
          <Link to={record.link}>{value}</Link>
          <Text type="secondary" style={{ fontSize: "90%" }}>
            {record.description}
          </Text>
        </Space>
      );

    case "price":
      return (value) => <Text style={{ whiteSpace: "nowrap" }}>{value || "-"}</Text>;

    case "descriptions":
      return getJHTMLColumnRender(JHTML_COLUMN_DESCRIPTIONS, render);

    case "title_link":
      return getJHTMLColumnRender(JHTML_COLUMN_TITLE_LINK, render);

    case "training_link":
      return getJHTMLColumnRender(JHTML_COLUMN_TRAINING_LINK, render);

    case "stream_link":
      return getJHTMLColumnRender(JHTML_COLUMN_STREAM_LINK, render);

    case "scenario_link":
      return getJHTMLColumnRender(JHTML_COLUMN_SCENARIO_LINK, render);

    case "cell_desc":
      return getJHTMLColumnRender(JHTML_COLUMN_CELL_DESC, render);

    case "price_and_marketing_price":
      return getJHTMLColumnRender(JHTML_COLUMN_PRICE_AND_MARKETING_PRICE, render);

    case "tag":
      return getJHTMLColumnRender(JHTML_COLUMN_TAG, render);

    default:
      return () => <Text type="danger" strong>{`[${type}]`}</Text>;
  }
}
