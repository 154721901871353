import { useContext } from "react";
import _ from "lodash";

import { JHTMLContext } from "../JHTMLContext";

export function InitVar({ name, value }) {
  const { options, children, renderChildren } = useContext(JHTMLContext);

  const childrenOpts = _.merge(options, {
    vars: {
      [name]: value,
    },
  });

  return <>{renderChildren(children, childrenOpts)}</>;
}
