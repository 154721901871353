export const addCollapsedColumns = (editor, config) => {
  editor.Blocks.add("collapsedColumns", {
    category: "Layout",
    label: "Collapsed Columns",
    attributes: { class: "gjs-fonts gjs-f-b3" },
    content: `
    <div data-gjs-type="div" bp="grid">
      <div data-gjs-type="div" bp="2@md 3@xl hide show@md"></div>
      <div data-gjs-type="div" bp="12@sm 8@md 6@xl"></div>
      <div data-gjs-type="div" bp="2@md 3@xl hide show@md"></div>
    </div>
    `,
  });
};
