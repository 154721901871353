import React, { useEffect, useState } from "react";
import { Button, Space, Layout, Menu, Dropdown, Skeleton, Typography } from "antd";
import { GlobalOutlined, DownOutlined, MenuOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

import styled from "styled-components";
import _ from "lodash";

import { Authorized, Anonymous, getUserData } from "./auth";
import { useIsMobile } from "./hooks/useIsMobile";
import { usePgrst } from "./hooks/usePgrst";
import { getSiteUrl } from "./helpers/helpers";
import { render } from "./jhtml";

const { Header: AntdHeader, Content, Sider: AntdSider } = Layout;
const { Paragraph } = Typography;

const Header = styled(AntdHeader)`
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LogoLink = styled(Link)`
  color: white;
  font-size: 20pt;
`;

const LogoutLink = styled(Link)`
  color: white;
`;

const Sider = styled(AntdSider)`
  overflow: auto;
  height: calc(100vh - 64px);
  position: fixed;
  left: 0;
`;

const MainLayout = styled(Layout)`
  transition: margin-left 0.2s;
`;

const MainContent = styled(Content)`
  padding: 20px 15px;
  overflow-y: auto;
  height: 94vh;
`;

const MainContentContainer = ({ children, isMenuCollapsed }) => {
  return (
    <>
      <Authorized>
        <MainLayout style={isMenuCollapsed ? {} : { marginLeft: "200px" }}>{children}</MainLayout>
      </Authorized>
      <Anonymous>{children}</Anonymous>
    </>
  );
};

const UserMenu = () => {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const userData = getUserData();
    if (!userData) {
      return;
    }
    setUserName(userData.name || userData.role || "User");
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <LogoutLink to="/logout">Выход</LogoutLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button type="link">
        {userName}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

function SidebarFakeMenu({ children }) {
  return <div style={{ height: "100%", background: "#ffffff", padding: "0 10px" }}>{children}</div>;
}

function SidebarMenu({ selectedKeys, onMenuSelect }) {
  const { loading, data, error, refetch } = usePgrst("sidebar_details_v1");

  if (loading) {
    return (
      <SidebarFakeMenu>
        <Skeleton paragraph={{ rows: 8 }} />
        <Skeleton paragraph={{ rows: 6 }} />
      </SidebarFakeMenu>
    );
  }

  if (error) {
    return (
      <SidebarFakeMenu>
        <Space direction="vertical" style={{ alignItems: "center", paddingTop: 20 }} size={0}>
          <Paragraph type="danger" style={{ textAlign: "center" }}>
            Не удалось загрузить пункты меню :(
          </Paragraph>
          <Button type="danger" ghost onClick={refetch}>
            Попробовать снова
          </Button>
        </Space>
      </SidebarFakeMenu>
    );
  }

  return render(data, {
    vars: {
      selectedKeys,
      onMenuSelect,
    },
  });
}

export default function MyLayout({ children }) {
  const location = useLocation();
  const webUrl = getSiteUrl();
  const isMobile = useIsMobile();

  const locationParts = location.pathname.split("/");
  const selectedKeys = "/" + _.get(locationParts, "[1]", "");

  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  const toggleCollapsed = () => setIsCollapsed(!isCollapsed);

  const onMenuSelect = () => {
    if (isMobile) {
      setIsCollapsed(true);
    }
  };

  return (
    <>
      <Header>
        <HeaderLeft>
          <Space size={15}>
            <Button size={isMobile ? "medium" : "large"} type="button" ghost={true} icon={<MenuOutlined />} onClick={toggleCollapsed} />
            <LogoLink to="/">{isMobile ? "Admin" : `${process.env.REACT_APP_PROJECT_NAME} Admin`}</LogoLink>

            <Authorized>
              {webUrl && (
                <Button href={webUrl} target="_blank" type="default" ghost={true} icon={<GlobalOutlined />}>
                  Сайт
                </Button>
              )}
            </Authorized>
          </Space>
        </HeaderLeft>
        <Authorized>
          <UserMenu />
        </Authorized>
      </Header>
      <Layout>
        <Authorized>
          <Sider collapsed={isCollapsed} collapsedWidth={0}>
            <SidebarMenu selectedKeys={selectedKeys} onMenuSelect={onMenuSelect} />
          </Sider>
        </Authorized>
        <MainContentContainer isMenuCollapsed={isCollapsed}>
          <MainContent>{children}</MainContent>
        </MainContentContainer>
      </Layout>
    </>
  );
}
