import { notification } from "antd";

export const showSuccessSaveMessage = () => {
  notification.success({
    description: "Данные успешно сохранены",
  });
};

export const showFailureSaveMessage = () => {
  notification.error({
    description: "Не удалось сохранить данные",
  });
};

export const showFailureMessage = (text) => {
  notification.error({
    description: text || "Произошла ошибка. Попробуйте ещё раз",
  });
};

export const showSuccessMessage = (text) => {
  notification.success({
    description: text,
  });
};
