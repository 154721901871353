import { useContext } from "react";
import { Alert as AntdAlert } from "antd";

import { JHTMLContext } from "../JHTMLContext";

export function Alert({ message, description, ...props }) {
  const { options, render } = useContext(JHTMLContext);

  const _message = message ? render(message, options) : null;
  const _description = description ? render(description, options) : null;

  return <AntdAlert message={_message} description={_description} {...props} />;
}
