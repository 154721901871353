import { useState } from "react";
import { Button, Card, Input, Typography, Select, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import _ from "lodash";

import { callN8n2 } from "../helpers/helpers";

const { Text } = Typography;
const { Option } = Select;

const SEGMENT_CONDITION_TYPE_SQL = "sql";
const SEGMENT_CONDITION_TYPES = {
  [SEGMENT_CONDITION_TYPE_SQL]: "SQL-запрос",
};

function sortByPosition(arr) {
  return [...arr].sort((a, b) => {
    switch (true) {
      case a.position < b.position:
        return -1;
      case a.position > b.position:
        return 1;
      default:
        return 0;
    }
  });
}

export function lastPosition(arr) {
  return _.get(sortByPosition(arr).pop(), "position");
}

export function nextPosition(arr) {
  let next = 1;

  if (Array.isArray(arr) && arr.length) {
    const last = lastPosition(arr);

    next = parseInt(last) + 1;
  }

  return next;
}

function SegmentConditionBodySQL({ condition, onChange }) {
  const [query, setQuery] = useState(condition);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    onChange(e.target.value);
  };

  function execute() {
    setError(null);
    setSuccess(false);

    callN8n2("admin.segment.execute.v1", { query })
      .then((r) => {
        if (!r.error) {
          setSuccess(true);
        } else {
          setError(r.error);
        }
      })
      .catch((e) => {
        console.error(e);
        setError("Что-то пошло не так");
      });
  }

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      <Input.TextArea value={query} onChange={onQueryChange} autoSize={{ minRows: 3 }} />

      <Space style={{ justifyContent: "space-between", width: "100%" }}>
        <Space>
          {success && (
            <Text type="success" strong>
              Запрос успешно выполнен
            </Text>
          )}
          {error && (
            <Text type="danger" strong>
              {error}
            </Text>
          )}
        </Space>

        <Button type="secondary" onClick={execute}>
          Выполнить
        </Button>
      </Space>
    </Space>
  );
}

function SegmentConditionBody({ type, condition, onChange }) {
  switch (type) {
    case SEGMENT_CONDITION_TYPE_SQL:
      return <SegmentConditionBodySQL condition={condition} onChange={onChange} />;
    default:
      break;
  }

  return null;
}

function SegmentCondition({ type, condition, position, onChange, onRemove }) {
  const [_type, setType] = useState(type);

  function onConditionChange(value) {
    onChange(position, _type, value);
  }

  function onConditionRemove() {
    onRemove(position);
  }

  function onTypeChange(value) {
    setType(value);
    onChange(position, value, null);
  }

  return (
    <Card>
      <Space direction="vertical" size={20} style={{ width: "100%" }}>
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <Space>
            <Text strong>Условие {position}</Text>

            <Select defaultValue={type} onChange={onTypeChange} style={{ width: 200 }}>
              {Object.keys(SEGMENT_CONDITION_TYPES).map((type) => {
                return (
                  <Option key={type} value={type}>
                    {SEGMENT_CONDITION_TYPES[type]}
                  </Option>
                );
              })}
            </Select>
          </Space>

          <Button type="text" icon={<CloseOutlined />} onClick={onConditionRemove} style={{ position: "relative", top: -15, right: -15 }} />
        </Space>

        <SegmentConditionBody type={type} condition={condition} onChange={onConditionChange} />
      </Space>
    </Card>
  );
}

export function InputSegmentConditions({ value = [], onChange }) {
  function onConditionChange(position, type, condition) {
    onChange(
      value.map((item) => {
        if (item.position !== position) {
          return item;
        }

        return { ...item, condition, type };
      })
    );
  }

  function onConditionRemove(position) {
    onChange(value.filter((item) => item.position !== position));
  }

  function onConditionCreate() {
    onChange([...value, { position: nextPosition(value), type: SEGMENT_CONDITION_TYPE_SQL, condition: "" }]);
  }

  if (!Array.isArray(value)) {
    return "ERROR:(";
  }

  return (
    <Space direction="vertical" size={15} style={{ width: "95%" }}>
      {sortByPosition(value).map((condition) => (
        <SegmentCondition key={condition.position} {...condition} onChange={onConditionChange} onRemove={onConditionRemove} />
      ))}
      <Button type="secondary" onClick={onConditionCreate}>
        Добавить условие
      </Button>
    </Space>
  );
}
