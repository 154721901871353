import { Select as AntdSelect } from "antd";
import _ from "lodash";

import {
  AsyncSelect2,
  AsyncSelectWithLink as AsyncSelectWithLinkOrig,
  AsyncSelect2WithLink,
  SelectWithLink as SelectWithLinkOrig,
  defaultSearchFilter,
  videoSearchFilter,
} from "../../../components/FormInput";

function bindFilterOption(props) {
  const _props = { ...props };

  const filterOptionType = _.get(props, "filterOption.type");
  if (filterOptionType) {
    switch (filterOptionType) {
      case "default":
        _props.filterOption = defaultSearchFilter;
        break;
      case "videoclip":
        _props.filterOption = videoSearchFilter;
        break;
      default:
        break;
    }
  }

  return _props;
}

export function AsyncSelect(props) {
  return <AsyncSelect2 {...bindFilterOption(props)} />;
}

export function AsyncSelectWithLink({ legacy, ...props }) {
  if (legacy) {
    return <AsyncSelectWithLinkOrig {...bindFilterOption(props)} />;
  }

  return <AsyncSelect2WithLink {...bindFilterOption(props)} />;
}

export function Select(props) {
  return <AntdSelect {...bindFilterOption(props)} />;
}

export function SelectWithLink(props) {
  return <SelectWithLinkOrig {...bindFilterOption(props)} />;
}
