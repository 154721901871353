import { fetchWithN8n, callPgrstRpc, unwrapJsonRpcError } from "@com.marathonium/web2-utils";
import _ from "lodash";

import { getJWT, setJWT, hasJWT } from "../auth";
import { localSiteUrl, imgpushPath } from "../constants";
import { history } from "../history";

export function getSiteUrl() {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  if (parts[0] === "localhost") {
    return localSiteUrl;
  }

  parts.shift();

  return `https://${parts.join(".")}`;
}

export function fetchWith401(path, options, success, history) {
  const { responseType, ...fetchOptions } = options;
  const headers = {};

  const jwt = getJWT();

  if (jwt) {
    headers["Authorization"] = `Bearer ${jwt}`;
  }

  return fetch(
    path,
    _.merge(fetchOptions, {
      headers,
    })
  )
    .then((res) => {
      console.log(res.status);

      if (res.status === 204) {
        return "";
      }

      if (res.status === 401) {
        throw new Error(res.status);
      }

      if (!res.ok) {
        throw new Error(res.status);
      }

      if (responseType === "raw") {
        return res;
      }

      if (responseType === "blob") {
        return res.blob();
      }

      return res.json();
    })
    .then((res) => {
      if (typeof success === "function") {
        success(res);
      }

      return res;
    })
    .catch((error) => {
      if (error.message === "401") {
        setJWT(null);
        history.push("/login", { redirectTo: window.location.pathname });
      }

      throw error;
    });
}

export function handleRpcError(e) {
  let error = e;
  const jsonRpcError = unwrapJsonRpcError(e);

  if (jsonRpcError) {
    error = jsonRpcError;
  }

  if (error.code === 401) {
    return history.push("/login", { redirectTo: window.location.pathname });
  }

  if (error.code === 403) {
    return history.push(`/access_denied?code=${error.data.code}`);
  }

  throw error;
}

export function callAdminRpc(method, params = {}, options = {}) {
  return callPgrstRpc(
    `${process.env.REACT_APP_ADMIN_API_PATH || ""}/admin-api/rpc`,
    hasJWT() ? "admin" : "public",
    method,
    params,
    options
  ).catch(
    _.get(options, "skipCatchError")
      ? (e) => {
          throw e;
        }
      : handleRpcError
  );
}

export function fetchWithJsonRpc(method, params = {}, options = {}, n8nVersion = 1) {
  return fetchWithN8n(method, params, options, n8nVersion).catch(handleRpcError);
}

export function callN8n2(method, params = {}, options = {}) {
  return fetchWithJsonRpc(method, params, options, 2);
}

window.callN8n2 = callN8n2;

export async function loadImageDataUrl(url, history) {
  const path = removeDomainFromImgpushUrl(url);
  const response = await fetchWith401(path, { responseType: "blob" }, null, history);
  const reader = new FileReader();

  return await new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsDataURL(response);
  });
}

export function incrementPosition(value) {
  return (items) => {
    if (!items.length) {
      return 0;
    }

    const maxPosition = items.reduce((acc, current) => {
      const currentPosition = parseInt(current.position);
      if (currentPosition > acc) {
        return currentPosition;
      }
      return acc;
    }, 0);

    return maxPosition + value;
  };
}

export function removeDomainFromImgpushUrl(url) {
  const isImgpush = _.isString(url) && url.includes(imgpushPath);
  if (!isImgpush) {
    return url;
  }

  const startIndex = url.indexOf(imgpushPath);

  return url.slice(startIndex);
}
