export const addImage = (editor, opts) => {
  editor.Components.addType("image", {
    model: {
      defaults: {
        tagName: "img",
        attributes: {
          alt: "",
          class: "gt-image",
        },
        traits: ["alt"],
        highlightable: true,
      },
    },
  });
};
