import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Table, Divider } from "antd";

import { DateCellRenderer, AnnouncementActiveCellRenderer } from "../components/CellRenderer";
import { PageError } from "../components/PageError";

import { DateFormats } from "../constants";
import { callN8n2 } from "../helpers/helpers";
import { useQuery } from "../hooks/useQuery";
import { history } from "../history";

export function Announcements() {
  const query = useQuery();

  const queryPage = parseInt(query.get("page"));
  const currentPage = !isNaN(queryPage) ? queryPage : 1;

  const queryPageSize = parseInt(query.get("pageSize"));
  const pageSize = !isNaN(queryPageSize) ? queryPageSize : 50;

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);

  const loadData = useCallback((params) => {
    const { limit, offset } = params || {};

    setIsLoaded(false);
    return callN8n2("admin.announcement.list.v1", { limit, offset })
      .then(({ total, items }) => {
        setData(items);
        setTotal(total);
        setError(null);
      })
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, []);

  useEffect(() => {
    loadData({
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
    });
  }, [loadData, pageSize, currentPage]);

  function createNew() {
    history.push("/announcements/create");
  }

  function handleTableChange(pagination) {
    query.set("page", pagination.current);
    history.push(`/announcements?${query.toString()}`);
  }

  function onShowSizeChange(current, size) {
    query.set("pageSize", size);
    history.push(`/announcements?${query.toString()}`);
  }

  const columns = [
    {
      title: "Дата старта",
      dataIndex: "started_at",
      key: "started_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (title, record) => <Link to={`/announcements/${record.id}`}>{record.show_once ? `[one-time] ${title}` : title}</Link>,
    },
    {
      title: "Статус",
      dataIndex: "active",
      key: "active",
      render: (value) => <AnnouncementActiveCellRenderer value={value} />,
    },
    {
      title: "Платформы",
      dataIndex: "platforms",
      key: "platforms",
      render: (value) => value || "-",
    },
    {
      title: "Кол-во просмотров",
      dataIndex: "views",
      key: "views",
      render: (value) => value || 0,
    },
    {
      title: "Кол-во кликов",
      dataIndex: "clicks",
      key: "clicks",
      render: (value) => value || 0,
    },
  ];

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Button type="primary" onClick={createNew}>
        + Добавить
      </Button>
      <Divider />
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={!isLoaded}
        onChange={handleTableChange}
        rowClassName={(record) => {
          const isActive = record.active;
          const isValid = new Date(record.ended_at) - new Date() > 0;

          return isActive && isValid ? null : "tr_muted";
        }}
        pagination={{
          current: currentPage,
          pageSize,
          total,
          onShowSizeChange,
        }}
      />
    </>
  );
}
