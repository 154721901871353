import { Component, createContext, useContext, useState, useEffect, useRef } from "react";
import { Table, Input, Button, Popconfirm, Form, Space } from "antd";
import { DeleteTwoTone as DeleteIcon } from "@ant-design/icons";
import _ from "lodash";

const EditableContext = createContext();

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  editable,
  alwaysEditable,
  children,
  dataIndex,
  record,
  handleSave,
  handleDelete,
  inputNode,
  valuePropName,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      try {
        inputRef.current.focus();
      } catch (e) {}
    }
  }, [editing]);

  useEffect(() => {
    if (alwaysEditable) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [alwaysEditable, form, dataIndex, record]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode =
      editing || alwaysEditable ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          valuePropName={valuePropName}
        >
          {inputNode ? inputNode(inputRef, save, record) : <Input ref={inputRef} onPressEnter={save} onBlur={save} />}
        </Form.Item>
      ) : (
        <div style={{ minHeight: "20px" }} className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default class EditableTable2 extends Component {
  constructor(props) {
    super(props);
    this.state = this.makeInitState(props);
  }

  get shouldRenderExpandAllButtons() {
    return _.get(this.props, "expandable.showExpandAllButtons", false);
  }

  get shouldExpandAllRows() {
    return _.get(this.props, "expandable.shouldExpandAllRows", false);
  }

  makeInitState(props) {
    const stateDataSource = _.map(props.dataSource, (e) => _.mergeWith(e, { key: _.uniqueId("ds_") }));

    return {
      dataSource: stateDataSource,
      count: _.size(props.dataSource),
      expandedRowKeys: this.shouldRenderExpandAllButtons || this.shouldExpandAllRows ? stateDataSource.map((item) => item.key) : [],
    };
  }

  setExpandedRowsKeys = (keys) => {
    this.setState({
      expandedRowKeys: keys,
    });
  };

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];

    this.props.handleDelete(dataSource[dataSource.findIndex((item) => key === item.key)]);

    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;

    const newData = _.mergeWith(
      _.fromPairs(
        _.map(this.props.columns, (e) => {
          const defaultValue = typeof e.defaultValue === "function" ? e.defaultValue(dataSource) : e.defaultValue;
          return [e.dataIndex, defaultValue];
        })
      ),
      { key: _.uniqueId("ds_") }
    );

    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    const foobar = { ...item, ...row };

    return new Promise((resolve) => {
      this.props.handleSave(foobar, (n) => {
        const newElement = { ...foobar, ...n };
        newData.splice(index, 1, newElement);

        this.setState({
          dataSource: newData,
        });

        resolve(newElement);
      });
    });
  };

  makeExtraColumns() {
    return [
      {
        width: 50,
        title: "",
        dataIndex: "operation",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Space>
              <Popconfirm title="Удалить?" onConfirm={() => this.handleDelete(record.key)}>
                <DeleteIcon style={{ fontSize: 20 }} />
              </Popconfirm>
            </Space>
          ) : null,
      },
    ];
  }

  makeColumns() {
    const { columns } = this.props;

    return _.concat(
      columns
        .filter((c) => !c.hidden)
        .map((col) => {
          if (!col.editable) {
            return col;
          }

          return {
            ...col,
            onCell: (record) => ({
              record,
              valuePropName: col.valuePropName || "value",
              inputNode: col.inputNode,
              editable: typeof col.editable === "function" ? col.editable(record) : col.editable,
              alwaysEditable: col.alwaysEditable,
              defaultValue: col.defaultValue,
              dataIndex: col.dataIndex,
              title: col.title,
              handleSave: this.handleSave,
              handleDelete: this.handleDelete,
            }),
          };
        }),

      this.makeExtraColumns()
    );
  }

  makeExpandable() {
    const { expandedRowKeys } = this.state;
    const { expandable } = this.props;

    return _.isPlainObject(expandable)
      ? {
          ...expandable,
          expandedRowKeys,
          onExpandedRowsChange: this.setExpandedRowsKeys,
        }
      : expandable;
  }

  makeComponents() {
    return {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
  }

  render() {
    const { dataSource } = this.state;
    const { addExtraButtons, showHeader, buttonAddLabel, style, ...otherProps } = this.props;

    const components = this.makeComponents();
    const expandable = this.makeExpandable();
    const columns = this.makeColumns();

    return (
      <div style={style}>
        {this.shouldRenderExpandAllButtons && (
          <Space size={10} style={{ margin: "15px 0" }}>
            <Button
              type="dashed"
              onClick={() => {
                this.setExpandedRowsKeys([]);
              }}
            >
              Свернуть все
            </Button>
            <Button
              type="dashed"
              onClick={() => {
                this.setExpandedRowsKeys(dataSource.map((item) => item.key));
              }}
            >
              Раскрыть все
            </Button>
          </Space>
        )}

        <Table
          {...otherProps}
          rowKey="key"
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
          expandable={expandable}
          pagination={false}
          showHeader={showHeader}
          style={{
            borderTop: "1px solid #f0f0f0",
          }}
        />

        <Space size={10} style={{ margin: "15px 0" }}>
          <Button onClick={this.handleAdd} type="dashed">
            {buttonAddLabel}
          </Button>

          {typeof addExtraButtons === "function" &&
            addExtraButtons({
              state: this.state,
              setState: (s, cb) => this.setState(s, cb),
              columns: columns,
              handleSave: (r) => this.handleSave(r),
            })}
        </Space>
      </div>
    );
  }
}

export { EditableTable2 };
