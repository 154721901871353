import { addAnchor } from "./anchor";
import { addButton } from "./button";
import { addCollapsedColumns } from "./collapsedColumns";
import { addColumns2 } from "./columns2";
import { addColumns3 } from "./columns3";
import { addColumns4 } from "./columns4";
import { addColumns5 } from "./columns5";
import { addDiv } from "./div";
import { addHeading1 } from "./heading1";
import { addHeading2 } from "./heading2";
import { addHeading3 } from "./heading3";
import { addImage } from "./image";
import { addNestedPage } from "./nestedPage";
import { addOfferForm } from "./offerForm";
import { addParagraph } from "./paragraph";
import { addRedirect } from "./redirect";
import { addSpan } from "./span";
import { addVideoClip } from "./videoclip";

function addGTCategory(editor, config) {
  const bm = editor.BlockManager;
  const categories = bm.getCategories();

  categories.add([
    {
      id: "Layout",
      label: "Layout",
      order: -1,
      open: true,
    },
  ]);
}

export const blocks = (editor, config) => {
  addGTCategory(editor, config);

  addAnchor(editor, config);
  addButton(editor, config);
  addCollapsedColumns(editor, config);
  addColumns2(editor, config);
  addColumns3(editor, config);
  addColumns4(editor, config);
  addColumns5(editor, config);
  addDiv(editor, config);
  addHeading1(editor, config);
  addHeading2(editor, config);
  addHeading3(editor, config);
  addNestedPage(editor, config);
  addOfferForm(editor, config);
  addParagraph(editor, config);
  addRedirect(editor, config);
  addSpan(editor, config);
  addImage(editor, config);
  addVideoClip(editor, config);
};
