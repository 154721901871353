export const addNestedPage = (editor, opts) => {
  const script = function ({ path }) {
    const comp = this;

    comp.classList.add("nested-page");

    if (!path) {
      comp.innerHTML = '<p>Требуется указать "path" в настройках компонента</p>';
      return;
    }

    comp.classList.add(`nested-page__${path}`);

    const method = "web.page.details.v1";

    fetch(`/n8n2/?m=${method}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: Date.now(),
        jsonrpc: "2.0",
        method,
        params: {
          path,
          partial: true,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }

        return response.json();
      })
      .then((json) => {
        if (json.error) {
          throw new Error(json.error.code);
        }

        return json.result;
      })
      .then((result) => {
        const { html, css } = result || {};

        let markup = "";
        if (css) {
          markup += `<style>${css}</style>`;
        }
        if (html) {
          markup += html;
        }
        comp.innerHTML = markup;

        const scripts = comp.getElementsByTagName("script");

        for (let i = 0; i < scripts.length; i++) {
          const scriptsItem = scripts.item(i);
          const scriptCode = scriptsItem.innerText;

          const newScript = document.createElement("script");
          newScript.text = scriptCode;

          comp.removeChild(scriptsItem);
          comp.appendChild(newScript);
        }
      })
      .catch((e) => {
        console.error(e);
        comp.innerHTML = `<p style="color: red;">Ошибка</p>`;
      });
  };

  editor.Components.addType("nested-page", {
    model: {
      defaults: {
        script,
        path: "",
        traits: [
          {
            type: "string",
            name: "path",
            changeProp: true,
          },
        ],
        "script-props": ["path"],
      },
    },
  });
};
