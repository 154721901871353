import React, { useEffect, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { Button, Typography, Skeleton, Tag } from "antd";
import { LinkOutlined } from "@ant-design/icons";

import { fetchWithJsonRpc, fetchWith401 } from "../helpers/helpers";
import { history } from "../history";
import { apiPath } from "../constants";

const { Text, Link: AntdLink } = Typography;

export const ReferenceFieldContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ReferenceFieldTitle = styled(AntdLink)`
  padding: 0;
  max-width: 300px;
  flex-shrink: 1;

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ReferenceFieldLink = styled(Button)`
  padding: 0;
`;

export function ReferenceField2({
  jrpcMethod,
  resource,
  source,
  idKey,
  title,
  titleLink,
  link,
  linkKey,
  linkTarget = "_self",
  placeholder,
  style,
  n8nVersion,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const method = jrpcMethod || `admin.${resource}.details.v1`;

  useEffect(() => {
    if (source) {
      fetchWithJsonRpc(method, { [idKey]: source }, {}, n8nVersion)
        .then((result) => {
          setData(result);
          setIsLoaded(true);
        })
        .catch(setError);
    }
  }, [method, source, idKey, n8nVersion]);

  if (!source) {
    return <Text style={{ fontStyle: "italic" }}>{placeholder || "Не задано"}</Text>;
  }

  if (error) {
    return (
      <Text type="danger" style={{ fontStyle: "italic" }}>
        Ошибка
      </Text>
    );
  }

  if (!isLoaded) {
    return <Skeleton.Input style={{ width: _.get(style, "width") || 200 }} active={true} />;
  }

  let linkUrl = typeof link === "function" ? link(data) : link;
  if (!linkUrl && linkKey) {
    linkUrl = _.get(data, linkKey);
  }
  let titleLinkUrl = typeof titleLink === "function" ? titleLink(data) : titleLink;
  let fieldTitle = typeof title === "function" ? title(data) : _.get(data, title);

  return (
    <ReferenceFieldContainer>
      {titleLinkUrl ? (
        <AntdLink href={titleLinkUrl}>{fieldTitle}</AntdLink>
      ) : (
        <ReferenceFieldTitle style={style} type="link">
          {fieldTitle}
        </ReferenceFieldTitle>
      )}

      {linkUrl && (
        <ReferenceFieldLink type="link" href={linkUrl} target={linkTarget} onClick={(e) => e.stopPropagation()} icon={<LinkOutlined />} />
      )}
    </ReferenceFieldContainer>
  );
}

export function ReferenceField({ resource, source, select, foo, bar, link, placeholder }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (source) {
      const querySelect = select ? `&select=${select}` : "";
      fetchWith401(
        `${apiPath}/${resource}?${foo}=eq.${source}${querySelect}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/vnd.pgrst.object+json",
          },
        },
        (result) => {
          setData(result);
          setIsLoaded(true);
        },
        history
      );
    }
  }, [resource, source, select, foo, bar]);

  if (!source) {
    return <i>{placeholder || "Не задано"}</i>;
  }

  if (!isLoaded) {
    return <p>Loading ...</p>;
  }

  let linkUrl = typeof link === "function" ? link(data) : link;

  return (
    <ReferenceFieldContainer>
      <ReferenceFieldTitle type="link">{_.get(data, bar)}</ReferenceFieldTitle>
      {linkUrl && (
        <ReferenceFieldLink type="link" target="_blank" href={linkUrl} onClick={(e) => e.stopPropagation()} icon={<LinkOutlined />} />
      )}
    </ReferenceFieldContainer>
  );
}

// admin.scenario_rule_access_period.dict.v1
export function ReferenceArrayField({ resource, source, foo, bar, color }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});

  const isEmptySource = (source) => {
    const isEmptyArray = Array.isArray(source) && _.isEmpty(source);
    return isEmptyArray || !source;
  };

  useEffect(() => {
    if (!isEmptySource(source)) {
      fetchWith401(
        `${apiPath}/${resource}?${foo}=in.(${source})`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
        null,
        history
      ).then((result) => {
        setData(result);
        setIsLoaded(true);
      });
    }
  }, [resource, source, foo, bar]);

  if (isEmptySource(source)) {
    return <i>Не задано</i>;
  }

  if (!isLoaded) {
    return <p>Loading ...</p>;
  }

  return (
    <>
      {data.map((e) => (
        <Tag key={e[foo]} color={color}>
          {e[bar]}
        </Tag>
      ))}
    </>
  );
}
