export function russianPlural(count, words) {
  const cases = [2, 0, 1, 1, 1, 2];

  let word;

  if (count % 100 > 4 && count % 100 < 20) {
    word = words[2];
  } else {
    word = words[cases[Math.min(count % 10, 5)]];
  }

  return `${count} ${word}`;
}
