import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Table, Form, Input, Divider, Breadcrumb, Popconfirm, Skeleton, notification } from "antd";
import { CopyOutlined, DeleteOutlined, EditOutlined, LinkOutlined } from "@ant-design/icons";

import { PageError } from "../components/PageError";
import { CardButtons, CardHeader } from "../components/Card";
import { MyTabs } from "../components/Tabs";

import { getSiteUrl, callN8n2 } from "../helpers/helpers";
import { showFailureSaveMessage, showSuccessSaveMessage } from "../helpers/notification";
import { initGrapesJSEditor } from "../grapesjs";
import { history } from "../history";

function PagesTable({ partial }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = {};
    if (partial) {
      params.partial = true;
    }

    callN8n2("admin.page.list.v1", params)
      .then((r) => r.items)
      .then(setData)
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, [partial]);

  function createNew() {
    history.push(partial ? "/pages/create/partial" : "/pages/create");
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (title, record) => <Link to={`/pages/${record.id}`}>{title}</Link>,
    },
    {
      title: "Адрес",
      dataIndex: "path",
      key: "path",
      render: (path) => (
        <Button type="link" href={`${getSiteUrl()}/${path}`} target="_blank" style={{ padding: 0 }}>
          {`${getSiteUrl()}/${path}`}
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Link to={`/pages/${id}/editor`}>
          <Button type="primary" ghost={true} icon={<LinkOutlined />} size="small">
            Редактировать
          </Button>
        </Link>
      ),
    },
  ];

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Button type="primary" onClick={createNew}>
        + Добавить
      </Button>
      <Divider />
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{
          pageSize: 50,
        }}
        loading={!isLoaded}
      />
    </>
  );
}

export function Pages() {
  const { tab } = useParams();

  return (
    <MyTabs
      activeKey={tab}
      resolvePath={(key) => `/pages/${key}`}
      items={[
        { key: "list", title: "Страницы", children: <PagesTable /> },
        { key: "partial", title: "Шаблоны", children: <PagesTable partial={true} /> },
      ]}
    />
  );
}

function PageBaseForm({ data, submitForm }) {
  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Название" name="title" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Адрес" name="path" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Содержимое HTML <head> (meta, title, ...)" name="head_mixin">
        <Input.TextArea autoSize={{ minRows: 5, maxRows: 8 }} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function PageCreate({ partial }) {
  function submitForm(submission) {
    const params = submission;
    if (partial) {
      params.partial = true;
    }

    callN8n2("admin.page.create.v1", params)
      .then(({ id }) => {
        history.push(`/pages/${id}`);
      })
      .catch((e) => {
        if (e.message === "409") {
          notification.error({
            description: `Страница или шаблон с указанным адресом уже существует`,
          });
          return;
        }

        showFailureSaveMessage();
      });
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>{partial ? <Link to="/pages/partial">Шаблоны</Link> : <Link to="/pages">Страницы</Link>}</Breadcrumb.Item>
        <Breadcrumb.Item>{partial ? "Новый шаблон" : "Новая страница"}</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <PageBaseForm data={{}} submitForm={submitForm} />
    </>
  );
}

export function PageEdit() {
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoaded(false);
    callN8n2("admin.page.details.v1", { id })
      .then(setData)
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, [id]);

  function submitForm(submission) {
    callN8n2("admin.page.update.v1", { ...submission, id })
      .then(showSuccessSaveMessage)
      .catch((e) => {
        if (e.message === "409") {
          notification.error({
            description: `Страница или шаблон с указанным адресом уже существует`,
          });
          return;
        }

        showFailureSaveMessage();
      });
  }

  async function onDelete() {
    try {
      await callN8n2("admin.page.delete.v1", { id });
      notification.success({
        description: data.partial ? "Шаблон удалён" : "Страница удалена",
      });
      history.push(data.partial ? `/pages/partial` : `/pages`);
    } catch (e) {
      notification.error({
        description: `Не удалось удалить ${data.partial ? "шаблон" : "страницу"}`,
      });
    }
  }

  async function onCopy() {
    try {
      const { id: newId } = await callN8n2("admin.page.copy.v1", { id });

      notification.success({
        description: `Копия ${data.partial ? "шаблона" : "страницы"} успешно создана`,
      });

      history.push(`/pages/${newId}`);
    } catch (e) {
      notification.error({
        description: `Не удалось создать копию ${data.partial ? "шаблона" : "страницы"}`,
      });
    }
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            {isLoaded && data.partial && <Link to="/pages/partial">Шаблоны</Link>}
            {isLoaded && !data.partial && <Link to="/pages">Страницы</Link>}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
        </Breadcrumb>

        <CardButtons>
          <Button type="primary" ghost={true} icon={<EditOutlined />} href={`/pages/${id}/editor`} target="_blank">
            Открыть редактор
          </Button>
          <Popconfirm
            disabled={!isLoaded}
            title={`Создать копию ${data.partial ? "шаблона" : "страницы"}?`}
            onConfirm={onCopy}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="primary" ghost={true} icon={<CopyOutlined />} disabled={!isLoaded}>
              Копировать
            </Button>
          </Popconfirm>
          <Popconfirm
            disabled={!isLoaded}
            title={`${data.partial ? "Шаблон будет удален" : "Страница будет удалена"}. Продолжить?`}
            onConfirm={onDelete}
            okText="Удалить"
            cancelText="Отмена"
          >
            <Button type="danger" ghost={true} icon={<DeleteOutlined />} disabled={!isLoaded}>
              Удалить
            </Button>
          </Popconfirm>
        </CardButtons>
      </CardHeader>

      <Divider />

      {isLoaded ? <PageBaseForm data={data} submitForm={submitForm} /> : <Skeleton active />}
    </>
  );
}

export function PageEditor() {
  const { id } = useParams();

  useEffect(() => {
    const onCloseEditor = () => {
      history.push(`/pages/${id}`);
    };

    callN8n2("admin.page.details.v1", { id })
      .then((page) => {
        const editor = initGrapesJSEditor("#gjs", { id, history, onCloseEditor, page });
        console.log("editor", editor);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [id]);

  return <div id="gjs" />;
}
