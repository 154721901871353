export const JHTML_COLUMN_TITLE_LINK = {
  type: "fragment",
  children: [
    {
      type: "router_link",
      props: {
        to: "{{row.link}}",
      },
      children: [
        {
          type: "html_to_unicode",
          children: [
            {
              type: "text_node",
              props: {
                value: "{{cell}}",
              },
            },
          ],
        },
      ],
    },
  ],
};

// это замена TrainingCellRenderer
export const JHTML_COLUMN_TRAINING_LINK = {
  type: "fragment",
  children: [
    {
      type: "router_link",
      props: {
        to: "/trainings/{{row.training.id}}",
      },
      children: [
        {
          type: "html_to_unicode",
          children: [
            {
              type: "text_node",
              props: {
                value: "{{row.training.title}}",
              },
            },
          ],
        },
      ],
    },
  ],
};

// это замена ScenarioCellRenderer
export const JHTML_COLUMN_SCENARIO_LINK = {
  type: "fragment",
  children: [
    {
      type: "router_link",
      props: {
        to: "/trainings/{{row.training.id}}/scenarios",
      },
      children: [
        {
          type: "html_to_unicode",
          children: [
            {
              type: "text_node",
              props: {
                value: "{{row.scenario.title}}",
              },
            },
          ],
        },
      ],
    },
  ],
};

// Это замена StreamCellRenderer
export const JHTML_COLUMN_STREAM_LINK = {
  type: "fragment",
  children: [
    {
      type: "router_link",
      props: {
        to: "{{row.stream.link}}",
      },
      children: [
        {
          type: "html_to_unicode",
          children: [
            {
              type: "text_node",
              props: {
                value: "{{row.stream.title}}",
              },
            },
          ],
        },
      ],
    },
  ],
};

export const JHTML_COLUMN_CELL_DESC = {
  type: "fragment",
  children: [
    {
      type: "text_node",
      props: {
        value: "{{cell.desc}}",
      },
    },
  ],
};

// OfferPriceCellRenderer
export const JHTML_COLUMN_PRICE_AND_MARKETING_PRICE = {
  type: "fragment",
  children: [
    {
      type: "antd_space",
      children: [
        {
          type: "if",
          props: {
            conditions: [
              {
                type: "number",
                props: {
                  operation: "gt",
                  value1: "{{row.marketing_price}}",
                  value2: 0,
                },
              },
            ],
          },
          children: [
            {
              type: "antd_text",
              props: {
                delete: true,
              },
              children: [
                {
                  type: "text_node",
                  props: {
                    value: "{{row.marketing_price}}",
                  },
                },
              ],
            },
            {
              type: "null",
            },
          ],
        },
        {
          type: "antd_text",
          children: [
            {
              type: "text_node",
              props: {
                value: "{{row.price}}",
              },
            },
          ],
        },
      ],
    },
  ],
};

export const JHTML_COLUMN_TAG = {
  type: "fragment",
  children: [
    {
      type: "antd_tag",
      props: {
        color: "{{cell.color}}",
      },
      children: [
        {
          type: "text_node",
          props: {
            value: "{{cell.title}}",
          },
        },
      ],
    },
  ],
};

export const JHTML_COLUMN_DESCRIPTIONS = {
  type: "fragment",
  children: [
    {
      type: "div",
      props: {
        style: {
          display: "flex",
          flexDirection: "column",
        },
      },
      children: [
        {
          type: "array_map",
          props: {
            items: "{{cell}}",
            template: {
              type: "antd_text",
              props: {
                key: "{{idx}}",
                type: "secondary",
                style: { fontSize: "90%" },
              },
              children: [
                {
                  type: "text_node",
                  props: {
                    value: "{{item}}",
                  },
                },
              ],
            },
          },
        },
      ],
    },
  ],
};
