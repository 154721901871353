import { Button, Result } from "antd";

export function PageError({
  onRefresh = () => {
    window.location.reload();
  },
}) {
  return (
    <Result
      status="error"
      title="Ошибка"
      subTitle="Что-то пошло не так :("
      extra={[
        <Button type="primary" key="refresh" onClick={onRefresh}>
          Перезагрузить страницу
        </Button>,
      ]}
    />
  );
}
