import React from "react";
import _ from "lodash";
import { Table } from "antd";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import styled from "styled-components";

import "./SortableTable.css";

const DragHandleIcon = styled(MenuOutlined)`
  cursor: grab;
  color: #999999;
`;

const DragHandle = sortableHandle(() => <DragHandleIcon />);
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

class SortableTable extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const onSort = _.get(this.props, "onSort", () => {});
      onSort(oldIndex, newIndex);
    }
  };

  DraggableContainer = (props) => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={this.onSortEnd} {...props} />
  );

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = _.get(this.props, "dataSource", []);
    const index = dataSource.findIndex((x) => x[this.props.rowKey] === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  get columns() {
    const columns = _.get(this.props, "columns", []);
    return [
      {
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => <DragHandle />,
      },
      ...columns,
    ];
  }

  render() {
    return (
      <Table
        {...this.props}
        columns={this.columns}
        components={{
          body: {
            wrapper: this.DraggableContainer,
            row: this.DraggableBodyRow,
          },
        }}
      />
    );
  }
}

export default SortableTable;
