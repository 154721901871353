import React, { useEffect, useState, useCallback, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import "moment/locale/ru";
import _ from "lodash";
import styled from "styled-components";
import {
  Alert,
  Button,
  Table,
  Divider,
  Breadcrumb,
  Checkbox,
  Radio,
  Popconfirm,
  Skeleton,
  Switch as AntdSwitch,
  Space,
  Typography,
  Form,
  Input,
  InputNumber,
  Select,
  Col,
  Row,
  notification,
} from "antd";
import { LinkOutlined, RedoOutlined, CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { blue, green } from "@ant-design/colors";

import { TrainingStreams } from "./Stream";

import EditableTable2 from "../components/EditableTable2";
import { InputImage } from "../components/InputImage";
import { InputDate } from "../components/FormInput";
import { AsyncSelect, AsyncSelect2, defaultSearchFilter } from "../components/FormInput";
import { ReferenceField, ReferenceField2, ReferenceArrayField } from "../components/ReferenceField";
import { PageError } from "../components/PageError";
import { HTML2Unicode } from "../components/HTML2Unicode";
import { MyTabs } from "../components/Tabs";
import { CardHeader } from "../components/Card";

import { apiPath, DateFormats } from "../constants";
import { callAdminRpc, getSiteUrl, fetchWith401, fetchWithJsonRpc, incrementPosition, callN8n2 } from "../helpers/helpers";
import { showFailureMessage, showFailureSaveMessage, showSuccessSaveMessage } from "../helpers/notification";
import { handleSave, handleSaveN8n, handleDelete, handleDeleteN8n } from "../helpers/request";
import { useQuery } from "../hooks/useQuery";
import { useN8n } from "../hooks/useN8n";
import { useN8n2 } from "../hooks/useN8n2";
import { useN8nDefer } from "../hooks/useN8nDefer";
import { history } from "../history";
import { CardScreen, QueryScreen } from "../jhtml";

import "../fonts/CeraPro/stylesheet.css";

const { Text } = Typography;
const { Option } = Select;

const TRAINING_VISIBILITY_VISIBLE = "visible";
const TRAINING_VISIBILITY_HIDDEN = "hidden";
const TRAINING_VISIBILITY_SECRET = "secret";

export function TrainingsTabs() {
  const { tab = "list" } = useParams();

  return (
    <MyTabs
      activeKey={tab}
      resolvePath={(key) => `/trainings/${key}`}
      items={[
        {
          key: "list",
          title: "Тренинги",
          children: (
            <QueryScreen
              source="screen_training_list_v1"
              queryMap={{
                page: "integer",
                pageSize: "integer",
                visibility: "string",
              }}
            />
          ),
        },
        { key: "training_packages", title: "Каталоги", children: <TrainingPackagesTable /> },
        {
          key: "teachers",
          title: "Преподаватели",
          children: (
            <QueryScreen
              source="screen_teacher_list_v1"
              queryMap={{
                page: "integer",
                pageSize: "integer",
              }}
            />
          ),
        },
      ]}
    />
  );
}

function TrainingPackagesTable() {
  const { loading, data = {}, error } = useN8n("admin.training_package.list.v1");

  function createNew() {
    history.push("/trainings/training_packages/create");
  }

  const columns = [
    {
      title: "#",
      dataIndex: "position",
      key: "position",
      width: 40,
      render: (position) => position || 0,
    },
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <Link to={`/trainings/training_packages/${record.id}`}>{text}</Link>,
    },
  ];

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Button type="primary" onClick={createNew}>
        + Добавить
      </Button>
      <Divider />
      <Table
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        pagination={{
          pageSize: 50,
        }}
        loading={loading}
      />
    </>
  );
}

function TrainingPackageBaseForm({ data, submitForm }) {
  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Позиция" name="position" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>

      <Form.Item label="Название" name="title">
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function TrainingPackageCreate() {
  const create = useN8nDefer("admin.training_package.create.v1");

  function submitForm(submission) {
    create(submission)
      .then(({ id }) => {
        history.push(`/trainings/training_packages/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/trainings/training_packages">Каталоги</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Новый каталог</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <TrainingPackageBaseForm data={{}} submitForm={submitForm} />
    </>
  );
}

function TrainingPackageItems({ id }) {
  const { loading, data = {}, error } = useN8n("admin.training_package_item.list.v1", { id }, [id]);
  const remove = useN8nDefer("admin.training_package_item.delete.v1");

  const columns = [
    {
      title: "#",
      dataIndex: "position",
      width: 30,
      editable: true,
      defaultValue: incrementPosition(10),
      render: (t, r) => t || 0,
    },
    {
      title: "Тренинг",
      dataIndex: "id_training",
      editable: true,
      defaultValue: null,
      width: "30%",
      render: (t, r) => {
        return <ReferenceField2 resource="training" source={r.id_training} idKey="id" title="title" n8nVersion={2} />;
      },
      inputNode: (ref, save, r) => {
        return (
          <AsyncSelect2
            jrpcMethod="admin.training.list.v1"
            n8nVersion={2}
            autoFocus={true}
            defaultValue={r.id_training}
            title="title"
            onChange={() => {
              r.id_scenario = null;
            }}
            onBlur={save}
            showSearch={true}
            filterOption={defaultSearchFilter}
          />
        );
      },
    },
    {
      title: "Сценарий",
      dataIndex: "id_scenario",
      editable: (r) => !!r.id_training,
      defaultValue: null,
      width: "30%",
      render: (t, r) => {
        if (!r.id_training) {
          return <i>Необходимо выбрать тренинг</i>;
        }
        return <ReferenceField resource="scenario" source={r.id_scenario} foo="id" bar="title" />;
      },
      inputNode: (ref, save, r) => {
        return (
          <AsyncSelect2
            jrpcMethod="admin.scenario.list.v1"
            jrpcParams={{ id_training: r.id_training }}
            autoFocus={true}
            defaultValue={r.id_scenario}
            title="title"
            onBlur={save}
            showSearch={true}
            filterOption={defaultSearchFilter}
          />
        );
      },
    },
  ];

  const deleteHandler = (row, succ) => {
    remove({ ...row, id_training_package: id })
      .then(succ)
      .catch((e) => console.error(e));
  };

  const saveHandler = (row, succ) => {
    handleSaveN8n("training_package_item", { id_training_package: id })(row, succ).catch(showFailureSaveMessage);
  };

  if (error) {
    return <PageError />;
  }

  if (loading) {
    return <Table loading={true} />;
  }

  return (
    <EditableTable2
      handleDelete={deleteHandler}
      handleSave={saveHandler}
      buttonAddLabel="Добавить элемент каталога"
      columns={columns}
      dataSource={data.items}
    />
  );
}

export function TrainingPackageEdit() {
  const { id, tab = "base" } = useParams();

  const { loading, data = {}, error } = useN8n("admin.training_package.details.v1", { id }, [id]);
  const update = useN8nDefer("admin.training_package.update.v1");
  const remove = useN8nDefer("admin.training_package.delete.v1");

  function submitForm(submission) {
    update({ ...submission, id })
      .then(showSuccessSaveMessage)
      .catch((e) => {
        showFailureMessage(e.message);
      });
  }

  async function onDelete() {
    try {
      await remove({ id });
      notification.success({
        description: "Каталог удалён",
      });
      history.push(`/trainings/training_packages`);
    } catch (e) {
      notification.error({
        description: "Не удалось удалить каталог",
      });
    }
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/trainings/training_packages">Каталоги</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
        </Breadcrumb>
        <Popconfirm disabled={loading} title="Каталог будет удалён. Продолжить?" onConfirm={onDelete} okText="Удалить" cancelText="Отмена">
          <Button type="danger" ghost={true} icon={<DeleteOutlined />} disabled={loading}>
            Удалить каталог
          </Button>
        </Popconfirm>
      </CardHeader>

      <Divider />

      <MyTabs
        activeKey={tab}
        resolvePath={(key) => `/trainings/training_packages/${id}/${key}`}
        items={[
          {
            key: "base",
            title: "Основные поля",
            children: !loading ? <TrainingPackageBaseForm data={data} submitForm={submitForm} /> : <Skeleton active />,
          },
          { key: "items", title: "Элементы", children: <TrainingPackageItems id={id} /> },
        ]}
      />
    </>
  );
}

function TrainingBaseForm({ data, submitForm }) {
  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Позиция" name="position">
        <InputNumber />
      </Form.Item>

      <Form.Item label="Заголовок" name="title">
        <Input />
      </Form.Item>

      <Form.Item label="Картинка" name="image">
        <InputImage />
      </Form.Item>

      <Form.Item label="Описание" name="description">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Адрес лендинга" name="landing_url">
        <Input />
      </Form.Item>

      <Row gutter={[50, 0]}>
        <Col xs={24} md={12}>
          <Form.Item label="Тип" name="type" rules={[{ required: true }]}>
            <AsyncSelect title="title" resource="training_type" style={{ width: "300px" }} autoFocus={false} />
          </Form.Item>

          <Form.Item label="Тренер" name="id_teacher">
            <AsyncSelect2 jrpcMethod="admin.teacher.list.v1" title="name" showSearch={true} filterOption={defaultSearchFilter} />
          </Form.Item>

          <Form.Item label="Взаимосвязанный контент" name="dependent_content" rules={[{ required: true }]}>
            <Select
              style={{ width: 300 }}
              options={[
                { label: "Да (контент связан логически)", value: true },
                { label: "Нет (контент логически не связан)", value: false },
              ]}
              placeholder="Тип контента не установлен"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="Видимость" name="visibility">
            <Select style={{ width: 300 }} defaultValue={TRAINING_VISIBILITY_HIDDEN}>
              <Option value={TRAINING_VISIBILITY_HIDDEN}>Скрытый</Option>
              <Option value={TRAINING_VISIBILITY_VISIBLE}>Доступный</Option>
              <Option value={TRAINING_VISIBILITY_SECRET}>Cекретный</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Опубликованно в" name="published_at">
            <InputDate showTime={false} format={DateFormats.dateOnly} />
          </Form.Item>

          {_.get(data, "id") && (
            <Form.Item label="Сценарий по умолчанию" name="id_scenario_default" rules={[{ required: true }]}>
              <AsyncSelect2
                jrpcMethod="admin.scenario.list.v1"
                jrpcParams={{ id_training: data.id }}
                title="title"
                style={{ width: "500px" }}
                showSearch={true}
                filterOption={defaultSearchFilter}
                deps={[data.id]}
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function TrainingCreate() {
  function submitForm(submission) {
    callN8n2("admin.training.create.v1", submission)
      .then(({ id }) => {
        showSuccessSaveMessage();
        history.push(`/trainings/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  return <TrainingBaseForm data={{}} submitForm={submitForm} />;
}

const TrainingEditHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TrainingScenarioSelect = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-gap: 10px;
`;

function TrainingLessonsTable({ id, data, scenarioId }) {
  const tgiTableColumns = [
    {
      title: "#",
      dataIndex: "position",
      width: 30,
      editable: true,
      defaultValue: incrementPosition(10),
      render: (t, r) => t || 0,
    },
    {
      title: "Урок",
      dataIndex: "id_lesson",
      editable: true,
      defaultValue: null,
      render: (t, r) => (
        <ReferenceField
          resource="lesson"
          source={r.id_lesson}
          foo="id"
          bar="title"
          link={`/lessons/${r.id_lesson}`}
          placeholder="Без урока"
        />
      ),
      inputNode: (ref, save, r) => {
        return (
          <AsyncSelect2
            jrpcMethod="admin.lesson.list.v1"
            jrpcParams={{ sort: { by: "title", order: "asc" } }}
            autoFocus={true}
            defaultValue={r.id_lesson}
            title="title"
            resource="lesson"
            onBlur={save}
            showSearch={true}
            filterOption={defaultSearchFilter}
          />
        );
      },
    },
    {
      title: "Задание",
      dataIndex: "id_practic",
      editable: true,
      defaultValue: null,
      render: (t, r) => (
        <ReferenceField
          resource="practic"
          source={r.id_practic}
          foo="id"
          bar="title"
          link={`/practics/${r.id_practic}`}
          placeholder="Без задания"
        />
      ),
      inputNode: (ref, save, r) => {
        return (
          <AsyncSelect
            defaultValue={r.id_practic}
            title="title"
            resource="practic"
            onBlur={save}
            showSearch={true}
            filterOption={defaultSearchFilter}
          />
        );
      },
    },
  ];

  if (scenarioId) {
    tgiTableColumns.push(
      {
        dataIndex: "practic_auto_accept",
        editable: true,
        alwaysEditable: true,
        inputNode: (ref, save, r) => {
          if (!r.has_scenario_rule) {
            return null;
          }
          const options = [
            {
              label: "Куратор",
              value: false,
            },
            {
              label: "Авто",
              value: true,
            },
          ];
          return (
            <Radio.Group
              options={options}
              onChange={save}
              defaultValue={r.practic_auto_accept}
              optionType="button"
              buttonStyle="solid"
              size="small"
              style={{ whiteSpace: "nowrap" }}
            />
          );
        },
      },
      {
        title: "В сценарии",
        dataIndex: "has_scenario_rule",
        editable: true,
        alwaysEditable: true,
        valuePropName: "checked",
        inputNode: (ref, save, r) => {
          return (
            <AntdSwitch
              defaultChecked={r.has_scenario_rule}
              checkedChildren="В сценарии"
              unCheckedChildren="Не используется"
              onChange={save}
              style={{ whiteSpace: "nowrap" }}
            />
          );
        },
      },
      {
        title: "Правило доступа",
        dataIndex: "access_rule",
        editable: (r) => r.has_scenario_rule,
        defaultValue: null,
        render: (t, r) => {
          if (!r.has_scenario_rule) {
            return null;
          }
          return <ReferenceField resource="scenario_rule_access_rule_dict" source={t} foo="id" bar="desc" />;
        },
        inputNode: (ref, save, r) => {
          return <AsyncSelect onBlur={save} defaultValue={r.access_rule} title="desc" resource="scenario_rule_access_rule_dict" />;
        },
      },
      {
        title: "Период доступа",
        dataIndex: "access_period",
        editable: (r) => r.has_scenario_rule,
        defaultValue: [],
        render: (t, r) => {
          if (!r.has_scenario_rule) {
            return null;
          }
          return <ReferenceArrayField resource="scenario_rule_access_period_dict" source={t} foo="id" bar="desc" color={blue.primary} />;
        },
        inputNode: (ref, save, r) => {
          return (
            <AsyncSelect
              onBlur={save}
              mode="multiple"
              defaultValue={r.access_period}
              title="desc"
              resource="scenario_rule_access_period_dict"
            />
          );
        },
      }
    );
  }

  const tgiTableHandleSave = (r) => {
    return (data, onSuccess) => {
      const params = {
        ...data,
        id_training_group: r.id,
        id_scenario: scenarioId,
      };

      callAdminRpc("training_group_item_update_v1", { params })
        .then((result) => {
          onSuccess({
            ...data,
            id: result.id,
            access_period: data.has_scenario_rule ? data.access_period : [],
            access_rule: data.has_scenario_rule ? data.access_rule : null,
          });
        })
        .catch(showFailureSaveMessage);
    };
  };

  const deleteTgiHandler = (e, succ) => {
    callAdminRpc("training_group_item_delete_v1", { id: e.id }).catch(showFailureSaveMessage);
  };

  const deleteTgHandler = (e, succ) => {
    callAdminRpc("training_group_delete_v1", { id: e.id }).catch(showFailureSaveMessage);
  };

  return (
    <EditableTable2
      buttonAddLabel="Добавить группу"
      handleDelete={deleteTgHandler}
      handleSave={handleSave(history, "training_group", id, ["training_group_item", "key"], { id_training: id })}
      columns={[
        {
          title: "#",
          dataIndex: "position",
          width: 30,
          editable: true,
          defaultValue: 0,
          render: (t, r) => t || 0,
        },

        {
          title: "Название группы",
          dataIndex: "title",
          width: "30%",
          editable: true,
          defaultValue: null,
          render: (t, r) => {
            if (!r.title_hidden && t) {
              return (
                <Space align="normal">
                  <CheckCircleOutlined style={{ color: green.primary, fontSize: 22 }} />
                  {t}
                </Space>
              );
            }

            return t || <i>Нет названия</i>;
          },
        },

        {
          title: "Скрыть заголовок?",
          dataIndex: "title_hidden",
          render: (text) => (text ? "Да" : "Нет"),
          editable: true,
          valuePropName: "checked",
          defaultValue: false,
          inputNode: (ref, save) => {
            return <Checkbox onBlur={save} />;
          },
        },

        {
          title: "Вариант отображения",
          dataIndex: "display",
          editable: true,
          defaultValue: "with_button",
          render: (t, r) => <ReferenceField resource="training_group_display_dict" source={r.display} foo="id" bar="desc" />,
          valuePropName: "defaultValue",
          inputNode: (ref, save, r) => {
            return <AsyncSelect defaultValue={r.display} title="desc" resource="training_group_display_dict" onBlur={save} />;
          },
        },
      ]}
      dataSource={data}
      rowKey="id"
      expandable={{
        showExpandAllButtons: true,
        expandedRowRender: (r) => (
          <EditableTable2
            showHeader={false}
            handleSave={tgiTableHandleSave(r)}
            handleDelete={deleteTgiHandler}
            style={{ marginLeft: 20 }}
            buttonAddLabel="Добавить урок"
            addExtraButtons={() => (
              <Button key="create_new_lesson" type="dashed" href={`/lessons/create/${r.id}`} target="_blank">
                Создать новый урок
              </Button>
            )}
            columns={tgiTableColumns}
            dataSource={r.training_group_item}
          />
        ),
      }}
    />
  );
}

function TrainingLessonsSettings({ trainingId, trainingData, scenarioId }) {
  const context = useContext(ScenarioPurchasesContext);
  // const { isLoaded, error, data, reloadData, onRecalc } = context;
  const { onRecalc2 } = context;

  return (
    <>
      <Popconfirm title="Связанные покупки будут пересчитаны. Продолжить?" onConfirm={onRecalc2} okText="Продолжить" cancelText="Отмена">
        <Text type="danger" underline style={{ cursor: "pointer" }}>
          Пересчитать покупки
        </Text>
      </Popconfirm>
      {/* <ScenarioPurchasesInfo data={data} isLoaded={isLoaded} error={error} onRecalc={onRecalc} onReload={reloadData} /> */}
      <TrainingLessonsTable id={trainingId} data={trainingData} scenarioId={scenarioId} />
    </>
  );
}

function TrainingLessons({ id }) {
  const query = useQuery();
  const scenarioId = query.get("scenario") || null;

  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const loadData = useCallback(() => {
    setIsLoaded(false);

    fetchWithJsonRpc("admin.training_group.list.v1", {
      id_training: id,
      id_scenario: scenarioId,
    })
      .then((result) => {
        setData(result);
        setIsLoaded(true);
      })
      .catch((e) => {
        setError(e);
      });
  }, [scenarioId, id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleScenarioChange = (value) => {
    if (value) {
      query.set("scenario", value);
    } else {
      query.delete("scenario");
    }
    history.push(`/trainings/${id}/lessons?${query.toString()}`);
  };

  if (error) {
    return <PageError />;
  }

  if (!isLoaded) {
    return <Table loading={true} />;
  }

  return (
    <Space direction="vertical" size={10} style={{ width: "100%" }}>
      <TrainingScenarioSelect>
        Сценарий:
        <AsyncSelect2
          jrpcMethod="admin.scenario.list.v1"
          jrpcParams={{ id_training: id }}
          deps={[id]}
          title="title"
          showSearch={true}
          filterOption={defaultSearchFilter}
          defaultValue={scenarioId}
          placeholder="Сценарий не выбран"
          allowClear={true}
          style={{ width: 300 }}
          onChange={handleScenarioChange}
        />
      </TrainingScenarioSelect>

      {scenarioId ? (
        <ScenarioPurchasesContextProvider id={scenarioId}>
          <TrainingLessonsSettings scenarioId={scenarioId} trainingId={id} trainingData={data} />
        </ScenarioPurchasesContextProvider>
      ) : (
        <TrainingLessonsTable id={id} data={data} scenarioId={scenarioId} />
      )}
    </Space>
  );
}

const ScenarioPurchasesContext = React.createContext();

const ScenarioPurchasesContextProvider = ({ id, children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  const loadData = useCallback(
    (should_calc_invalid) => {
      setError(null);
      setIsLoaded(false);

      fetchWithJsonRpc("admin.scenario.purchases_count.v2", {
        id,
        should_calc_invalid,
      })
        .then(setData)
        .catch((e) => {
          setError(e);
          notification.error({
            message: "Ошибка",
            description: "Не удалось загрузить данные о связанных покупках",
          });
        })
        .finally(() => setIsLoaded(true));
    },
    [id]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onRecalc = () => {
    fetchWithJsonRpc("admin.scenario.purchases_recalc.v1", { id })
      .then(() => {
        notification.success({
          description: "Пересчёт покупок успешно запущен",
        });
        loadData();
      })
      .catch((e) => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось запустить пересчёт покупок",
        });
      });
  };

  const onRecalc2 = () => {
    callN8n2("admin.purchase.force_recalc.v1", { id_scenario: id })
      .then(() => {
        notification.success({
          description: "Пересчёт покупок успешно запущен",
        });
      })
      .catch((e) => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось запустить пересчёт покупок",
        });
      });
  };

  const reloadData = () => loadData(true);

  return (
    <ScenarioPurchasesContext.Provider
      value={{
        isLoaded,
        error,
        data,
        reloadData,
        onRecalc,
        onRecalc2,
      }}
    >
      {children}
    </ScenarioPurchasesContext.Provider>
  );
};

function TrainingScenarioRules({ row, trainingId }) {
  const deleteHandler = (e, succ) => {
    handleDeleteN8n("scenario_rule", { id_scenario: row.id }, "v2")(e, succ).catch((e) => console.error(e));
  };

  const saveHandler = (s, succ) => {
    handleSaveN8n("scenario_rule", { id_scenario: row.id }, null, "v2")(s, succ).catch(showFailureSaveMessage);
  };

  return (
    <EditableTable2
      handleDelete={deleteHandler}
      handleSave={saveHandler}
      buttonAddLabel="Добавить правило"
      style={{ marginLeft: 20 }}
      columns={[
        {
          title: "Урок",
          dataIndex: "id_training_group_item",
          editable: true,
          render: (t, r) => {
            return (
              <ReferenceField
                resource="training_group_item"
                source={t || null}
                foo="id"
                bar="lesson.title"
                select="id,lesson(id,title)"
                link={(data) => `/lessons/${_.get(data, "lesson.id")}`}
              />
            );
          },
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect
                onBlur={save}
                defaultValue={r.id_training_group_item}
                idKey="id_tgi"
                title="title"
                resource={`admin_lesson?id_training=eq.${trainingId}&select=id_tgi,title`}
              />
            );
          },
        },
        {
          title: "Период доступа",
          dataIndex: "access_period",
          width: "30%",
          editable: true,
          defaultValue: [],
          render: (t, r) => <ReferenceArrayField resource="scenario_rule_access_period_dict" source={t} foo="id" bar="desc" />,
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect
                onBlur={save}
                mode="multiple"
                defaultValue={r.access_period}
                title="desc"
                resource="scenario_rule_access_period_dict"
              />
            );
          },
        },

        {
          title: "Правило доступа",
          dataIndex: "access_rule",
          width: "30%",
          editable: true,
          defaultValue: null,
          render: (t, r) => <ReferenceField resource="scenario_rule_access_rule_dict" source={t} foo="id" bar="desc" />,
          inputNode: (ref, save, r) => {
            return <AsyncSelect onBlur={save} defaultValue={r.access_rule} title="desc" resource="scenario_rule_access_rule_dict" />;
          },
        },
      ]}
      dataSource={(row.scenario_rule || []).sort(function (a, b) {
        const tgAPos = _.get(a, "training_group_item.training_group.position");
        const tgBPos = _.get(b, "training_group_item.training_group.position");
        const tgiAPos = _.get(a, "training_group_item.position");
        const tgiBPos = _.get(b, "training_group_item.position");
        const tgPosCompare = tgAPos - tgBPos;
        if (tgPosCompare !== 0) {
          return tgPosCompare;
        }
        return tgiAPos - tgiBPos;
      })}
    />
  );
}

function ScenarioPurchasesInfo({ isLoaded, error, data, onRecalc, onReload, style }) {
  if (!isLoaded) {
    return <Skeleton.Input style={{ width: 500, ...style }} active={true} />;
  }

  if (error) {
    return null;
  }

  const totalCount = _.get(data, "total") || 0;
  const invalidCount = _.get(data, "invalid") || 0;

  return (
    <Space direction="vertical" size={4} style={style}>
      <Space size={8}>
        <Text strong>Данные используются в {totalCount} покупках.</Text>
        <Button onClick={onReload} size="small" type="primary" icon={<RedoOutlined />}>
          Проверить актуальность
        </Button>
      </Space>
      {invalidCount > 0 && (
        <Space size={3}>
          <Text type="danger">Покупок, не соответствующих текущим настройкам: {invalidCount}.</Text>
          <Popconfirm title="Связанные покупки будут пересчитаны. Продолжить?" onConfirm={onRecalc} okText="Продолжить" cancelText="Отмена">
            <Text type="danger" underline style={{ cursor: "pointer" }}>
              Пересчитать покупки
            </Text>
          </Popconfirm>
        </Space>
      )}
    </Space>
  );
}

function TrainingScenarioSettings({ row, trainingId }) {
  const context = useContext(ScenarioPurchasesContext);
  const { isLoaded, error, data, reloadData, onRecalc } = context;

  return (
    <Space direction="vertical" size={10} style={{ width: "100%" }}>
      <ScenarioPurchasesInfo
        data={data}
        isLoaded={isLoaded}
        error={error}
        onRecalc={onRecalc}
        onReload={reloadData}
        style={{ marginLeft: 20 }}
      />
      <TrainingScenarioRules row={row} trainingId={trainingId} />
    </Space>
  );
}

function TrainingScenarios({ id }) {
  const [error, setError] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    // fixme : use fetchWithJsonRpc
    // admin.scenario.list_by_training.v1
    fetchWith401(
      `${apiPath}/training?id=eq.${id}&select=scenario!scenario_id_training_fkey(*,scenario_rule(*,training_group_item(*,training_group(position),lesson(title))))`,
      {
        headers: {
          Accept: "application/vnd.pgrst.object+json",
        },
      },
      (result) => {
        setData(result);
      },
      history
    )
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, [id]);

  if (error) {
    return <PageError />;
  }

  if (!isLoaded) {
    return <Table loading={true} />;
  }

  return (
    <EditableTable2
      handleDelete={handleDelete(history, "scenario")}
      handleSave={handleSave(history, "scenario", id, ["training_group_item", "scenario_rule", "key"], { id_training: id })}
      buttonAddLabel="Добавить сценарий"
      columns={[
        {
          title: "Название",
          dataIndex: "title",
          key: "title",
          editable: true,
          defaultValue: null,
          render: (t, r) => t || "-",
        },
        {
          title: "Описание",
          dataIndex: "description",
          key: "description",
          editable: true,
          defaultValue: null,
          render: (t, r) => t || "-",
        },
      ]}
      dataSource={data.scenario}
      expandable={{
        expandedRowRender: (r) => (
          <ScenarioPurchasesContextProvider id={r.id}>
            <TrainingScenarioSettings row={r} trainingId={id} />
          </ScenarioPurchasesContextProvider>
        ),
      }}
    />
  );
}

export function TrainingEdit() {
  const { id, tab = "base" } = useParams();
  const { loading, data = {}, error, refetch } = useN8n2("admin.training.details.v1", { id }, [id]);

  if (loading) {
    return <Skeleton active />;
  }

  if (error) {
    return <PageError />;
  }

  function submitForm(submission) {
    callN8n2("admin.training.update.v1", { id, ...submission })
      .then(() => {
        notification.success({
          description: "Данные успешно сохранены",
        });
        refetch();
      })
      .catch(() => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось сохранить данные",
        });
      });
  }

  return (
    <>
      <TrainingEditHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/trainings">Тренинги</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <HTML2Unicode>{_.get(data, "title")}</HTML2Unicode>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Button type="primary" ghost={true} icon={<LinkOutlined />} href={`${getSiteUrl()}/trainings/${id}`} target="_blank">
          Открыть тренинг
        </Button>
      </TrainingEditHeader>

      <Divider />

      <MyTabs
        activeKey={tab}
        resolvePath={(key) => `/trainings/${id}/${key}`}
        items={[
          {
            key: "base",
            title: "Основные поля",
            children: <TrainingBaseForm data={data} submitForm={submitForm} />,
          },
          {
            key: "lessons",
            title: "Уроки и сценарии",
            children: _.isBoolean(_.get(data, "dependent_content")) ? (
              <TrainingLessons id={id} />
            ) : (
              <Alert type="warning" message={<Text>Необходимо выбрать тип контента тренинга, чтобы управлять содержанием</Text>} />
            ),
          },
          {
            key: "scenarios",
            title: "Сценарии",
            children: <TrainingScenarios id={id} data={data} />,
          },
          {
            key: "streams",
            title: "Потоки",
            children: <TrainingStreams id={id} />,
          },
          {
            key: "offers",
            title: "Предложения",
            children: <CardScreen source="screen_training_details_tab_offers_v1" />,
          },
          {
            key: "description",
            title: "Описание",
            children: <CardScreen dataFn="useN8n2" source="admin.screen.training_details_description.v1" />,
          },
        ]}
      />
    </>
  );
}
