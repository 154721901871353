import { useContext } from "react";
import { Form as AntdForm } from "antd";
import _ from "lodash";

import { JHTMLContext } from "../JHTMLContext";

export function UseForm({ name = "form" }) {
  // const { options, children, renderChildren } = useContext(JHTMLContext);
  // const [form] = AntdForm.useForm();

  // const childrenOpts = _.merge(options, {
  //   [name]: form,
  // });

  // return <>{renderChildren(children, childrenOpts)}</>;

  const { options, children, renderChildren } = useContext(JHTMLContext);
  return <>{renderChildren(children, options)}</>;
}

export function Form(props) {
  const { options, children, renderChildren } = useContext(JHTMLContext);
  const [form] = AntdForm.useForm();

  const childrenOpts = _.merge(options, {
    vars: { form },
  });

  return (
    <AntdForm form={form} {...props}>
      {renderChildren(children, childrenOpts)}
    </AntdForm>
  );
}

export function FormItem({ children, ...props }) {
  return <AntdForm.Item {...props}>{Array.isArray(children) ? children[0] : children}</AntdForm.Item>;
}
