import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Input, Select, Skeleton, Space, DatePicker, Radio, Typography, notification } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { fetchWithJsonRpc, fetchWith401, callAdminRpc } from "../helpers/helpers";
import { apiPath, DateFormats } from "../constants";
import { useN8n2 } from "../hooks/useN8n2";
import { history } from "../history";

const { Option } = Select;
const { Text, Link: AntdLink } = Typography;
const { RangePicker } = DatePicker;

function getTitle(row, title) {
  if (typeof title === "function") {
    return title(row);
  }

  return _.get(row, title);
}

export function AsyncSelect3({
  adminApiMethod,
  adminApiParams = {},
  title = "title",
  idKey = "id",
  onBlur,
  autoFocus,
  mode,
  value,
  deps,
  ...selectProps
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const refreshDeps = Array.isArray(deps) ? [adminApiMethod, ...deps] : [adminApiMethod];

  useEffect(() => {
    callAdminRpc(adminApiMethod, { params: adminApiParams }, {})
      .then((r) => {
        setData(r);
        setIsLoaded(true);
      })
      .catch((e) => {
        typeof onBlur === "function" && onBlur();
        notification.error({
          message: "Ошибка",
          description: "Не удалось загрузить список элементов для выбора",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refreshDeps);

  if (!isLoaded) {
    return <Skeleton.Input style={{ width: _.get(selectProps, "style.width") || 200 }} active={true} />;
  }

  return (
    <Select
      autoFocus={autoFocus}
      defaultOpen={autoFocus}
      onBlur={onBlur}
      mode={mode}
      value={!value && mode === "multiple" ? [] : value}
      {...selectProps}
    >
      {(data || []).map((r) => (
        <Option key={r[idKey]} value={r[idKey]}>
          {getTitle(r, title)}
        </Option>
      ))}
    </Select>
  );
}

export function AsyncSelect2({
  jrpcMethod,
  jrpcParams = {},
  n8nVersion,
  title = "title",
  idKey = "id",
  onBlur,
  autoFocus,
  mode,
  value,
  deps,
  defaultActiveFirstOption,
  onChange,
  disabled = false,
  ...selectProps
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  let refreshDeps = [jrpcMethod, onChange, value, defaultActiveFirstOption, idKey];
  if (Array.isArray(deps)) {
    refreshDeps = [...refreshDeps, ...deps];
  }

  useEffect(() => {
    fetchWithJsonRpc(jrpcMethod, jrpcParams, {}, n8nVersion)
      .then((result) => {
        let items = [];
        if (Array.isArray(result.items)) {
          items = result.items;
        } else if (Array.isArray(result)) {
          items = result;
        }
        setData(items);
        setIsLoaded(true);

        if (!value && defaultActiveFirstOption) {
          onChange(_.get(items, `[0][${idKey}]`));
        }
      })
      .catch((e) => {
        typeof onBlur === "function" && onBlur();
        notification.error({
          message: "Ошибка",
          description: "Не удалось загрузить список элементов для выбора",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refreshDeps);

  if (!isLoaded) {
    return <Skeleton.Input style={{ width: _.get(selectProps, "style.width") || 200 }} active={true} />;
  }

  return (
    <Select
      autoFocus={autoFocus}
      defaultOpen={autoFocus}
      onBlur={onBlur}
      mode={mode}
      value={!value && mode === "multiple" ? [] : value}
      onChange={onChange}
      disabled={disabled}
      {...selectProps}
    >
      {data.map((r) => (
        <Option key={r[idKey]} value={r[idKey]}>
          {getTitle(r, title)}
        </Option>
      ))}
    </Select>
  );
}

export function AsyncRadioGroup({
  jrpcMethod,
  jrpcParams = {},
  title = "title",
  idKey = "id",
  onBlur,
  value,
  deps,
  disabled = false,
  ...props
}) {
  const refreshDeps = Array.isArray(deps) ? [jrpcMethod, ...deps] : [jrpcMethod];
  const { loading, data = {}, error } = useN8n2(jrpcMethod, jrpcParams, refreshDeps);

  useEffect(() => {
    if (!error) {
      return;
    }

    typeof onBlur === "function" && onBlur();
  }, [error, onBlur]);

  if (loading) {
    return <Skeleton.Input style={{ width: _.get(props, "style.width") || 200 }} active={true} />;
  }

  if (error) {
    return <Text type="danger">Ошибка: Не удалось загрузить список элементов для выбора</Text>;
  }

  let items = [];
  if (Array.isArray(data.items)) {
    items = data.items;
  } else if (Array.isArray(data)) {
    items = data;
  }

  return (
    <Radio.Group
      value={value}
      optionType="button"
      options={items.map((i) => ({ label: getTitle(i, title), value: i[idKey] }))}
      disabled={disabled}
      {...props}
    />
  );
}

export const defaultSearchFilter = (input, option) => {
  const id = _.get(option, "props.value") || _.get(option, "value") || "";
  const title = _.get(option, "props.children") || _.get(option, "label") || "";

  return input.toLowerCase().trim() === id || title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const videoSearchFilter = (input, option) => {
  const title = _.get(option, "props.children") || "";
  const id = _.get(option, "props.value") || "";

  return id.indexOf(input.trim()) >= 0 || title.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
};

export function AsyncSelect({ resource, title, idKey = "id", onBlur, autoFocus = true, mode, value, ...selectProps }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchWith401(`${apiPath}/${resource}`, {}, null, history)
      .then((result) => {
        setData(result);
        setIsLoaded(true);
      })
      .catch(() => {
        typeof onBlur === "function" && onBlur();
        notification.error({
          message: "Ошибка",
          description: "Не удалось загрузить список элементов для выбора",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  if (!isLoaded) {
    return <Skeleton.Input style={{ width: _.get(selectProps, "style.width") || 200 }} active={true} />;
  }

  return (
    <Select
      autoFocus={autoFocus}
      defaultOpen={autoFocus}
      onBlur={onBlur}
      mode={mode}
      value={!value && mode === "multiple" ? [] : value}
      {...selectProps}
    >
      {data.map((r) => (
        <Option key={r[idKey]} value={r[idKey]}>
          {_.get(r, title)}
        </Option>
      ))}
    </Select>
  );
}

export function InputDate(props) {
  const { onSave, value, onChange, ...otherProps } = props;

  const date = moment.utc(value);
  const initValue = date.isValid() ? date.local() : null;

  const onChangeHandler = (value) => {
    let newValue = value;
    if (moment.isMoment(newValue)) {
      newValue = newValue.toISOString();
    }
    onChange(newValue);
  };

  return (
    <DatePicker
      showTime
      format={DateFormats.withSeconds}
      value={initValue}
      onBlur={onSave}
      onOk={onSave}
      onChange={onChangeHandler}
      {...otherProps}
    />
  );
}

export function restoreRangePickerValue(value) {
  if (!Array.isArray(value)) {
    return [];
  }

  return value.map((item) => {
    if (moment.isMoment(item)) {
      return item;
    }

    return moment(item);
  });
}

export function InputDateRange({ value, ...props }) {
  return <RangePicker {...props} value={restoreRangePickerValue(value)} />;
}

export function SelectWithLink({ SelectComponent = Select, path, target = "_self", label = "Открыть", style = {}, value, ...selectProps }) {
  return (
    <div
      style={{
        display: "flex",
        flex: "auto",
        alignItems: "center",
        gridGap: 10,
      }}
    >
      <SelectComponent
        {...selectProps}
        value={value}
        style={{
          flexBasis: "auto",
          flexGrow: "1",
          width: 0,
          ...style,
        }}
      />

      {value && (
        <Link
          style={{
            whiteSpace: "nowrap",
          }}
          to={`${path}${value}`}
          target={target}
        >
          <Space>
            <>{label}</>
            <LinkOutlined />
          </Space>
        </Link>
      )}
    </div>
  );
}

export function AsyncSelect2WithLink(props) {
  return <SelectWithLink {...props} SelectComponent={AsyncSelect2} />;
}

export function AsyncSelectWithLink(props) {
  return <SelectWithLink {...props} SelectComponent={AsyncSelect} />;
}

export function AsyncSelectWithLink3(props) {
  return <SelectWithLink {...props} SelectComponent={AsyncSelect3} />;
}

export function InputWithLink({ target = "_self", style = {}, value, ...props }) {
  return (
    <div
      style={{
        display: "flex",
        flex: "auto",
        alignItems: "center",
        gridGap: 10,
      }}
    >
      <Input {...props} value={value} />

      {value && (
        <AntdLink href={value} target={target}>
          <LinkOutlined />
        </AntdLink>
      )}
    </div>
  );
}
