export const GLOBAL_CONFIG_KEY = "97e74e0a-d6d2-4052-a387-cd4a619f7b94";
export const REQUEST_SIGNATURE_HEADER_NAME = "X-2259932a-2fc9-4b30-9b80-a35d95af59da";

export const cdnHost = "https://gymteam.kinescopecdn.net";
export const fitHost = "https://gymteam.fit";
export const imgPushPath = "/imgpush";
export const apiPath = "/api";
export const n8nPath = "/n8n";
export const n8n2Path = process.env.REACT_APP_N8N2_PATH || "/n8n2";
export const WEB_API_PATH = process.env.REACT_APP_WEB_API_PATH || "/web-api/rpc";

export const DEFAULT_COLOR_MODE = "light";
