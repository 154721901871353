export const addHeading2 = (editor, config) => {
  const bm = editor.BlockManager;

  bm.add("gt-heading2", {
    category: "Basic",
    label: "Heading 2",
    attributes: { class: "gjs-fonts gjs-f-h1p" },
    content: "<h2>Heading 2</h2>",
  });
};
