export const addRedirect = (editor, opts) => {
  const script = function ({ enable, path }) {
    const comp = this;
    comp.classList.add("gt-redirect");

    if (!path) {
      comp.innerHTML = '<p>Требуется указать "path" в настройках компонента</p>';
      return;
    }

    comp.innerHTML = `<p style="${!enable && "text-decoration: line-through;"}">Redirect: ${path}</p>`;
  };

  const scriptExport = function ({ enable, path }) {
    const comp = this;
    comp.classList.add("gt-redirect");

    if (!enable || !path) {
      return;
    }

    window.location.href = path;
  };

  editor.Components.addType("redirect", {
    model: {
      defaults: {
        script,
        "script-export": scriptExport,
        enable: true,
        path: "",
        traits: [
          {
            type: "checkbox",
            name: "enable",
            changeProp: true,
          },
          {
            type: "string",
            name: "path",
            changeProp: true,
          },
        ],
        "script-props": ["path", "enable"],
      },
    },
  });
};
