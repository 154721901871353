export class HTML2UnicodeConverter {
  constructor(text) {
    this.text = text;
  }

  convertNbsp = () => {
    this.text = this.text.replace(/&nbsp;/g, "\u00a0");
    return this;
  };

  convertMdash = () => {
    this.text = this.text.replace(/&mdash;/g, "\u2014");
    return this;
  };

  convertBr = () => {
    this.text = this.text.replace(/<br ?\/?>/gi, "\n");
    return this;
  };

  convertNobr = () => {
    this.text = this.text.replace(/<nobr>(.*?)<\/nobr>/gi, (match, p1) => {
      let result = p1;
      result = result.replace(/ /g, "\u00a0");
      result = result.replace(/-/g, "\u2011");
      return result;
    });
    return this;
  };

  toString() {
    return this.text;
  }

  static convert(text) {
    const instance = new HTML2UnicodeConverter(text);

    return instance.convertNbsp().convertMdash().convertBr().convertNobr().toString();
  }
}
