export const addVideoClip = (editor, opts) => {
  const script = function ({ url }) {
    const comp = this;

    function errorHandler(e) {
      console.error(e);
      comp.innerHTML = `<p style="color: red;">Ошибка</p>`;
    }

    function renderVideo(src) {
      const newScript = document.createElement("script");
      newScript.src = "https://cdn.jsdelivr.net/npm/hls.js@1";
      newScript.onload = () => {
        const { Hls } = window;
        const video = document.createElement("video");
        video.controls = true;
        video.style = "width: 100%";
        comp.appendChild(video);

        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(src);
          hls.attachMedia(video);
        } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
          video.src = src;
        }
      };

      newScript.onerror = errorHandler;

      comp.appendChild(newScript);
    }

    if (!url) {
      comp.innerHTML = `<p>Требуется указать "url" в настройках компонента</p>`;
      return;
    }

    comp.classList.add("gt-videoclip");

    renderVideo(url);
  };

  editor.Components.addType("videoclip", {
    model: {
      defaults: {
        script,
        url: "",
        traits: [
          {
            type: "string",
            name: "url",
            changeProp: true,
          },
        ],
        "script-props": ["url"],
      },
    },
  });
};
