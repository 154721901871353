import { useEffect, useState } from "react";

import { callN8n2 } from "../helpers/helpers";

let cachePromise;
export function useLessonItemDisplayDict() {
  const [dict, setDict] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cachePromise) {
      setLoading(true);
      cachePromise
        .then((cache) => {
          setDict(cache);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    setLoading(true);
    cachePromise = callN8n2("admin.lesson_item_display_dict.list.v1", { all: true })
      .then((result) => {
        setDict(result);
        return result;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { dict, loading };
}
