import { JHTMLContextProvider } from "./components/JHTMLContext";
import { JHTMLError } from "./components/JHTMLError";
import { replacePlaceholders, bindEvents, injectGlobalVars } from "./helpers";
import { matchComponent, shouldWrapToContextProvider } from "./mapping";

function JHTMLContextWrapper({ element, options, children, ...externalProps }) {
  return <JHTMLContextProvider value={{ render, options, children, renderChildren, externalProps }}>{element}</JHTMLContextProvider>;
}

export function render({ type, children, props, key = 0 }, options = {}) {
  const { vars } = options || {};
  const _vars = injectGlobalVars(vars);

  try {
    const Component = matchComponent(type);
    const _props = bindEvents(replacePlaceholders(props, _vars), _vars);

    const element = (
      <Component key={key} {..._props}>
        {renderChildren(children, options)}
      </Component>
    );

    if (shouldWrapToContextProvider(type)) {
      return <JHTMLContextWrapper key={key} element={element} options={options} children={children} />;
    }

    return element;
  } catch (e) {
    try {
      return renderSimpleNode(type, replacePlaceholders(props, _vars));
    } catch (e) {
      console.error(e);

      return <JHTMLError />;
    }
  }
}

function renderChildren(children, options) {
  if (Array.isArray(children)) {
    return children.map((child, idx) => render({ ...child, key: idx }, options));
  }

  return children;
}

function renderSimpleNode(type, props) {
  switch (type) {
    case "null":
      return null;
    case "text_node":
      return props.value;

    default:
      throw new Error(`Wrong node type: ${type}`);
  }
}
