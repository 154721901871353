import { Link } from "react-router-dom";
import { Tabs } from "antd";

import { history } from "../history";

const { TabPane } = Tabs;

export function TabPaneLink(props) {
  return <Link {...props} style={{ color: "inherit" }} onClick={(e) => e.stopPropagation()} />;
}

export function MyTabs({ items, resolvePath, ...props }) {
  return (
    <Tabs {...props} onChange={(key) => history.push(resolvePath(key))}>
      {items.map(({ key, title, children, disabled, ...other }) => {
        return (
          <TabPane
            key={key}
            disabled={disabled}
            tab={disabled ? title : <TabPaneLink to={resolvePath(key)}>{title}</TabPaneLink>}
            {...other}
          >
            {children}
          </TabPane>
        );
      })}
    </Tabs>
  );
}
