import { getSiteUrl } from "../../../helpers/helpers";
import { cmdCloseEditor, cmdOpenPage, cmdSave } from "./constants";

const closeEditor = (editor, config) => {
  return () => {
    const { onCloseEditor } = config;

    if (editor.getDirtyCount()) {
      const result = window.confirm("Вы собираетесь покинуть страницу. Возможно, внесенные изменения не сохранятся");
      return result && onCloseEditor();
    }

    onCloseEditor();
  };
};

const save = (editor) => {
  return () => {
    editor.store();
  };
};

const openPage = (editor, config) => {
  return () => {
    const { page } = config;

    const link = document.createElement("a");
    link.href = `${getSiteUrl()}/${page.path}`;
    link.target = "_blank";

    link.click();
  };
};

export const commands = (editor, config) => {
  const cm = editor.Commands;

  cm.add(cmdCloseEditor, closeEditor(editor, config));
  cm.add(cmdSave, save(editor, config));
  cm.add(cmdOpenPage, openPage(editor, config));
};
