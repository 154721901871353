import React, { useEffect, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { Button, Typography, Skeleton, Input, Space } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";

import { defaultPreviewImage } from "../constants";
import { callN8n2 } from "../helpers/helpers";

const { Text } = Typography;

const CellRendererContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  cursor: pointer;
`;

const CellRendererTitle = styled(Button)`
  padding: 0;
  max-width: 300px;
  flex-shrink: 1;

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const CellRendererLink = styled(Button)`
  padding: 0;
`;

export function VideoClipCellRenderer({ id, placeholder }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      return;
    }

    callN8n2(`admin.video.details.v1`, { id })
      .then((result) => {
        setData(result);
        setIsLoaded(true);
      })
      .catch(setError);
  }, [id]);

  if (!id) {
    return <Text italic>{placeholder || "Не задано"}</Text>;
  }

  if (error) {
    return (
      <Text type="danger" italic>
        Ошибка
      </Text>
    );
  }

  if (!isLoaded) {
    return <Skeleton.Input style={{ width: 200 }} active={true} />;
  }

  const light = _.get(data, "image_url");

  return (
    <CellRendererContainer>
      <div>
        <CellRendererTitle type="link">{_.get(data, "title")}</CellRendererTitle>
        <CellRendererLink type="link" target="_blank" href={`/videos/${id}`} onClick={(e) => e.stopPropagation()} icon={<LinkOutlined />} />
      </div>

      <ReactPlayer
        width="250px"
        height="250px"
        url={_.get(data, "url")}
        light={light || defaultPreviewImage}
        playIcon={light ? null : <div />}
        controls={true}
        playing={true}
        playsinline={true}
        onClick={(e) => e.stopPropagation()}
      />
    </CellRendererContainer>
  );
}

const InputVideoClipContainer = styled.div`
  display: flex;
`;

const InputVideoClipLeftSide = styled.div`
  width: 300px;
  flex-shrink: 0;
`;

const InputVideoClipRightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  width: 100%;
`;

const InputVideoClipPlaceholder = styled.div`
  width: 300px;
  height: 300px;
  background-color: #cccccc;
`;

const InputVideoClipSaveButton = styled(Button)`
  margin-top: 10px;
`;

function format(seconds) {
  const date = new Date(seconds * 1000);

  const hh = pad(date.getUTCHours());
  const mm = pad(date.getUTCMinutes());
  const ss = pad(date.getUTCSeconds());

  return `${hh}:${mm}:${ss}`;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

export function Duration({ seconds }) {
  return <time dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</time>;
}

function InputVideoClipMeta({ title, isReady, error, value }) {
  return (
    <Space>
      <Text>{title}:</Text>
      {error ? (
        <Text type="danger">Ошибка</Text>
      ) : (
        <>{isReady ? <Text>{value}</Text> : <Skeleton.Button active={true} size="small" style={{ height: 22 }} />}</>
      )}
    </Space>
  );
}

export function InputVideoClip({ onSave, preview, durationInit, onDurationChange, ...inputProps }) {
  const { value } = inputProps;

  const [isMetaReady, setIsMetaReady] = useState(true);
  const [meta, setMeta] = useState({});
  const [metaError, setMetaError] = useState(null);

  useEffect(() => {
    setIsMetaReady(false);
    setMetaError(null);

    callN8n2("admin.video_meta.details.v1", { url: value })
      .then((result) => {
        setMeta(result);
        setIsMetaReady(true);
      })
      .catch(setMetaError);
  }, [value]);

  return (
    <InputVideoClipContainer>
      <InputVideoClipLeftSide>
        {value ? (
          <ReactPlayer
            width="300px"
            height="300px"
            url={value.trim()}
            light={preview || defaultPreviewImage}
            playIcon={preview ? null : <div />}
            controls={true}
            playing={false}
            playsinline={true}
          />
        ) : (
          <InputVideoClipPlaceholder />
        )}
      </InputVideoClipLeftSide>
      <InputVideoClipRightSide>
        <Input {...inputProps} />
        <Space direction="vertical" style={{ marginTop: 10 }}>
          <InputVideoClipMeta title="Длительность" isReady={isMetaReady} error={metaError} value={meta.duration} />
          <InputVideoClipMeta title="Размер" isReady={isMetaReady} error={metaError} value={meta.size} />
          <InputVideoClipMeta title="Разрешение" isReady={isMetaReady} error={metaError} value={meta.resolution} />
        </Space>
        {onSave && <InputVideoClipSaveButton onClick={onSave}>Сохранить</InputVideoClipSaveButton>}
      </InputVideoClipRightSide>
    </InputVideoClipContainer>
  );
}
