import { useState } from "react";
import { Button, Input, Popconfirm, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export function InputList({ value: initValue, onChange, onSave, autoFocus }) {
  const [values, setValues] = useState(Array.isArray(initValue) ? initValue : [""]);

  function setNewValue(value) {
    onChange(null);
    setValues(value);
    setTimeout(() => {
      onChange(value);
    }, 1);
  }

  function handleAdd() {
    setNewValue([...values, ""]);
  }

  function handleItemChange(value, index) {
    const newValues = [...values];
    newValues[index] = value;

    setNewValue(newValues);
  }

  function handleItemDelete(index) {
    const newValues = [...values];
    newValues.splice(index, 1);

    if (!newValues.length) {
      newValues.push("");
    }

    setNewValue(newValues);
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {values.map((value, index) => {
        return (
          <div key={index} style={{ width: "100%", display: "flex", alignItems: "center", gap: "10px" }}>
            <Input.TextArea
              autoSize={{ minRows: 1 }}
              value={value}
              onChange={(e) => handleItemChange(e.target.value, index)}
              autoFocus={autoFocus}
            />
            <Popconfirm title="Удалить?" onConfirm={() => handleItemDelete(index)}>
              <DeleteOutlined style={{ color: "#999999", fontSize: 18 }} />
            </Popconfirm>
          </div>
        );
      })}
      <Space>
        <Button size="small" type="primary" ghost onClick={handleAdd}>
          + Добавить
        </Button>

        {onSave && (
          <Button size="small" type="primary" onClick={onSave}>
            Сохранить
          </Button>
        )}
      </Space>
    </Space>
  );
}
