import React, { useEffect, useState } from "react";
import { Image as AntdImage } from "antd";
import _ from "lodash";

import { imgpushPath } from "../constants";
import { loadImageDataUrl } from "../helpers/helpers";
import { history } from "../history";

function AsyncImage({ src: imgUrl, ...props }) {
  const [src, setSrc] = useState(null);
  const { onError } = props;

  useEffect(() => {
    if (!imgUrl) {
      return;
    }
    async function loadImage() {
      try {
        const dataUrl = await loadImageDataUrl(imgUrl, history);
        setSrc(dataUrl);
      } catch (e) {
        console.log("AsyncImage error", e);
        typeof onError === "function" && onError(e);
      }
    }
    loadImage();
  }, [imgUrl, onError]);

  return <AntdImage src={src} {...props} />;
}

export function Image(props) {
  const { src } = props;

  const isImgpush = _.isString(src) && src.includes(imgpushPath);

  const ImageComponent = isImgpush ? AsyncImage : AntdImage;
  return <ImageComponent {...props} />;
}
