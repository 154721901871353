import { useContext } from "react";
import _ from "lodash";

import { JHTMLContext } from "../JHTMLContext";
import { replacePlaceholders } from "../../helpers";

export function ArrayMap({ template, items }) {
  const { options, render } = useContext(JHTMLContext);

  const vars = _.get(options, "vars") || {};

  return replacePlaceholders(items, vars).map((item, idx) =>
    render(
      {
        ...template,
        key: idx,
      },
      {
        vars: {
          ...vars,
          idx,
          item,
        },
      }
    )
  );
}
