export const addDiv = (editor, opts) => {
  editor.Components.addType("div", {
    model: {
      defaults: {
        droppable: true,
        style: {
          "min-height": "24px",
        },
        traits: ["id", "title", "bp"],
      },
    },
  });
};
