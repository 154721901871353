import { Descriptions } from "antd";
import styled from "styled-components";

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-gap: 10px;
`;

export const CardDescriptions = styled(Descriptions)`
  background-color: white;
  padding: 15px 20px;
  margin-bottom: 15px;
`;
