import React, { useState, useRef, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
  notification,
} from "antd";
import { DeleteOutlined, FolderOpenOutlined, RedoOutlined } from "@ant-design/icons";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

import EditableTable2 from "../components/EditableTable2";
import { SelectUser } from "../components/SelectUser";
import { DateCellRenderer, TrainingCellRenderer, TrainingPackageCellRenderer } from "../components/CellRenderer";
import { AsyncSelect2, defaultSearchFilter, restoreRangePickerValue } from "../components/FormInput";
import { ReferenceField2 } from "../components/ReferenceField";
import { PageError } from "../components/PageError";
import { CardHeader } from "../components/Card";
import { MyTabs } from "../components/Tabs";

import { DateFormats } from "../constants";
import { getSiteUrl } from "../helpers/helpers";
import { showFailureSaveMessage, showFailureMessage, showSuccessSaveMessage } from "../helpers/notification";
import { handleDeleteAdminRpc, handleSaveAdminRpc } from "../helpers/request";
import { useQuery } from "../hooks/useQuery";
import { useManyJsonRpc } from "../hooks/useManyJsonRpc";
import { useN8n } from "../hooks/useN8n";
import { useN8n2 } from "../hooks/useN8n2";
import { useN8nDefer } from "../hooks/useN8nDefer";
import { history } from "../history";
import { render } from "../jhtml";
import { useN8n2Defer } from "../hooks/useN8n2Defer";
import { updateOrder } from "../components/Orders";
import { usePgrst } from "../hooks/usePgrst";

const { Text, Title, Link: AntdLink } = Typography;
const { RangePicker } = DatePicker;

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, zoomPlugin);

function tagColorByValue(value) {
  switch (value) {
    case "new":
      return "green";
    case "inwork":
      return "cyan";
    case "cancelled":
      return "red";
    case "paused":
    default:
      return "gold";
  }
}

function fetchQueryParam(query, name, fallback) {
  try {
    return JSON.parse(query.get(name));
  } catch {
    return fallback;
  }
}

const WORK_STATUS_PSEUDO_OPTION_ANY = "any";
const WORK_STATUS_PSEUDO_OPTIONS = [
  {
    label: "все статусы",
    value: WORK_STATUS_PSEUDO_OPTION_ANY,
  },
];

const MANAGERS_PSEUDO_OPTION_ANY = "any";
const MANAGERS_PSEUDO_OPTION_WITHOUT = "without";
const MANAGERS_PSEUDO_OPTION_WITH = "with";
const MANAGERS_PSEUDO_OPTIONS = [
  {
    label: "не важно",
    value: MANAGERS_PSEUDO_OPTION_ANY,
  },
  {
    label: "с менеджером",
    value: MANAGERS_PSEUDO_OPTION_WITH,
  },
  {
    label: "без менеджера",
    value: MANAGERS_PSEUDO_OPTION_WITHOUT,
  },
];

const OFFERS_PSEUDO_OPTION_ANY_SUBS = "any_subs";

const OFFERS_PSEUDO_OPTIONS = [
  {
    label: "Все заказы с подписками",
    value: OFFERS_PSEUDO_OPTION_ANY_SUBS,
  },
];

function OrderFilters({
  orderText,
  sumText,
  userText,
  waiting,
  loadData,
  onChange,
  onReset,
  workStatus,
  paymentStatus,
  idManager,
  createdDateRange,
  paidDateRange,
  idOffer,
  jrpcParams,
}) {
  const { data, loading, error } = useManyJsonRpc(
    useN8n2("admin.manager.list.v1"),
    useN8n2("admin.order_work_status.list.v1"),
    useN8n2("admin.order_status.list.v1"),

    useN8n2("admin.platform.list.v2"),

    useN8n2("admin.offer.list.v1", { only_published: true }),
    useN8n("admin.offer_subscribe.list.v1", { only_published: true }),
    useN8n2("admin.offer_increase.list.v1", { only_published: true }),
    useN8n2("admin.offer_package.list.v1", { only_published: true })
  );

  if (loading) {
    return <Skeleton active />;
  }

  if (error) {
    return <PageError />;
  }

  const [managers, workStatuses, paidStatuses, platforms, ...offers] = data;

  const workStatusOptions = [
    ...WORK_STATUS_PSEUDO_OPTIONS,
    ...workStatuses.map((item) => ({ label: item.title, value: item.id, disabled: item.disabled })),
  ];

  const paymentStatusOptions = paidStatuses.map((item) => ({ label: item.title, value: item.id, disabled: item.disabled }));

  const managerOptions = [...MANAGERS_PSEUDO_OPTIONS, ...managers.map((item) => ({ label: item.name, value: item.id }))];

  const offersOptions = [
    ...OFFERS_PSEUDO_OPTIONS,
    ...platforms.map((item) => ({ label: "Все по платформе: " + item.desc, value: "platform_" + item.id })),
    ...offers.flatMap((o) => o.items || o).map((item) => ({ label: item.title, value: item.id })),
  ];

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={tagColorByValue(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  function onFieldChange(field) {
    return (value) => {
      onChange(field, value);
    };
  }

  return (
    <Row gutter={[15, 25]}>
      <Col xs={24} md={16}>
        <Row gutter={[15, 15]}>
          <Col xs={24} md={14}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Row style={{ alignItems: "center" }} gutter={10}>
                <Col xs={24} md={6} style={{ textAlign: "right" }}>
                  <Text>Статус</Text>
                </Col>

                <Col xs={24} md={18}>
                  <Select
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    value={workStatus}
                    style={{
                      width: "100%",
                    }}
                    options={workStatusOptions}
                    onChange={onFieldChange("work_status")}
                  />
                </Col>
              </Row>

              <Row style={{ alignItems: "center" }} gutter={10}>
                <Col xs={24} md={6} style={{ textAlign: "right" }}>
                  <Text>Менеджеры</Text>
                </Col>

                <Col xs={24} md={18}>
                  <Select
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    value={idManager}
                    style={{
                      width: "100%",
                    }}
                    options={managerOptions}
                    onChange={onFieldChange("id_manager")}
                  />
                </Col>
              </Row>

              <Row style={{ alignItems: "center" }} gutter={10}>
                <Col xs={24} md={6} style={{ textAlign: "right" }}>
                  <Text>Офферы</Text>
                </Col>

                <Col xs={24} md={18}>
                  <Select
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    value={idOffer}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                    options={offersOptions}
                    onChange={onFieldChange("id_offer")}
                    showSearch={true}
                    filterOption={defaultSearchFilter}
                  />
                </Col>
              </Row>
            </Space>
          </Col>
          <Col xs={24} md={10}>
            <Space direction="vertical">
              <RangePicker
                showTime
                placeholder={["Cоздано с", "По"]}
                value={restoreRangePickerValue(createdDateRange)}
                onChange={onFieldChange("created")}
              />
              <RangePicker
                showTime
                placeholder={["Оплачено с", "По"]}
                value={restoreRangePickerValue(paidDateRange)}
                onChange={onFieldChange("paid")}
              />
              <Space style={{ minHeight: 32 }}>
                <Checkbox.Group options={paymentStatusOptions} value={paymentStatus} onChange={onFieldChange("payment_status")} />
              </Space>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={8}>
        <Space direction="vertical" size={20}>
          <Space direction="vertical" size={0}>
            <Space size={3}>
              <Text strong>{orderText ? `${_.get(orderText, "title")},` : null}</Text>
              <Text type="secondary">{_.get(orderText, "sub_title")}</Text>
            </Space>
            <Space size={3}>
              <Text strong>{userText ? `${_.get(userText, "title")},` : null}</Text>
              <Text type="secondary">{_.get(userText, "sub_title")}</Text>
            </Space>
            <Space size={3}>
              <Text strong>{sumText ? `${_.get(sumText, "title")},` : null}</Text>
              <Text type="secondary">{_.get(sumText, "sub_title")}</Text>
            </Space>
          </Space>

          <Space>
            <Button onClick={loadData} type="danger" ghost icon={<RedoOutlined />} disabled={waiting}>
              Фильровать
            </Button>

            {render(
              {
                type: "antd_dropdown",
                props: {
                  overlay: {
                    type: "antd_menu",
                    children: [
                      {
                        id: "order_users_export",
                        title: "Уникальные пользователи",
                      },
                      {
                        id: "double_order_export",
                        title: "Все заказы с дублями",
                      },
                    ].map((exportType) => ({
                      type: "antd_menu_item",
                      props: {
                        key: exportType.id,
                      },
                      children: [
                        {
                          type: "antd_popconfirm",
                          props: {
                            title: "Делаем экспорт?",
                            okText: "Да",
                            cancelText: "Нет",
                            onConfirm: {
                              fn: `
                                callN8n2("admin.order.download_csv.v1", { ...jrpcParams, export_type: "${exportType.id}" })
                                  .then(({ link }) => {
                                    const a = document.createElement("a");
                                    a.href = link;
                                    a.target = "_blank";
                                    a.click();
                                  })
                                  .catch((e) => {
                                    const description = e.message === "5" ? "Нет данных!" : "Что-то пошло не так. Попробуйте ещё раз";
                                    notification.error({
                                      message: "Ошибка",
                                      description,
                                    });
                                  });
                                `,
                            },
                          },
                          children: [
                            {
                              type: "text_node",
                              props: {
                                value: exportType.title,
                              },
                            },
                          ],
                        },
                      ],
                    })),
                  },
                },
                children: [
                  {
                    type: "antd_button",
                    props: {
                      type: "primary",
                      ghost: true,
                      icon: "DownloadOutlined",
                    },
                  },
                ],
              },
              {
                vars: {
                  jrpcParams,
                },
              }
            )}

            <Button onClick={onReset} type="link">
              <Text type="secondary" underline>
                сбросить
              </Text>
            </Button>
            <Link to={"/orders/create"} style={{ textDecoration: "underline" }}>
              добавить заказ
            </Link>
          </Space>
        </Space>
      </Col>
    </Row>
  );
}

export function OrdersPage() {
  const { tab = "list" } = useParams();

  return (
    <MyTabs
      activeKey={tab}
      resolvePath={(key) => `/orders/${key}`}
      items={[
        { key: "list", title: "Список заказов", children: <OrdersTable /> },
        { key: "sales", title: "Продажи", children: <Sales /> },
      ]}
    />
  );
}

function getInitDateRange() {
  const date = new Date();
  const from = new Date(date.getFullYear(), date.getMonth(), 1);
  const to = new Date(date.getFullYear(), date.getMonth() + 1);

  return [from.toISOString(), to.toISOString()];
}

function OrdersTable() {
  const query = useQuery();

  const queryPage = parseInt(query.get("page"));
  const currentPage = !isNaN(queryPage) ? queryPage : 1;

  const queryPageSize = parseInt(query.get("pageSize"));
  const pageSize = !isNaN(queryPageSize) ? queryPageSize : 50;

  const workStatus = fetchQueryParam(query, "work_status", [WORK_STATUS_PSEUDO_OPTION_ANY]) || [WORK_STATUS_PSEUDO_OPTION_ANY];
  const paymentStatus = fetchQueryParam(query, "payment_status", []) || ["new", "paid"];
  const idManager = fetchQueryParam(query, "id_manager", [MANAGERS_PSEUDO_OPTION_ANY]) || [MANAGERS_PSEUDO_OPTION_ANY];
  const createdDateRange = fetchQueryParam(query, "created", []) || getInitDateRange();
  const paidDateRange = fetchQueryParam(query, "paid", []) || [];
  const idOffer = fetchQueryParam(query, "id_offer", []) || [];

  const params = {
    limit: pageSize,
    offset: pageSize * (currentPage - 1),
    id_offer: idOffer,
    payment_status: paymentStatus,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  if (!workStatus.length || workStatus.includes(WORK_STATUS_PSEUDO_OPTION_ANY)) {
    params.any_work_status = true;
  } else {
    params.work_status = workStatus;
  }

  if (!idManager.length || idManager.includes(MANAGERS_PSEUDO_OPTION_ANY)) {
    params.any_manager = true;
  } else {
    if (idManager.includes(MANAGERS_PSEUDO_OPTION_WITHOUT)) {
      params.without_manager = true;
    }

    if (idManager.includes(MANAGERS_PSEUDO_OPTION_WITH)) {
      params.with_manager = true;
    }

    const pseudoOptionsValues = MANAGERS_PSEUDO_OPTIONS.map((i) => i.value);
    const uuidItems = idManager.filter((i) => !pseudoOptionsValues.includes(i));
    if (uuidItems.length) {
      params.id_manager = uuidItems;
    }
  }

  if (Array.isArray(createdDateRange) && createdDateRange.length === 2) {
    params.created_from = createdDateRange[0];
    params.created_until = createdDateRange[1];
  }

  if (Array.isArray(paidDateRange) && paidDateRange.length === 2) {
    params.paid_from = paidDateRange[0];
    params.paid_until = paidDateRange[1];
  }

  const { loading, error, data = {}, refetch } = useN8n2("admin.screen.order_list.v1", params, [
    pageSize,
    currentPage,
    JSON.stringify(workStatus),
    JSON.stringify(paymentStatus),
    JSON.stringify(idManager),
    JSON.stringify(createdDateRange),
    JSON.stringify(paidDateRange),
    JSON.stringify(idOffer),
  ]);

  function handleFilterChange(filter, value) {
    query.set(filter, JSON.stringify(value));
    query.delete("page");
    history.push(`/orders?${query.toString()}`);
  }

  function handleResetFilters() {
    history.push(`/orders`);
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Space direction="vertical" size={30} style={{ width: "100%" }}>
        <OrderFilters
          orderText={data.order_text}
          sumText={data.sum_text}
          userText={data.user_text}
          waiting={loading}
          loadData={refetch}
          onChange={handleFilterChange}
          onReset={handleResetFilters}
          workStatus={workStatus}
          paymentStatus={paymentStatus}
          idManager={idManager}
          createdDateRange={createdDateRange}
          paidDateRange={paidDateRange}
          idOffer={idOffer}
          jrpcParams={params}
        />

        {data.table_content
          ? render(data.table_content, {
              vars: {
                loading,
                currentPage,
                pageSize,
                query,
              },
            })
          : loading && <Table loading />}
      </Space>
    </>
  );
}

function OrderBaseForm({ data, submitForm }) {
  const isEditFormMode = !!data.id_user;
  const isStatusPaid = _.get(data, "status") === "paid";

  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Пользователь" name="id_user">
        <SelectUser isDisabled={isStatusPaid} />
      </Form.Item>

      <Form.Item label="Статус обработки" name="work_status">
        <AsyncSelect2 jrpcMethod="admin.order_work_status.list.v1" title="title" style={{ width: 360 }} n8nVersion={2} />
      </Form.Item>

      <Form.Item label="Менеджер" name="id_manager">
        <AsyncSelect2 jrpcMethod="admin.manager.list.v1" title="name" style={{ width: 360 }} n8nVersion={2} />
      </Form.Item>

      <Form.Item label="Статус оплаты" name="status">
        <AsyncSelect2
          jrpcMethod="admin.order_status.list.v1"
          title="desc"
          style={{ width: 360 }}
          disabled={!isEditFormMode || isStatusPaid}
        />
      </Form.Item>

      {isEditFormMode && (
        <>
          <Form.Item label="Дата создания" name="created_at">
            <DateCellRenderer format={DateFormats.withSeconds} />
          </Form.Item>

          {_.get(data, "status") === "paid" && (
            <Form.Item label="Дата оплаты" name="updated_at">
              <DateCellRenderer format={DateFormats.withSeconds} />
            </Form.Item>
          )}
        </>
      )}

      {isStatusPaid || (
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
            Сохранить
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

export function OrderCreate() {
  const create = useN8n2Defer("admin.order.create.v1");

  function submitForm(submission) {
    create(submission)
      .then(({ id }) => {
        history.push(`/orders/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/orders">Заказы</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Новый заказ</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <OrderBaseForm data={{ status: "new", work_status: "new" }} submitForm={submitForm} />
    </>
  );
}

function OrderItems({ id, orderStatus }) {
  const { loading, error, data = {} } = useN8n2("admin.order_item.list.v1", { id }, [id]);

  const deleteHandler = (e, succ) => {
    handleDeleteAdminRpc("order_item", { id_order: id })(e, succ).catch((e) => console.error(e));
  };

  const saveHandler = (s, succ) => {
    handleSaveAdminRpc("order_item", { id_order: id })(s, succ).catch((e) => showFailureMessage(e.message));
  };

  if (error) {
    return <PageError />;
  }

  if (loading) {
    return <Table loading={true} />;
  }

  if (orderStatus === "paid") {
    return (
      <Table
        dataSource={data.items}
        pagination={false}
        style={{ marginBottom: 10 }}
        columns={[
          {
            title: "Цена",
            dataIndex: "price",
            width: 50,
            defaultValue: 0,
            render: (t, r) => t || 0,
          },
          {
            title: "Оффер",
            dataIndex: "id_offer",
            render: (t, r) => {
              return (
                <ReferenceField2
                  resource="offer"
                  source={r.id_offer}
                  idKey="id"
                  title={(data) => `${data.title} / ${data.platform}`}
                  titleLink={(data) => {
                    return `/offers/${data.id}`;
                  }}
                />
              );
            },
          },
          {
            title: "Оффер-подписка",
            dataIndex: "id_offer_subscribe",
            render: (t, r) => {
              return (
                <ReferenceField2
                  resource="offer_subscribe"
                  source={r.id_offer_subscribe}
                  idKey="id"
                  title={(data) => `${data.title} / ${data.platform}`}
                  titleLink={(data) => {
                    return `/offers/subscribes/${data.id}`;
                  }}
                />
              );
            },
          },
          {
            title: "Оффер-пакет",
            dataIndex: "id_offer_package",
            render: (t, r) => {
              return (
                <ReferenceField2
                  resource="offer_package"
                  source={r.id_offer_package}
                  idKey="id"
                  title={(data) => `${data.title} / ${data.platform}`}
                  titleLink={(data) => {
                    return `/offers/offer_packages/${data.id}`;
                  }}
                />
              );
            },
          },
          {
            title: "Оффер-продление",
            dataIndex: "id_offer_increase",
            render: (t, r) => {
              return (
                <ReferenceField2
                  n8nVersion={2}
                  resource="offer_increase"
                  source={r.id_offer_increase}
                  idKey="id"
                  title={(data) => `${data.title} / ${data.platform}`}
                  titleLink={(data) => {
                    return `/offers/offer_increase/${data.id}`;
                  }}
                />
              );
            },
          },
        ]}
      />
    );
  }

  return (
    <EditableTable2
      handleDelete={deleteHandler}
      handleSave={saveHandler}
      buttonAddLabel="Добавить позицию"
      dataSource={data.items}
      loading={loading}
      pagination={false}
      style={{ marginBottom: 10 }}
      columns={[
        {
          title: "Цена",
          dataIndex: "price",
          width: 50,
          editable: true,
          defaultValue: 0,
          render: (t, r) => t || 0,
        },
        {
          title: "Оффер",
          dataIndex: "id_offer",
          editable: true,
          defaultValue: null,
          width: "30%",
          render: (t, r) => {
            return (
              <ReferenceField2
                resource="offer"
                source={r.id_offer}
                idKey="id"
                title={(data) => `${data.title} / ${data.platform}`}
                link={(data) => {
                  return `/offers/${data.id}`;
                }}
              />
            );
          },
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect2
                jrpcMethod="admin.offer.list.v1"
                n8nVersion={2}
                autoFocus={true}
                defaultValue={r.id_offer}
                title={(data) => `${data.title} / ${_.get(data, "platform.desc")}`}
                onBlur={save}
                showSearch={true}
                filterOption={defaultSearchFilter}
                onChange={() => {
                  r.id_offer_subscribe = null;
                  r.id_offer_package = null;
                  r.id_offer_increase = null;
                }}
              />
            );
          },
        },
        {
          title: "Оффер-подписка",
          dataIndex: "id_offer_subscribe",
          editable: true,
          defaultValue: null,
          width: "30%",
          render: (t, r) => {
            return (
              <ReferenceField2
                resource="offer_subscribe"
                source={r.id_offer_subscribe}
                idKey="id"
                title={(data) => `${data.title} / ${data.platform}`}
                link={(data) => {
                  return `/offers/subscribes/${data.id}`;
                }}
              />
            );
          },
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect2
                jrpcMethod="admin.offer_subscribe.list.v1"
                autoFocus={true}
                defaultValue={r.id_offer_subscribe}
                title={(data) => `${data.title} / ${_.get(data, "platform.desc")}`}
                onBlur={save}
                showSearch={true}
                filterOption={defaultSearchFilter}
                onChange={() => {
                  r.id_offer = null;
                  r.id_offer_package = null;
                  r.id_offer_increase = null;
                }}
              />
            );
          },
        },
        {
          title: "Оффер-пакет",
          dataIndex: "id_offer_package",
          editable: true,
          defaultValue: null,
          width: "30%",
          render: (t, r) => {
            return (
              <ReferenceField2
                resource="offer_package"
                source={r.id_offer_package}
                idKey="id"
                title={(data) => `${data.title} / ${data.platform}`}
                link={(data) => {
                  return `/offers/offer_packages/${data.id}`;
                }}
              />
            );
          },
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect2
                jrpcMethod="admin.offer_package.list.v1"
                autoFocus={true}
                defaultValue={r.id_offer_package}
                title={(data) => `${data.title} / ${_.get(data, "platform.desc")}`}
                onBlur={save}
                showSearch={true}
                filterOption={defaultSearchFilter}
                onChange={() => {
                  r.id_offer = null;
                  r.id_offer_subscribe = null;
                  r.id_offer_increase = null;
                }}
              />
            );
          },
        },
        {
          title: "Оффер-продление",
          dataIndex: "id_offer_increase",
          editable: true,
          defaultValue: null,
          width: "30%",
          render: (t, r) => {
            return (
              <ReferenceField2
                n8nVersion={2}
                resource="offer_increase"
                source={r.id_offer_increase}
                idKey="id"
                title={(data) => `${data.title} / ${data.platform}`}
                link={(data) => {
                  return `/offers/offer_increase/${data.id}`;
                }}
              />
            );
          },
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect2
                n8nVersion={2}
                jrpcMethod="admin.offer_increase.list.v1"
                autoFocus={true}
                defaultValue={r.id_offer_increase}
                title={(data) => `${data.title} / ${_.get(data, "platform.desc")}`}
                onBlur={save}
                showSearch={true}
                filterOption={defaultSearchFilter}
                onChange={() => {
                  r.id_offer = null;
                  r.id_offer_subscribe = null;
                  r.id_offer_package = null;
                }}
              />
            );
          },
        },
      ]}
    />
  );
}

function OrderPurchases({ id, orderStatus }) {
  const { loading, error, data = {} } = usePgrst("order_purchases_list_v1", { params: { id } }, [id, orderStatus]);

  const columns = [
    {
      title: "Дата создания",
      dataIndex: "created_at",
      render: (value, record) => {
        const createdAtDisp = (
          <Button type="primary" ghost={true} size="small" icon={<FolderOpenOutlined style={{ marginRight: 5 }} />}>
            <DateCellRenderer value={value} format={DateFormats.withSeconds} />
          </Button>
        );
        const linkPath = record.type === "purchase_subscribe" ? `/purchases/subscribes/${record.id}` : `/purchases/${record.id}`;
        return <Link to={linkPath}>{createdAtDisp}</Link>;
      },
    },
    {
      title: "Тип продукта",
      dataIndex: "type",
      render: (t, r) => (r.type === "purchase_subscribe" ? "Пакет тренингов" : "Тренинг"),
    },
    {
      title: "Продукт",
      dataIndex: "product",
      render: (t, r) => {
        if (r.type === "purchase_subscribe") {
          return <TrainingPackageCellRenderer data={t} />;
        }
        return <TrainingCellRenderer data={t} />;
      },
    },
  ];

  if (error) {
    return <PageError />;
  }

  return <Table columns={columns} dataSource={data.items} loading={loading} pagination={false} style={{ marginBottom: 15 }} />;
}

function OrderPayments({ id }) {
  const { loading, error, data = {} } = useN8n2("admin.payment.list.v1", { id }, [id]);

  const columns = [
    {
      title: "Дата создания",
      dataIndex: "created_at",
      render: (v) => <DateCellRenderer value={v} format={DateFormats.withSeconds} />,
    },
    {
      title: "Тип",
      dataIndex: "type",
      render: (v, r) => v,
    },
    {
      title: "Статус",
      dataIndex: "status_info",
      render: (v, r) => <Tag color={_.get(v, "color")}>{_.get(v, "title")}</Tag>,
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      render: (v, r) => {
        return v || "-";
      },
    },
  ];

  if (error) {
    return <PageError />;
  }

  return <Table columns={columns} dataSource={data.items} loading={loading} pagination={false} />;
}

export function OrderEdit() {
  const { id, tab = "base" } = useParams();

  const { loading, error, data = {}, refetch } = useN8n2("admin.order.details.v1", { id }, [id]);
  const remove = useN8nDefer("admin.order.delete.v1");

  function submitForm(submission) {
    updateOrder({ ...submission, id })
      .then(() => {
        if (submission.status === "paid") {
          refetch();
        }
      })
      .then(showSuccessSaveMessage)
      .catch(showFailureSaveMessage);
  }

  async function onDelete() {
    try {
      await remove({ id });
      notification.success({
        description: "Заказ удалён",
      });
      history.push(`/orders`);
    } catch (e) {
      notification.error({
        description: "Не удалось удалить заказ",
      });
    }
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/orders">Заказы</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.id}</Breadcrumb.Item>
        </Breadcrumb>
        <Popconfirm disabled={loading} title="Заказ будет удалён. Продолжить?" onConfirm={onDelete} okText="Удалить" cancelText="Отмена">
          <Button type="danger" ghost={true} icon={<DeleteOutlined />} disabled={loading}>
            Удалить заказ
          </Button>
        </Popconfirm>
      </CardHeader>

      <Divider />

      <MyTabs
        activeKey={tab}
        resolvePath={(key) => `/orders2/${id}/${key}`}
        items={[
          {
            key: "base",
            title: "Основные поля",
            children: (
              <>
                {loading ? <Skeleton active /> : <OrderBaseForm data={data} submitForm={submitForm} />}

                <Title level={5}>UTM:</Title>
                <Space direction="vertical" size={10} style={{ marginBottom: 30 }}>
                  <Space>
                    <Text>utm_source:</Text>
                    <Text strong>{_.get(data, "utm_params.utm_source")}</Text>
                  </Space>
                  <Space>
                    <Text>utm_medium:</Text>
                    <Text strong>{_.get(data, "utm_params.utm_medium")}</Text>
                  </Space>
                  <Space>
                    <Text>utm_content:</Text>
                    <Text strong>{_.get(data, "utm_params.utm_content")}</Text>
                  </Space>
                </Space>

                <Title level={5}>Ссылка на оплату:</Title>
                <AntdLink href={`${getSiteUrl()}/payment/${id}`} target="_blank">
                  {`${getSiteUrl()}/payment/${id}`}
                </AntdLink>

                <Title level={5}>Позиции заказа</Title>
                <OrderItems id={id} orderStatus={_.get(data, "status")} />
                <Title level={5}>Связанные покупки</Title>
                <OrderPurchases id={id} orderStatus={_.get(data, "status")} />
              </>
            ),
          },
          { key: "payments", title: "Платежи", children: <OrderPayments id={id} /> },
        ]}
      />
    </>
  );
}

function SalesFilters({ periodOptions, aggPeriodOptions, offerTypeOptions, period, aggrPeriod, offerType, onChange }) {
  function onFieldChange(field) {
    return (value) => {
      onChange(field, value);
    };
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Text>Период</Text>
        <Select
          showArrow
          value={
            period ||
            _.get(
              periodOptions.find((i) => !!i.сhecked),
              "value"
            )
          }
          options={periodOptions}
          onChange={onFieldChange(SALES_QUERY_PARAM_PERIOD)}
          style={{ width: "100%" }}
        />
      </Space>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Text>Группировка</Text>
        <Select
          showArrow
          value={
            aggrPeriod ||
            _.get(
              aggPeriodOptions.find((i) => !!i.сhecked),
              "value"
            )
          }
          options={aggPeriodOptions}
          onChange={onFieldChange(SALES_QUERY_PARAM_AGGR_PERIOD)}
          style={{ width: "100%" }}
        />
      </Space>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Text>Тип оффера</Text>
        <Checkbox.Group
          value={offerType ? offerType : offerTypeOptions.filter((i) => i.сhecked).map((i) => i.value)}
          onChange={onFieldChange(SALES_QUERY_PARAM_OFFER_TYPE)}
        >
          <Space direction="vertical" size={0}>
            {offerTypeOptions.map((i) => {
              return (
                <Space key={i.value}>
                  <div style={{ backgroundColor: i.backgroundColor, width: 16, height: 16 }} />
                  <Checkbox value={i.value}>{i.label}</Checkbox>
                </Space>
              );
            })}
          </Space>
        </Checkbox.Group>
      </Space>
    </Space>
  );
}

function SalesTotal({ data }) {
  if (!Array.isArray(data)) {
    return null;
  }

  return (
    <Space direction="vertical" size={3}>
      {data.map((item) => (
        <Space key={item.title} direction="vertical" size={0}>
          <Text strong>{item.title}</Text>
          <Text>{item.sum}</Text>
        </Space>
      ))}
    </Space>
  );
}

function OrderLink({ data }) {
  return <Link to={data.link}>{data.title}</Link>;
}

const SALES_QUERY_PARAM_PERIOD = "period";
const SALES_QUERY_PARAM_AGGR_PERIOD = "aggr_period";
const SALES_QUERY_PARAM_OFFER_TYPE = "offer_type";

const SalesChart = React.memo(function ({ data, handleZoom }) {
  const chartRef = useRef();

  return (
    <Bar
      ref={chartRef}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                try {
                  return `${context.dataset.label}: ${context.formattedValue} руб.`;
                } catch (e) {
                  return null;
                }
              },
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
              onZoomComplete: handleZoom,
            },
            pan: {
              enabled: true,
              mode: "x",
              onPanComplete: handleZoom,
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      }}
      data={data}
      onClick={(event) => {
        const element = getElementAtEvent(chartRef.current, event);
        const index = _.get(element, "[0].index");
        const link = _.get(data, `links[${index}]`);

        if (link) {
          window.open(link, "_blank");
        }
      }}
    />
  );
});

function Sales() {
  const query = useQuery();

  const period = query.get(SALES_QUERY_PARAM_PERIOD);
  const aggregate_period = query.get(SALES_QUERY_PARAM_AGGR_PERIOD);
  const offer_type = fetchQueryParam(query, SALES_QUERY_PARAM_OFFER_TYPE, []);
  const [fakePeriod, setFakePeriod] = useState(null);

  const { loading, data, error } = useN8n2(
    "admin.screen.order_sales.v1",
    {
      period,
      aggregate_period,
      offer_type,
    },
    [period, aggregate_period, JSON.stringify(offer_type)]
  );

  function handleFilterChange(periodMap) {
    return (filter, value) => {
      setFakePeriod(null);

      const v = typeof value === "string" ? value : JSON.stringify(value);

      query.set(filter, v);

      if (filter === SALES_QUERY_PARAM_PERIOD) {
        query.set(SALES_QUERY_PARAM_AGGR_PERIOD, _.get(periodMap, `${v}`));
      }

      history.push(`/orders/sales?${query.toString()}`);
    };
  }

  const handleZoom = useMemo(
    () =>
      _.debounce(({ chart }) => {
        try {
          if (chart.isZoomedOrPanned()) {
            const x = chart.scales.x;
            const { min, max } = x.getMinMax();

            const minLabel = x.getLabelForValue(min);
            const maxLabel = x.getLabelForValue(max);

            setFakePeriod(`${minLabel} — ${maxLabel}`);
          } else {
            setFakePeriod(null);
          }
        } catch (e) {
          console.error(e);
        }
      }, 100),
    []
  );

  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} md={18}>
        {loading && <Skeleton active />}
        {error && <PageError />}
        {!loading && !error && <SalesChart data={data.chart} handleZoom={handleZoom} />}
      </Col>
      <Col xs={24} md={6}>
        {data && (
          <Space direction="vertical" size={10} style={{ width: "100%", position: "relative", top: "-50px", marginBottom: "-50px" }}>
            <SalesFilters
              periodOptions={data.period}
              aggPeriodOptions={data.aggregate_period}
              offerTypeOptions={data.offer_type}
              period={fakePeriod || period}
              aggrPeriod={aggregate_period}
              offerType={offer_type}
              onChange={handleFilterChange(data.period_map)}
            />
            <SalesTotal data={data.total_text} />
            <OrderLink data={data.order_link} />
          </Space>
        )}
      </Col>
    </Row>
  );
}
