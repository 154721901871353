import {
  Badge,
  Breadcrumb,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Input,
  InputNumber,
  Menu,
  Popconfirm,
  Radio,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography,
} from "antd";
import { Link, NavLink, Redirect, Route, Switch as RouterSwitch } from "react-router-dom";

import { OrderManager, OrderWorkStatus, OrderComment } from "../components/Orders";
import { Image } from "../components/Image";
import { HTML2Unicode } from "../components/HTML2Unicode";
import { InputImage } from "../components/InputImage";
import { InputList } from "../components/InputList";
import { InputSegmentConditions } from "../components/InputSegmentConditions";
import { InputVideoClip, VideoClipCellRenderer } from "../components/VideoClip";
import { InputDate, InputDateRange, InputWithLink } from "../components/FormInput";
import { ReferenceField2 } from "../components/ReferenceField";
import { SelectUser } from "../components/SelectUser";
import { ImageCellRenderer } from "../components/CellRenderer";
import { HTMLEditor } from "../components/HTMLEditor";
import { InputColorSwitch } from "../components/InputColorSwitch";
import { VideoTimeCodesPreviewer } from "../components/VideoTimeCodesPreviewer";
import { SortableTable } from "./components/Table";
import { AsyncSelect as ActivityAsyncSelect } from "../components/Activity";

import {
  Alert,
  ArrayMap,
  AsyncSelect,
  AsyncSelectWithLink,
  AsyncTabs,
  Button,
  Div,
  Dropdown,
  UseForm,
  Form,
  FormItem,
  ForwardProps,
  Icon,
  IF,
  InitVar,
  ReactUseState,
  Fragment,
  Modal,
  ReactHTML,
  Span,
  Select,
  SelectWithLink,
  Switch,
  Table,
  EditableTable,
  UnorderedList,
  OrderedList,
  ListItem,
} from "./components";
import { Screen, CardScreen, QueryScreen, TabCardScreen, TabQueryScreen } from "./components/Screen";

export function shouldWrapToContextProvider(type) {
  switch (type) {
    case "antd_alert":
    case "antd_dropdown":
    case "antd_use_form":
    case "antd_form":
    case "antd_table":
    case "async_tabs":
    case "init_var":
    case "switch":
    case "react_use_state":
    case "editable_table":
    case "sortable_table":
    case "forward_props":
      return true;

    default:
      return false;
  }
}

export function matchComponent(type) {
  switch (type) {
    case "screen":
      return Screen;
    case "card_screen":
      return CardScreen;
    case "query_screen":
      return QueryScreen;
    case "tab_card_screen":
      return TabCardScreen;
    case "tab_query_screen":
      return TabQueryScreen;
    case "fragment":
      return Fragment;
    case "div":
      return Div;
    case "ul":
      return UnorderedList;
    case "ol":
      return OrderedList;
    case "li":
      return ListItem;
    case "span":
      return Span;
    case "if":
      return IF;
    case "switch":
      return Switch;
    case "init_var":
      return InitVar;
    case "forward_props":
      return ForwardProps;
    case "array_map":
      return ArrayMap;
    case "react_use_state":
      return ReactUseState;
    case "react_html":
      return ReactHTML;

    case "router_link":
      return Link;
    case "router_nav_link":
      return NavLink;
    case "router_redirect":
      return Redirect;
    case "router_route":
      return Route;
    case "router_switch":
      return RouterSwitch;

    case "antd_alert":
      return Alert;
    case "antd_badge":
      return Badge;
    case "antd_breadcrumb":
      return Breadcrumb;
    case "antd_breadcrumb_item":
      return Breadcrumb.Item;
    case "antd_card":
      return Card;
    case "antd_button":
      return Button;
    case "antd_checkbox":
      return Checkbox;
    case "antd_checkbox_group":
      return Checkbox.Group;
    case "antd_col":
      return Col;
    case "antd_descriptions":
      return Descriptions;
    case "antd_descriptions_item":
      return Descriptions.Item;
    case "antd_divider":
      return Divider;
    case "antd_dropdown":
      return Dropdown;
    case "antd_icon":
      return Icon;
    case "antd_use_form":
      return UseForm;
    case "antd_form":
      return Form;
    case "antd_form_item":
      return FormItem;
    case "antd_input":
      return Input;
    case "antd_input_password":
      return Input.Password;
    case "antd_input_number":
      return InputNumber;
    case "antd_link":
      return Typography.Link;
    case "antd_modal":
      return Modal;
    case "antd_paragraph":
      return Typography.Paragraph;
    case "antd_radio":
      return Radio;
    case "antd_radio_group":
      return Radio.Group;
    case "antd_row":
      return Row;
    case "antd_menu":
      return Menu;
    case "antd_menu_item":
      return Menu.Item;
    case "antd_menu_item_group":
      return Menu.ItemGroup;
    case "antd_popconfirm":
      return Popconfirm;
    case "antd_skeleton":
      return Skeleton;
    case "antd_select":
      return Select;
    case "antd_select_with_link":
      return SelectWithLink;
    case "antd_space":
      return Space;
    case "antd_table":
      return Table;
    case "antd_tag":
      return Tag;
    case "antd_text":
      return Typography.Text;
    case "antd_textarea":
      return Input.TextArea;
    case "antd_title":
      return Typography.Title;
    case "image":
      return Image;

    case "async_select":
      return AsyncSelect;
    case "async_select_with_link":
      return AsyncSelectWithLink;
    case "activity_async_select":
      return ActivityAsyncSelect;
    case "async_tabs":
      return AsyncTabs;
    case "html_to_unicode":
      return HTML2Unicode;
    case "order_manager_selector":
      return OrderManager;
    case "order_work_status_selector":
      return OrderWorkStatus;
    case "order_comment_editor":
      return OrderComment;
    case "select_user":
      return SelectUser;
    case "reference_field":
      return ReferenceField2;
    case "editable_table":
      return EditableTable;
    case "sortable_table":
      return SortableTable;
    case "input_date":
      return InputDate;
    case "input_date_range":
      return InputDateRange;
    case "input_image":
      return InputImage;
    case "input_segment_conditions":
      return InputSegmentConditions;
    case "input_videoclip":
      return InputVideoClip;
    case "input_list":
      return InputList;
    case "input_html":
      return HTMLEditor;
    case "input_color_switch":
      return InputColorSwitch;
    case "input_with_link":
      return InputWithLink;

    case "cell_videoclip":
      return VideoClipCellRenderer;
    case "cell_image":
      return ImageCellRenderer;

    case "video_timecodes_previewer":
      return VideoTimeCodesPreviewer;

    default:
      throw new Error(`Wrong node type: ${type}`);
  }
}
