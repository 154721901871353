import { components } from "./components";
import { blocks } from "./blocks";
import { commands } from "./commands";
import { panels } from "./panels";
import { styles } from "./styles";
import "./style.css";

export function gtCustomPlugin(editor, opts = {}) {
  components(editor, opts);
  blocks(editor, opts);
  commands(editor, opts);
  panels(editor, opts);
  styles(editor, opts);
}
