import React, { useState, useEffect } from "react";
import _ from "lodash";

import { AsyncSelectWithLink3, defaultSearchFilter } from "../components/FormInput";
import { useQuery } from "../hooks/useQuery";

import { callAdminRpc } from "../helpers/helpers";
import { history } from "../history";

export const createActivityRef = (activityRef, props, idActivity) => {
  const aid = idActivity || new URLSearchParams(document.location.search).get("id_activity");

  if (!aid) return;

  return callAdminRpc(
    "activity_ref_create_v1",
    {
      params: Object.assign(
        {
          ...props,
        },
        {
          id: _.get(activityRef, "id", null),
          id_activity: aid,
        }
      ),
    },
    {}
  );
};

export function AsyncSelect({ onComplete, onChange, value, ...props }) {
  const query = useQuery();
  const [idActivity, setIdActivity] = useState(null);
  const [activityRef, setActivityRef] = useState(null);

  useEffect(() => {
    callAdminRpc("get_activity_ref_v1", { params: props }).then((r) => {
      const id_activity = _.get(r, "id_activity", query.get("id_activity"));
      setActivityRef(r);
      setIdActivity(id_activity);
      typeof onChange === "function" && id_activity && onChange(id_activity);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activityRef && onComplete) {
      onComplete(activityRef);
    }
    // eslint-disable-next-line
  }, [activityRef]);

  const _onChange = (e) => {
    query.set("id_activity", e);
    history.push({ search: query.toString() });
    typeof onChange === "function" && onChange(e);

    return e !== idActivity ? setIdActivity(e) : null;
  };

  const onClear = () => {
    query.delete("id_activity");
    history.push({ search: query.toString() });

    return callAdminRpc(
      "activity_ref_delete_v1",
      {
        params: props,
      },
      {}
    );
  };

  const onBlur = () => idActivity && createActivityRef(activityRef, props, idActivity).then((r) => setActivityRef(r));

  return (
    <AsyncSelectWithLink3
      adminApiMethod="as_activity_list_v1"
      path="/activities/"
      target="_blank"
      autoFocus={false}
      showSearch={true}
      filterOption={defaultSearchFilter}
      allowClear={true}
      onClear={onClear}
      onBlur={onBlur}
      value={idActivity}
      onChange={_onChange}
      {...props}
    />
  );
}
