import _ from "lodash";

import { cmdCloseEditor, cmdOpenPage, cmdSave } from "./constants";

export const panels = (editor, config) => {
  const pn = editor.Panels;

  const panelGT = pn.addPanel({ id: "gt" });

  panelGT.get("buttons").add([
    {
      id: cmdCloseEditor,
      command: cmdCloseEditor,
      className: "fa fa-arrow-left",
      attributes: {
        title: "Закрыть редактор",
      },
    },
  ]);

  const { title, path } = _.get(config, "page") || {};
  const pageTitle = `${path} - ${title}`;

  panelGT.get("buttons").add([
    {
      id: `${cmdCloseEditor}-page-title`,
      command: cmdCloseEditor,
      className: "gjs-pn-gt__button-title",
      label: pageTitle,
      attributes: {
        title: "Открыть карточку страницы",
      },
    },
  ]);

  panelGT.get("buttons").add([
    {
      id: cmdSave,
      command: cmdSave,
      className: "fa fa-save ",
      disable: true,
      attributes: {
        title: "Сохранить",
      },
    },
  ]);

  panelGT.get("buttons").add([
    {
      id: cmdOpenPage,
      command: cmdOpenPage,
      className: "fa fa-eye",
      attributes: {
        title: "Открыть в новом окне (режим отображения)",
      },
      active: false,
    },
  ]);

  editor.on("update", () => {
    const saveBtn = editor.Panels.getButton("gt", cmdSave);
    const isDisabled = saveBtn.attributes.disable;
    const isDirty = editor.getDirtyCount();

    if (isDirty) {
      if (isDisabled) {
        saveBtn.set("disable", false);
      }
    } else {
      saveBtn.set("disable", true);
    }
  });

  editor.on("load", () => {
    const swVisibilityBtn = pn.getButton("options", "sw-visibility");
    swVisibilityBtn && swVisibilityBtn.set("active", 1);
  });
};
