export const addColumns4 = (editor, config) => {
  editor.Blocks.add("columns4", {
    category: "Layout",
    label: "4 Columns",
    attributes: { class: "gjs-fonts gjs-f-b3" },
    content: `
<div data-gjs-type="div" bp="grid 3">
  <div data-gjs-type="div"></div>
  <div data-gjs-type="div"></div>
  <div data-gjs-type="div"></div>
  <div data-gjs-type="div"></div>
</div>`,
  });
};
