import _ from "lodash";
import { useEffect, useState, useCallback } from "react";

export function useApi(params = {}, deps = [], options = {}, exec = null) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState();

  const fetchData = useCallback(async () => {
    setLoading(true);

    return exec(params)
      .then((data) => {
        setData(data);
        setError(null);
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, deps);

  async function refetch() {
    return fetchData();
  }

  const { defer, condition } = options || {};

  useEffect(() => {
    if (!defer) {
      const shouldExec = _.isUndefined(condition) || condition;
      if (!shouldExec) {
        setLoading(false);
        return;
      }

      fetchData();
    }
  }, [fetchData, defer, condition]);

  return { loading, error, data, setData, refetch, exec };
}
