export const addDiv = (editor, config) => {
  const bm = editor.BlockManager;

  bm.add("gt-div", {
    category: "Layout",
    label: "DIV",
    attributes: { class: "gjs-fonts gjs-f-b1" },
    content: { type: "div" },
  });
};
