import React, { useEffect, useState, useCallback } from "react";
import { Router, Route, Switch, Link, Redirect, useParams, useLocation, useRouteMatch, generatePath } from "react-router-dom";
import "moment/locale/ru";
import _ from "lodash";
import styled from "styled-components";
import {
  Button,
  Table,
  Form,
  Input,
  Divider,
  Typography,
  Select,
  Breadcrumb,
  Checkbox,
  Modal,
  Popconfirm,
  Descriptions,
  Skeleton,
  Switch as AntdSwitch,
  List,
  notification,
} from "antd";
import { CheckOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";

import { Announcements } from "./routes/Announcements";
import { Forms, FormCreate, FormEdit } from "./routes/Forms";
import { LessonEdit, LessonsTable } from "./routes/Lessons";
import {
  Offers,
  OfferCreate,
  OfferEdit,
  OfferIncreaseCreate,
  OfferIncreaseEdit,
  OfferSubscribeCreate,
  OfferSubscribeEdit,
  OfferPackageCreate,
  OfferPackageEdit,
} from "./routes/Offers";
import { OrderCreate, OrderEdit, OrdersPage } from "./routes/Orders";
import { Pages, PageCreate, PageEdit, PageEditor } from "./routes/Pages";
import {
  Purchases,
  PurchasesCreate,
  PurchasesEdit,
  PurchaseCard,
  PurchasesCreateByOfferPackage,
  PurchaseSubscribeCreate,
  PurchaseSubscribeEdit,
} from "./routes/Purchases";
import { SegmentCard, SegmentCreate } from "./routes/Segment";
import { StreamCard, StreamCreate, StreamEdit, StreamsTable, STREAM_TYPE_DYNAMIC } from "./routes/Stream";
import { TrainingEdit, TrainingCreate, TrainingsTabs, TrainingPackageCreate, TrainingPackageEdit } from "./routes/Trainings";
import { UsersCreate, UsersEdit, UsersTable } from "./routes/Users";

import { AsyncSelect as ActivityAsyncSelect } from "./components/Activity";
import EditableTable2 from "./components/EditableTable2";
import { SearchUserForm, SelectUserModal } from "./components/SelectUser";
import {
  DateCellRenderer,
  UserCellRenderer,
  TrainingCellRenderer,
  PracticItemRenderer,
  PracticAnswerStatusRenderer,
  PracticCellRenderer,
  LessonCellRenderer,
} from "./components/CellRenderer";
import { Duration } from "./components/VideoClip";
import { AsyncSelect } from "./components/FormInput";
import { Image } from "./components/Image";
import { FileLink } from "./components/FileLink";
import { PageError } from "./components/PageError";
import { CardButtons, CardDescriptions, CardHeader } from "./components/Card";
import { MyTabs } from "./components/Tabs";
import { PageErrorBoundary } from "./components/ErrorBoundary";

import { getUserData, setJWT, useIsAuthorized } from "./auth";
import { DateFormats, apiPath } from "./constants";
import { fetchWith401, fetchWithJsonRpc, incrementPosition, callN8n2, callAdminRpc } from "./helpers/helpers";
import { showFailureMessage, showFailureSaveMessage, showSuccessSaveMessage } from "./helpers/notification";
import { handleSave, handleDelete } from "./helpers/request";
import { useQuery } from "./hooks/useQuery";
import { usePgrst } from "./hooks/usePgrst";

import MyLayout from "./MyLayout";
import { history } from "./history";
import { CardScreen, render } from "./jhtml";

import "./App.css";

const { Title } = Typography;
const { Option } = Select;

function PracticsCreate() {
  function submitForm(e) {
    // admin.practic.create.v1
    fetchWith401(
      `${apiPath}/practic?select=id`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Prefer: "return=representation",
          Accept: "application/vnd.pgrst.object+json",
        },
        body: JSON.stringify(e),
      },
      ({ id }) => {
        history.push(`/practics/${id}/base`);
      },
      history
    );
  }

  return <PracticBaseForm data={{}} submitForm={submitForm} />;
}

function VideosTable() {
  const query = useQuery();

  const queryPage = parseInt(query.get("page"));
  const currentPage = !isNaN(queryPage) ? queryPage : 1;

  const queryPageSize = parseInt(query.get("pageSize"));
  const pageSize = !isNaN(queryPageSize) ? queryPageSize : 50;

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);

  const loadData = useCallback((params) => {
    const { limit, offset } = params || {};

    setIsLoaded(false);
    return fetchWithJsonRpc("admin.video.list.v1", { limit, offset })
      .then(({ total, items }) => {
        setData(items);
        setTotal(total);
        setError(null);
      })
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, []);

  useEffect(() => {
    loadData({
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
    });
  }, [loadData, pageSize, currentPage]);

  function createNew() {
    history.push("/videos/create");
  }

  function handleTableChange(pagination) {
    query.set("page", pagination.current);
    history.push(`/videos?${query.toString()}`);
  }

  function onShowSizeChange(current, size) {
    query.set("pageSize", size);
    history.push(`/videos?${query.toString()}`);
  }

  const columns = [
    {
      title: "Обложка",
      dataIndex: "image_url",
      key: "image_url",
      render: (image_url, record) => (
        <Link to={`/videos/${record.id}`}>
          <Image width={100} src={image_url} preview={false} />
        </Link>
      ),
      width: 100,
    },
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (title, record) => <Link to={`/videos/${record.id}`}>{title}</Link>,
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Продолжительность",
      dataIndex: "duration",
      key: "duration",
      render: (value) => {
        const duration = parseInt(value);
        return isNaN(duration) ? "-" : <Duration seconds={duration / 1000} />;
      },
    },
  ];

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Button type="primary" onClick={createNew}>
        + Добавить
      </Button>
      <Divider />
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={!isLoaded}
        onChange={handleTableChange}
        pagination={{
          current: currentPage,
          pageSize,
          total,
          onShowSizeChange,
        }}
      />
    </>
  );
}

function PracticsTable() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    // admin.practic.list.v1
    fetchWith401(
      `${apiPath}/practic`,
      {},
      (result) => {
        setData(result);
        setIsLoaded(true);
      },
      history
    );
  }, []);

  function handleClick(id) {
    history.push(`/practics/${id}/base`);
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <Button type="link" onClick={() => handleClick(record.id)}>
          {text}
        </Button>
      ),
    },
  ];

  if (!isLoaded) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <Button type="primary" onClick={() => history.push("/practics/create")}>
        + Добавить
      </Button>
      <Divider />
      <Table columns={columns} dataSource={data} rowKey="id" />
    </div>
  );
}

function PracticAnswerCardData({ data }) {
  const answers = _.get(data, "answers") || {};
  const practicItems = _.get(data, "practic_item") || [];

  const practicAswers = practicItems
    .sort((a, b) => a.position - b.position)
    .map((practicItem) => {
      return {
        id: practicItem.id,
        title: practicItem.title,
        type: practicItem.display,
        value: answers[practicItem.id],
      };
    });

  const renderPracticItemValue = (value, type) => {
    if (!value) {
      return "-";
    }

    if (Array.isArray(value)) {
      return (
        <List
          split={false}
          size="small"
          itemLayout="horizontal"
          dataSource={value.map((v) => renderPracticItemValue(v, type))}
          renderItem={(item) => <List.Item style={{ padding: 0 }}>{item}</List.Item>}
        />
      );
    }

    switch (type) {
      case "switch":
        return value ? "Да" : "Нет";
      case "upload":
        return <FileLink href={`${_.get(value, "path")}`}>{_.get(value, "name")}</FileLink>;
      default:
        break;
    }

    return value;
  };

  return (
    <>
      <CardDescriptions title="Карточка ответа" layout="horizontal" bordered column={2} size="small">
        <Descriptions.Item label="Дата создания">
          <DateCellRenderer value={_.get(data, "created_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>
        <Descriptions.Item label="Пользователь">
          <UserCellRenderer data={_.get(data, "user")} />
        </Descriptions.Item>

        <Descriptions.Item label="Дата изменения">
          <DateCellRenderer value={_.get(data, "updated_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>
        <Descriptions.Item label="Тренинг">
          <TrainingCellRenderer data={_.get(data, "training")} />
        </Descriptions.Item>

        <Descriptions.Item label="Статус">
          <PracticAnswerStatusRenderer value={_.get(data, "status")} />
        </Descriptions.Item>
        <Descriptions.Item label="Урок">
          <LessonCellRenderer data={_.get(data, "lesson")} />
        </Descriptions.Item>

        <Descriptions.Item label="Куратор">{_.get(data, "id_curator")}</Descriptions.Item>
        <Descriptions.Item label="Практика">
          <PracticCellRenderer data={_.get(data, "practic")} />
        </Descriptions.Item>

        <Descriptions.Item label="Комментарий куратора" span={1}>
          {_.get(data, "curator_comment")}
        </Descriptions.Item>
      </CardDescriptions>

      <CardDescriptions
        labelStyle={{ textAlign: "right", width: "30%" }}
        title="Поля ответа"
        layout="horizontal"
        bordered
        column={1}
        size="small"
      >
        {practicAswers.map((answer) => {
          return (
            <Descriptions.Item key={answer.id} label={answer.title}>
              {renderPracticItemValue(answer.value, answer.type)}
            </Descriptions.Item>
          );
        })}
      </CardDescriptions>
    </>
  );
}

const ReviewStatus = {
  Accept: "a",
  Decline: "d",
};

function PracticAnswerReviewModal({ id, visible, status, onCancel, onReviewSuccess }) {
  const [form] = Form.useForm();

  const reviewHandler = () => {
    form.submit();
  };

  async function submitHandler(submission) {
    try {
      await fetchWithJsonRpc("admin.practic_answer.review.v1", {
        id,
        comment: submission.comment,
        status,
      });
      notification.success({
        description: `Ответ ${status === ReviewStatus.Accept ? "принят" : "отклонён"}`,
      });
      onReviewSuccess();
    } catch (e) {
      notification.error({
        description: `Не удалось ${status === ReviewStatus.Accept ? "принять" : "отклонить"} ответ`,
      });
    }
  }

  return (
    <Modal
      title={status === ReviewStatus.Accept ? "Принять ответ" : "Отклонить ответ"}
      visible={visible}
      width={800}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button key="submit" onClick={reviewHandler} type={status === ReviewStatus.Accept ? "primary" : "danger"}>
          {status === ReviewStatus.Accept ? "Принять" : "Отклонить"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={{ comment: "" }} onFinish={submitHandler}>
        <Form.Item label="Комментарий" name="comment">
          <Input.TextArea autoSize={{ minRows: 5, maxRows: 8 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function PracticAnswerCard() {
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  const loadData = useCallback(() => {
    callN8n2("admin.practic_answer.details.v1", { id })
      .then((data) => {
        setData(data);
        setIsLoaded(true);
      })
      .catch(setError);
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  async function onDelete() {
    try {
      await fetchWith401(
        `${apiPath}/practic_answer_orig?id=eq.${id}`,
        {
          method: "DELETE",
        },
        (result) => {
          notification.success({
            description: "Ответ удалён",
          });
          history.push(`/practic_answers`);
        },
        history
      );
    } catch (e) {
      notification.error({
        description: "Не удалось удалить ответ",
      });
    }
  }

  const [isRevieModalVisible, setIsRevieModalVisible] = useState(false);
  const [reviewStatus, setReviewStatus] = useState();
  const acceptHandler = () => {
    setReviewStatus(ReviewStatus.Accept);
    setIsRevieModalVisible(true);
  };
  const declineHandler = () => {
    setReviewStatus(ReviewStatus.Decline);
    setIsRevieModalVisible(true);
  };
  const cancelReviewHandler = () => {
    setIsRevieModalVisible(false);
  };
  const successReviewHandler = () => {
    setIsRevieModalVisible(false);
    history.push("/practic_answers?status=pending");
  };

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <PracticAnswerReviewModal
        id={id}
        visible={isRevieModalVisible}
        status={reviewStatus}
        onCancel={cancelReviewHandler}
        onReviewSuccess={successReviewHandler}
      />
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/practic_answers">Ответы</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{id}</Breadcrumb.Item>
        </Breadcrumb>
        <CardButtons>
          {_.get(data, "status") === "pending" && (
            <>
              <Button type="primary" icon={<CheckOutlined />} onClick={acceptHandler}>
                Принять ответ
              </Button>
              <Button type="danger" icon={<CloseOutlined />} onClick={declineHandler}>
                Отклонить ответ
              </Button>
            </>
          )}
          <Popconfirm
            disabled={!isLoaded}
            title="Ответ будет удалён. Продолжить?"
            onConfirm={onDelete}
            okText="Удалить"
            cancelText="Отмена"
          >
            <Button type="danger" ghost={true} icon={<DeleteOutlined />} disabled={!isLoaded}>
              Удалить ответ
            </Button>
          </Popconfirm>
        </CardButtons>
      </CardHeader>

      <Divider />

      {isLoaded ? <PracticAnswerCardData data={data} /> : <Skeleton active />}
    </>
  );
}

const UploadTypes = {
  image: "Изображение",
  video: "Видео",
};

function PracticsEdit() {
  let { id, tab } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    fetchWith401(
      // admin.practic.details.v1
      `${apiPath}/practic?id=eq.${id}&select=*,practic_item(*)&practic_item.order=position.asc`,
      {
        headers: {
          Accept: "application/vnd.pgrst.object+json",
        },
      },
      (result) => {
        setData(result);
        setIsLoaded(true);
      },
      history
    );
  }, [id]);

  function submitForm(e) {
    fetchWith401(
      // admin.practic.update.v1
      `${apiPath}/practic?id=eq.${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(_.mergeWith(e, { id: id })),
      },
      () => {},
      history
    );
  }

  if (!isLoaded) {
    return <h1>Loading...</h1>;
  }

  return (
    <MyTabs
      activeKey={tab}
      resolvePath={(key) => `/practics/${id}/${key}`}
      items={[
        { key: "base", title: "Основное", children: <PracticBaseForm data={data} submitForm={submitForm} /> },
        {
          key: "blocks",
          title: "Блоки",
          children: (
            <EditableTable2
              buttonAddLabel="Добавить блок"
              handleDelete={handleDelete(history, "practic_item")}
              handleSave={handleSave(history, "practic_item", id, ["key"], {
                id_practic: id,
              })}
              columns={[
                {
                  title: "#",
                  dataIndex: "position",
                  width: 30,
                  editable: true,
                  defaultValue: incrementPosition(10),
                  render: (t, r) => t || 0,
                },

                {
                  title: "Заголовок задания",
                  dataIndex: "title",
                  width: "30%",
                  editable: true,
                  defaultValue: null,
                  render: (t, r) => t || <i>Нет названия</i>,
                },

                {
                  title: "Тип блока",
                  dataIndex: "display",
                  width: "30%",
                  editable: true,
                  defaultValue: null,
                  render: (t, r) => <PracticItemRenderer value={t} />,
                  inputNode: (ref, save, r) => {
                    return <AsyncSelect onBlur={save} defaultValue={r.access_period} title="desc" resource="practic_item_display_dict" />;
                  },
                },

                {
                  title: "Обязательный",
                  dataIndex: "is_required",
                  width: "30px",
                  editable: true,
                  defaultValue: false,
                  alwaysEditable: true,
                  inputNode: (ref, save, r) => {
                    return <AntdSwitch defaultChecked={r.is_required} onChange={save} />;
                  },
                },
              ]}
              dataSource={data.practic_item}
              expandable={{
                expandedRowRender: (r) => {
                  if (r.display === "many_select" || r.display === "single_select") {
                    return (
                      <EditableTable2
                        buttonAddLabel="Добавить элемент"
                        handleDelete={handleSave(history, "practic_item", null, null, null, (e) => {
                          return {
                            content: (r.content = _.filter(r.content, function (n) {
                              return n.key !== e.key;
                            })),
                            id: r.id,
                            id_practic: id,
                          };
                        })}
                        handleSave={handleSave(history, "practic_item", null, null, null, (e) => {
                          return {
                            content: (r.content = _.concat(r.content || [], e)),
                            id: r.id,
                            id_practic: id,
                          };
                        })}
                        columns={[
                          {
                            title: "Текст",
                            dataIndex: "value",
                            editable: true,
                            render: (t, r) => t || "-",
                          },
                        ]}
                        dataSource={r.content || []}
                      />
                    );
                  }

                  if (r.display === "upload") {
                    return (
                      <EditableTable2
                        key={r.display}
                        handleDelete={() => {}}
                        handleSave={handleSave(history, "practic_item", null, null, null, (e) => {
                          return {
                            content: (r.content = e),
                            id: r.id,
                            id_practic: id,
                          };
                        })}
                        columns={[
                          {
                            title: "Тип файла",
                            dataIndex: "type",
                            editable: true,
                            render: (t, r) => UploadTypes[t] || "-",
                            inputNode: (ref, save) => (
                              <Select onChange={save}>
                                {Object.keys(UploadTypes).map((type) => {
                                  return (
                                    <Option key={type} value={type}>
                                      {UploadTypes[type]}
                                    </Option>
                                  );
                                })}
                              </Select>
                            ),
                          },
                        ]}
                        dataSource={_.concat([], r.content)}
                      />
                    );
                  }
                },
              }}
            />
          ),
        },
      ]}
    />
  );
}

function UserGroupBaseForm({ data, submitForm }) {
  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Название" name="title">
        <Input />
      </Form.Item>

      <Form.Item label="Входит в активность" name="id_activity" rules={[{ required: true }]}>
        <ActivityAsyncSelect id_user_group={data.id} />
      </Form.Item>

      <Form.Item label="Лимит участников" name="items_limit">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

function UserGroupsCreate() {
  function submitForm(submission) {
    fetchWithJsonRpc("admin.user_group.create.v1", submission).then((result) => {
      history.push(`/user_groups/${result.id}`);
    });
  }

  return (
    <div>
      <Title level={3}>Новая группа</Title>
      <Divider />
      <UserGroupBaseForm data={{}} submitForm={submitForm} />
    </div>
  );
}

const UserGroupsEditHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserGroupsEditButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-gap: 10px;
`;

function UserGroupUsersTable({ groupId }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const loadData = useCallback((groupId, searchText) => {
    setIsLoaded(false);
    callN8n2("admin.user_group_item.search_by_group.v1", { id: groupId, q: searchText }).then((result) => {
      setData(Array.isArray(result) ? result : []);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    loadData(groupId, searchText);
  }, [loadData, groupId, searchText]);

  async function deleteUserGroupItem(id) {
    try {
      await fetchWithJsonRpc("admin.user_group_item.delete.v1", { id });
      await loadData(groupId, searchText);
      notification.success({
        description: "Пользователь удалён из группы",
      });
    } catch (e) {
      notification.error({
        description: "Не удалось удалить пользователя из группы",
      });
    }
  }

  async function addUserGroupItem(id_user, id_user_group) {
    try {
      await fetchWithJsonRpc("admin.user_group_item.create.v1", { id_user, id_user_group });
      await loadData(groupId, searchText);
    } catch (e) {
      throw e;
    }
  }

  const columns = [
    {
      title: "Фото",
      dataIndex: "photo",
      key: "photo",
      render: (image, record) => (
        <Button type="link" href={`/users/${record.id_user}`} target="_blank">
          <Image width={80} src={image} preview={false} />
        </Button>
      ),
      width: 80,
    },
    {
      title: "Фамилия и имя",
      dataIndex: "name",
      key: "name",
      render: (z, record) => {
        const disp = `${record.surname || ""} ${record.name || ""}`.trim();
        return (
          <Button type="link" href={`/users/${record.id_user}`} target="_blank">
            {disp || "-"}
          </Button>
        );
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (t) => t || "-",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (t) => t || "-",
    },
    {
      title: "Дата регистрации",
      dataIndex: "u_created_at",
      key: "u_created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Дата добавления в группу",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      key: "actions",
      fixed: "right",
      width: "50px",
      render: (text, record) => (
        <Popconfirm
          title="Пользователь будет удалён из группы. Продолжить?"
          onConfirm={() => deleteUserGroupItem(record.id)}
          okText="Удалить"
          cancelText="Отмена"
        >
          <DeleteOutlined title="Удалить" style={{ color: "#1890ff" }} />
        </Popconfirm>
      ),
    },
  ];

  const handleSearch = (submission) => {
    setSearchText(submission.search);
  };

  const openSelectModal = () => {
    setIsModalOpen(true);
  };

  const closeSelectModal = () => {
    setIsModalOpen(false);
  };

  const onSelectHandler = (users) => {
    Promise.all(users.map((id_user) => addUserGroupItem(id_user, groupId)))
      .then(() => {
        notification.success({
          description: `${users.length > 1 ? "Пользователи добавлены" : "Пользователь добавлен"} в группу`,
        });
      })
      .catch(() => {
        notification.error({
          description: `Не удалось добавить ${users.length > 1 ? "пользователей" : "пользователя"} в группу`,
        });
      });
    closeSelectModal();
  };

  return (
    <div>
      <Divider />
      <Button type="primary" onClick={openSelectModal}>
        + Добавить
      </Button>
      <SelectUserModal
        isMultiple={false}
        visible={isModalOpen}
        onCancel={closeSelectModal}
        onSelect={onSelectHandler}
        destroyOnClose={true}
      />
      <SearchUserForm initialValue={searchText} onSubmit={handleSearch} />
      <Table columns={columns} dataSource={data} rowKey="id" loading={!isLoaded} />
    </div>
  );
}

function UserGroupsEdit() {
  const { id, tab = "base" } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    fetchWithJsonRpc("admin.user_group.details.v1", { id }).then((result) => {
      setData(result);
      setIsLoaded(true);
    });
  }, [id]);

  if (!isLoaded) {
    return <h1>Loading...</h1>;
  }

  function submitForm(submission) {
    fetchWithJsonRpc("admin.user_group.update.v1", { ...submission, id })
      .then(showSuccessSaveMessage)
      .catch(showFailureSaveMessage);
  }

  const onDeleteHandler = () => {
    fetchWithJsonRpc("admin.user_group.delete.v1", { id })
      .then(() => {
        history.push(`/user_groups`);
      })
      .catch(showFailureMessage);
  };

  return (
    <>
      <UserGroupsEditHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/user_groups">Группы пользователей</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
        </Breadcrumb>
        <UserGroupsEditButtons>
          <Popconfirm title="Группа будет удалена. Продолжить?" onConfirm={onDeleteHandler} okText="Удалить" cancelText="Отмена">
            <Button type="danger" ghost={true} icon={<DeleteOutlined />}>
              Удалить группу
            </Button>
          </Popconfirm>
        </UserGroupsEditButtons>
      </UserGroupsEditHeader>

      <Divider />

      <MyTabs
        activeKey={tab}
        resolvePath={(key) => `/user_groups/${id}/${key}`}
        items={[
          { key: "base", title: "Основное", children: <UserGroupBaseForm data={data} submitForm={submitForm} /> },
          { key: "users", title: "Пользователи", children: <UserGroupUsersTable groupId={id} /> },
        ]}
      />
    </>
  );
}

function PracticBaseForm({ data, submitForm }) {
  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Заголовок" name="title">
        <Input />
      </Form.Item>

      <Form.Item label="Описание" name="description">
        <Input.TextArea />
      </Form.Item>

      <Form.Item name="is_popup" valuePropName="checked">
        <Checkbox>Является всплывающим заданием</Checkbox>
      </Form.Item>

      <Form.Item label="Название кнопки задания" name="button_text">
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

const LoginContainer = styled.div`
  width: 300px;
  margin: 120px auto 0;
`;

function Login() {
  const location = useLocation();
  const { redirectTo } = location.state || {};

  const submitForm = ({ login, pass }) => {
    callAdminRpc("admin_login_v1", { params: { login, pass } }).then((r) => {
      setJWT(r.token);
      history.push(redirectTo ? redirectTo : "/");
    });
  };

  return (
    <LoginContainer>
      <Title>Вход</Title>
      <Form
        initialValues={{
          login: "",
          pass: "",
        }}
        layout="vertical"
        onFinish={submitForm}
      >
        <Form.Item label="Логин" name="login">
          <Input />
        </Form.Item>

        <Form.Item label="Пароль" name="pass">
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </LoginContainer>
  );
}

function LogoutPage() {
  useEffect(() => {
    setJWT(null);
    history.replace("/");
  }, []);

  return null;
}

function CheckTokenValidity() {
  useEffect(() => {
    const userData = getUserData();
    if (userData && !userData.name) {
      history.push("/logout");
    }
  }, []);

  return null;
}

const Error403 = () => <h1>Доступ запрещён :-( </h1>;

function IDParamNormalizer({ children }) {
  const { path, params } = useRouteMatch();
  const [content, setContent] = useState(null);

  const hasUnderscore = _.get(params, "id").includes("_");
  const newPath = generatePath(path, { ...params, id: _.get(params, "id").replaceAll("_", "-") });

  useEffect(() => {
    if (hasUnderscore) {
      history.replace(newPath);
    } else {
      setContent(children);
    }
  }, [children, hasUnderscore, newPath]);

  return content;
}

function App() {
  const isAuthorized = useIsAuthorized();

  const { loading, data, error } = usePgrst("router_details_v1", {}, [isAuthorized]);

  if (loading) {
    return null;
  }

  if (error) {
    return <PageError />;
  }

  return (
    <Router history={history}>
      <CheckTokenValidity />
      <Switch>
        <Route path="/pages/:id/editor" children={<PageEditor />} />
        <Route path="*">
          <MyLayout>
            <PageErrorBoundary>
              <Switch>
                {_.get(data, "children", []).map(render)}

                <Route path="/403" exact children={<Error403 />} />
                <Route path="/login" children={<Login />} />
                <Route path="/offers/subscribes/create">
                  <OfferSubscribeCreate />
                </Route>
                <Route path="/offers/subscribes/:id/:tab?">
                  <IDParamNormalizer>
                    <OfferSubscribeEdit />
                  </IDParamNormalizer>
                </Route>
                <Route path="/offers/offer_packages/create">
                  <OfferPackageCreate />
                </Route>
                <Route path="/offers/offer_packages/:id/:tab?">
                  <IDParamNormalizer>
                    <OfferPackageEdit />
                  </IDParamNormalizer>
                </Route>
                <Route path="/offers/offer_increase/create">
                  <OfferIncreaseCreate />
                </Route>
                <Route path="/offers/offer_increase/:id/:tab?">
                  <IDParamNormalizer>
                    <OfferIncreaseEdit />
                  </IDParamNormalizer>
                </Route>
                <Route path="/offers/create">
                  <OfferCreate />
                </Route>
                <Route exact path="/offers/:tab(list|subscribes|offer_packages|offer_increase)?">
                  <Offers />
                </Route>
                <Route path="/offers/:id/:tab?">
                  <IDParamNormalizer>
                    <OfferEdit />
                  </IDParamNormalizer>
                </Route>
                <Route exact path="/trainings/:tab(list|training_packages|teachers)?">
                  <TrainingsTabs />
                </Route>
                <Route path="/trainings/training_packages/create">
                  <TrainingPackageCreate />
                </Route>
                <Route path="/trainings/training_packages/:id/:tab?">
                  <TrainingPackageEdit />
                </Route>
                <Route path="/trainings/create" children={<TrainingCreate />} />
                <Route path="/trainings/:id_training/streams/dynamic/create" children={<StreamCreate type={STREAM_TYPE_DYNAMIC} />} />
                <Route path="/trainings/:id_training/streams/create" exact children={<StreamCreate />} />
                <Route path="/trainings/:id_training/streams/dynamic/:id/edit" children={<StreamEdit type={STREAM_TYPE_DYNAMIC} />} />
                <Route path="/trainings/:id_training/streams/:id/edit" children={<StreamEdit />} />
                <Route path="/trainings/:id_training/streams/dynamic/:id/:tab?" children={<StreamCard type={STREAM_TYPE_DYNAMIC} />} />
                <Route path="/trainings/:id_training/streams/:id/:tab?" children={<StreamCard />} />
                <Route path="/trainings/:id/:tab?" children={<TrainingEdit />} />

                <Route path="/streams">
                  <StreamsTable />
                </Route>

                <Route path="/lessons/create/:id?">
                  <CardScreen dataFn="useN8n2" source="admin.screen.lesson_tab_base.v1" idKey="IDTrainingGroup" />
                </Route>
                <Route path="/lessons/:id/:tab?">
                  <LessonEdit />
                </Route>
                <Route path="/lessons">
                  <LessonsTable />
                </Route>

                <Route path="/videos">
                  <VideosTable />
                </Route>
                <Route path="/practics/create" children={<PracticsCreate />} />
                <Route path="/practics/:id/:tab?" children={<PracticsEdit />} />
                <Route path="/practics">
                  <PracticsTable />
                </Route>
                <Route path="/practic_answers/:id" children={<PracticAnswerCard />} />

                <Route path="/users/create" children={<UsersCreate />} />
                <Route path="/users/:id/:tab?" children={<UsersEdit />} />
                <Route path="/users">
                  <UsersTable />
                </Route>
                <Route path="/user_groups/create" children={<UserGroupsCreate />} />
                <Route path="/user_groups/:id/:tab?" children={<UserGroupsEdit />} />

                <Route path="/orders/:tab(list|sales)?" exact>
                  <OrdersPage />
                </Route>
                <Route path="/orders/create">
                  <OrderCreate />
                </Route>
                <Route path="/orders2/:id/:tab?">
                  <OrderEdit />
                </Route>
                <Route path="/orders/:id">
                  <CardScreen dataFn="useN8n2" source="admin.screen.order_details.v1" />
                </Route>
                <Route path="/purchases/create_by_offer_package">
                  <PurchasesCreateByOfferPackage />
                </Route>
                <Route path="/purchases/create">
                  <PurchasesCreate />
                </Route>
                <Route path="/purchases/:id/edit">
                  <PurchasesEdit />
                </Route>
                <Route path="/purchases/subscribes/create">
                  <PurchaseSubscribeCreate />
                  {/* TODO <SimpleScreen source="admin.screen.purchase_subscribe_create.v1" /> */}
                </Route>
                <Route exact path="/purchases/subscribes/:id">
                  <CardScreen dataFn="useN8n2" source="admin.screen.purchase_subscribe_card.v1" />
                </Route>
                <Route path="/purchases/subscribes/:id/edit">
                  <PurchaseSubscribeEdit />
                </Route>
                <Route exact path="/purchases/:tab(list|subscribes)?">
                  <Purchases />
                </Route>
                <Route path="/purchases/:id">
                  <PurchaseCard />
                </Route>
                <Route path="/pages/create/partial">
                  <PageCreate partial={true} />
                </Route>
                <Route path="/pages/create">
                  <PageCreate />
                </Route>
                <Route exact path="/pages/:tab(list|partial)?">
                  <Pages />
                </Route>
                <Route path="/pages/:id">
                  <PageEdit />
                </Route>
                <Route path="/forms/create">
                  <FormCreate />
                </Route>
                <Route exact path="/forms">
                  <Forms />
                </Route>
                <Route path="/forms/:id/:tab?">
                  <FormEdit />
                </Route>
                <Route path="/announcements/create/:id?">
                  <CardScreen dataFn="useN8n2" source="admin.screen.announcement_create.v1" />
                </Route>
                <Route exact path="/announcements">
                  <Announcements />
                </Route>
                <Route path="/announcements/:id">
                  <CardScreen dataFn="useN8n2" source="admin.screen.announcement_update.v1" />
                </Route>
                <Route path="/segments/create">
                  <SegmentCreate />
                </Route>
                <Route path="/segments/:id/:tab?">
                  <SegmentCard />
                </Route>
                <Route path="/logout">
                  <LogoutPage />
                </Route>

                <Route path="/tags/create">
                  <CardScreen dataFn="useN8n2" source="admin.screen.tag_details.v1" />
                </Route>
                <Route path="/tags/:id">
                  <CardScreen dataFn="useN8n2" source="admin.screen.tag_details.v1" />
                </Route>

                <Route path="/">
                  <Redirect to="/trainings" />
                </Route>
              </Switch>
            </PageErrorBoundary>
          </MyLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
