import { useEffect, useState } from "react";

import { fetchWithJsonRpc } from "../helpers/helpers";

let cachePromise;
export function usePurchaseIncreasePeriodDict(initialValue) {
  const [dict, setDict] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cachePromise) {
      setLoading(true);
      cachePromise
        .then((cache) => {
          setDict(cache);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    setLoading(true);
    cachePromise = fetchWithJsonRpc("admin.purchase_increase.period_dict.v1")
      .then((result) => {
        setDict(result);
        return result;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { dict, loading };
}
