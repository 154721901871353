import _ from "lodash";

export function InputColorSwitch({ values, value, defaultValue, style, onChange }) {
  let currentIndex = values.findIndex((item) => item.value === value);
  if (currentIndex === -1) {
    currentIndex = values.findIndex((item) => item.value === defaultValue);
  }

  function handleClick() {
    const nextIndex = currentIndex + 1 >= values.length ? 0 : currentIndex + 1;
    typeof onChange === "function" && onChange(_.get(values, `[${nextIndex}].value`));
  }

  return (
    <div
      style={{
        width: "14px",
        height: "14px",
        borderRadius: "50%",
        backgroundColor: _.get(values, `[${currentIndex}].color`),
        cursor: "pointer",
        ...style,
      }}
      onClick={handleClick}
      title={_.get(values, `[${currentIndex}].title`)}
    />
  );
}
