export const DateFormats = {
  default: "DD.MM.YYYY HH:mm",
  withSeconds: "DD.MM.YYYY HH:mm:ss",
  dateOnly: "DD.MM.YYYY",
};

export const cdnHost = process.env.REACT_APP_CDN_HOST || "https://gymteam.kinescopecdn.net";
export const localSiteUrl = "http://localhost:3000";
export const apiPath = process.env.REACT_APP_POSTGREST_PATH || "/api";
export const imgpushPath = "/imgpush";
export const n8nPath = "/n8n";
export const n8n2Path = process.env.REACT_APP_N8N2_PATH || "/n8n2";

export const defaultPreviewImage = `${cdnHost}/square-video-default.png`;

export const filebrowserFiles = process.env.REACT_APP_FILEBROWSER_FILES || "https://filebrowser.gymteam.ru/files/";
