export const addHeading3 = (editor, config) => {
  const bm = editor.BlockManager;

  bm.add("gt-heading3", {
    category: "Basic",
    label: "Heading 3",
    attributes: { class: "gjs-fonts gjs-f-h1p" },
    content: "<h3>Heading 3</h3>",
  });
};
