export const addOfferForm = (editor, opts) => {
  const script = function ({
    id,
    title,
    username_enabled,
    button_text,
    prodamus_enabled,
    prodamus_button_text,
    prodamus_color,
    tinkoff_credit_enabled,
    tinkoff_credit_button_text,
    tinkoff_credit_color,
    utm_source,
    utm_medium,
    utm_content,
  }) {
    const comp = this;

    comp.classList.add("gt-offer-form");

    if (!id) {
      comp.innerHTML = '<p>Требуется указать "id" в настройках компонента</p>';
      return;
    }

    const formContainer = document.createElement("div");
    const formContainerId = `payment_form_${new Date().getTime()}`;
    formContainer.id = formContainerId;
    comp.appendChild(formContainer);

    const { gymteam } = window;

    const utm = {};
    if (utm_source) {
      utm.utm_source = utm_source;
    }
    if (utm_medium) {
      utm.utm_medium = utm_medium;
    }
    if (utm_content) {
      utm.utm_content = utm_content;
    }

    gymteam.createOfferForm(formContainerId, id, {
      title,
      username: {
        enabled: username_enabled,
      },
      button_text,
      prodamus: {
        enabled: prodamus_enabled,
        button_text: prodamus_button_text,
        color: prodamus_color,
      },
      tinkoff_credit: {
        enabled: tinkoff_credit_enabled,
        button_text: tinkoff_credit_button_text,
        color: tinkoff_credit_color,
      },
      utm,
    });
  };

  editor.Components.addType("offerForm", {
    model: {
      defaults: {
        script,
        username_enabled: true,
        title: "Выберите вариант участия:",
        button_text: "картой российского банка",
        prodamus_enabled: true,
        prodamus_button_text: "картой иностранного банка",
        prodamus_color: "",
        tinkoff_credit_enabled: true,
        tinkoff_credit_button_text: "Взять в рассрочку",
        tinkoff_credit_color: "#fbe04a",
        traits: [
          {
            type: "text",
            name: "id",
            changeProp: true,
          },
          {
            type: "text",
            name: "title",
            changeProp: true,
          },
          {
            type: "checkbox",
            name: "username_enabled",
            label: "Фамилия Имя",
            changeProp: true,
          },
          {
            type: "text",
            name: "button_text",
            label: "Button",
            changeProp: true,
          },
          {
            type: "checkbox",
            name: "prodamus_enabled",
            label: "Prodamus",
            changeProp: true,
          },
          {
            type: "text",
            name: "prodamus_button_text",
            label: "Text",
            changeProp: true,
          },
          {
            type: "color",
            name: "prodamus_color",
            label: "Color",
            changeProp: true,
          },
          {
            type: "checkbox",
            name: "tinkoff_credit_enabled",
            label: "Tinkoff",
            changeProp: true,
          },
          {
            type: "text",
            name: "tinkoff_credit_button_text",
            label: "Text",
            changeProp: true,
          },
          {
            type: "color",
            name: "tinkoff_credit_color",
            label: "Color",
            changeProp: true,
          },
          {
            type: "text",
            name: "utm_source",
            changeProp: true,
          },
          {
            type: "text",
            name: "utm_medium",
            changeProp: true,
          },
          {
            type: "text",
            name: "utm_content",
            changeProp: true,
          },
        ],
        "script-props": [
          "id",
          "title",
          "username_enabled",
          "button_text",
          "prodamus_enabled",
          "prodamus_button_text",
          "prodamus_color",
          "tinkoff_credit_enabled",
          "tinkoff_credit_button_text",
          "tinkoff_credit_color",
          "utm_source",
          "utm_medium",
          "utm_content",
        ],
      },
    },
  });
};
