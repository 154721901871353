import React, { useEffect, useState } from "react";
import { ContentState, EditorState, Modifier, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export function HTMLEditor({ value, onChange, onBlur }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const contentBlock = htmlToDraft(value || "");
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      setEditorState(editorState);
    }
  }, []); // eslint-disable-line

  function setEditorReference(ref) {
    if (ref) {
      ref.focus();
    }
  }

  function handleChange(state) {
    onChange(draftToHtml(convertToRaw(state.getCurrentContent())));
  }

  return (
    <Editor
      editorRef={setEditorReference}
      editorState={editorState}
      editorClassName="gt-html-editor"
      onEditorStateChange={setEditorState}
      onChange={() => handleChange(editorState)}
      onBlur={onBlur}
      handlePastedText={(text, _html) => {
        try {
          const html = _html ? `<div>${_html.replace(/style="[^>]*"?/gm, "")}</div>` : null;

          if (!html && !text) {
            return false;
          }

          const contentBlock = htmlToDraft(html || text);
          const pastedBlocks = ContentState.createFromBlockArray(contentBlock.contentBlocks).blockMap;
          const newState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), pastedBlocks);
          const newEditorState = EditorState.push(editorState, newState, "insert-fragment");

          setEditorState(newEditorState);
          handleChange(newEditorState);

          return true;
        } catch (e) {
          console.error(e);
          return false;
        }
      }}
    />
  );
}
