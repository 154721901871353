import { Component } from "react";
import { Typography } from "antd";

import { PageError } from "./PageError";

const { Text } = Typography;

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Catched error:", error, errorInfo);
  }

  renderError() {
    return <Text type="danger">Ошибка :(</Text>;
  }

  render() {
    if (this.state.hasError) {
      return this.renderError();
    }

    return this.props.children;
  }
}

export class PageErrorBoundary extends ErrorBoundary {
  renderError() {
    return <PageError />;
  }
}
