export const addColumns2 = (editor, config) => {
  editor.Blocks.add("columns2", {
    category: "Layout",
    label: "2 Columns",
    attributes: { class: "gjs-fonts gjs-f-b2" },
    content: `
    <div data-gjs-type="div" bp="grid 6">
      <div data-gjs-type="div"></div>
      <div data-gjs-type="div"></div>
    </div>
    `,
  });
};
