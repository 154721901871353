export const addColumns5 = (editor, config) => {
  editor.Blocks.add("columns5", {
    category: "Layout",
    label: "5 Columns",
    attributes: { class: "gjs-fonts gjs-f-b3" },
    content: `
<div data-gjs-type="div" bp="flex">
  <div data-gjs-type="div" bp="fill"></div>
  <div data-gjs-type="div" bp="fill"></div>
  <div data-gjs-type="div" bp="fill"></div>
  <div data-gjs-type="div" bp="fill"></div>
  <div data-gjs-type="div" bp="fill"></div>
</div>`,
  });
};
