export const addColumns3 = (editor, config) => {
  editor.Blocks.add("columns3", {
    category: "Layout",
    label: "3 Columns",
    attributes: { class: "gjs-fonts gjs-f-b3" },
    content: `
    <div data-gjs-type="div" bp="grid 4">
      <div data-gjs-type="div"></div>
      <div data-gjs-type="div"></div>
      <div data-gjs-type="div"></div>
    </div>
    `,
  });
};
