import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import styled from "styled-components";
import {
  Button,
  Table,
  Form,
  Divider,
  Typography,
  Select,
  Breadcrumb,
  Checkbox,
  Modal,
  Popconfirm,
  Descriptions,
  Skeleton,
  Space,
  notification,
} from "antd";
import { EditOutlined, FolderOpenOutlined, RedoOutlined, PlusOutlined, DeleteTwoTone } from "@ant-design/icons";
import { red, blue } from "@ant-design/colors";

import { SelectUser } from "../components/SelectUser";
import {
  DateCellRenderer,
  UserCellRenderer,
  TrainingCellRenderer,
  ScenarioCellRenderer,
  StreamCellRenderer,
  PurchaseIncreasePeriodRenderer,
  TrainingPackageCellRenderer,
  OfferSubscribeRecurrenceCellRenderer,
  PurchaseActiveCellRenderer,
} from "../components/CellRenderer";
import { AsyncSelect2, AsyncSelect2WithLink, InputDate, defaultSearchFilter } from "../components/FormInput";
import { PageError } from "../components/PageError";
import { CardButtons, CardDescriptions, CardHeader } from "../components/Card";
import { MyTabs } from "../components/Tabs";

import { DateFormats } from "../constants";
import { showFailureMessage } from "../helpers/notification";
import { usePurchaseIncreasePeriodDict } from "../hooks/usePurchaseIncreasePeriodDict";
import { useQuery } from "../hooks/useQuery";
import { useN8n2 } from "../hooks/useN8n2";
import { useN8nDefer } from "../hooks/useN8nDefer";
import { useN8n } from "../hooks/useN8n";
import { useN8n2Defer } from "../hooks/useN8n2Defer";
import { history } from "../history";
import { render } from "../jhtml";

import { callAdminRpc } from "../helpers/helpers";

const { Title, Text } = Typography;
const { Option } = Select;

export function Purchases() {
  const { tab } = useParams();

  return (
    <MyTabs
      activeKey={tab}
      resolvePath={(key) => `/purchases/${key}`}
      items={[
        { key: "list", title: "Покупки", children: <PurchasesTable /> },
        { key: "subscribes", title: "Подписки", children: <PurchaseSubscribesTable /> },
      ]}
    />
  );
}

function PurchasesTable() {
  const query = useQuery();

  const queryPage = parseInt(query.get("page"));
  const currentPage = !isNaN(queryPage) ? queryPage : 1;

  const queryPageSize = parseInt(query.get("pageSize"));
  const pageSize = !isNaN(queryPageSize) ? queryPageSize : 50;

  const { loading, data = {}, error } = useN8n2(
    "admin.purchase.list.v1",
    {
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
    },
    [pageSize, currentPage]
  );

  const handleTableChange = (pagination) => {
    query.set("page", pagination.current);
    history.push(`/purchases?${query.toString()}`);
  };

  const columns = [
    {
      title: "Пользователь",
      dataIndex: "user",
      key: "user",
      render: (user) => <UserCellRenderer data={user} />,
    },
    {
      title: "Тренинг",
      dataIndex: "training",
      key: "training",
      render: (training) => <TrainingCellRenderer data={training} />,
    },
    {
      title: "Поток",
      dataIndex: "stream",
      key: "stream",
      render: (stream) => <StreamCellRenderer title={stream.title} link={stream.link} />,
    },
    {
      title: "Сценарий",
      dataIndex: "scenario",
      key: "scenario",
      render: (scenario, record) => <ScenarioCellRenderer trainingId={_.get(record, "training.id")} data={scenario} />,
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Дата изменения",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Статус",
      dataIndex: "active",
      key: "active",
      render: (value) => <PurchaseActiveCellRenderer value={value} />,
    },
    {
      key: "actions",
      fixed: "right",
      width: "50px",
      render: (text, record) => (
        <Link to={`/purchases/${record.id}`}>
          <FolderOpenOutlined title="Открыть карточку покупки" />
        </Link>
      ),
    },
  ];

  if (error) {
    return (
      <PageError
        onRefresh={() => {
          history.push(`/purchases`);
        }}
      />
    );
  }

  const onShowSizeChange = (current, size) => {
    query.set("pageSize", size);
    history.push(`/purchases?${query.toString()}`);
  };

  return (
    <div>
      <Space>
        <Button type="primary" onClick={() => history.push("/purchases/create")}>
          + Добавить
        </Button>
        <Button type="primary" onClick={() => history.push(`/purchases/create_by_offer_package`)}>
          + Добавить по оффер-пакету
        </Button>
      </Space>
      <Divider />
      <Table
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        onChange={handleTableChange}
        pagination={{
          current: currentPage,
          pageSize,
          total: data.total,
          onShowSizeChange,
        }}
        loading={loading}
      />
    </div>
  );
}

const PurchaseBaseFormButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 10px;
`;

function PurchaseBaseForm({ data, mode, submitForm, onCancel, hasIncreases }) {
  const [form] = Form.useForm();
  const [trainingId, setTrainingId] = useState(data.id_training);
  const [isManualDates, setIsManualDates] = useState(data.is_manually_dates);
  const isEditFormMode = mode === "edit";

  const onTrainingChange = (id) => {
    setTrainingId(id);
    form.setFieldsValue({
      id_stream: "",
      id_scenario: "",
    });
  };

  const onManualDatesCheckboxChange = (e) => {
    setIsManualDates(e.target.checked);
  };

  const onConfirmChange = () => {
    form.submit();
  };

  return (
    <Form form={form} initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Пользователь" name="id_user" rules={[{ required: true }]}>
        <SelectUser isMultiple={!isEditFormMode} maxItemsToDisplay={5} isDisabled={isEditFormMode} />
      </Form.Item>
      <Form.Item label="Тренинг" name="id_training" rules={[{ required: true }]}>
        <AsyncSelect2
          jrpcMethod="admin.training.list.v1"
          n8nVersion={2}
          title="title"
          onChange={onTrainingChange}
          showSearch={true}
          filterOption={defaultSearchFilter}
        />
      </Form.Item>
      {trainingId && (
        <>
          <Form.Item label="Поток" name="id_stream" rules={[{ required: true }]}>
            <AsyncSelect2
              jrpcMethod="admin.selector.any_stream.v1"
              jrpcParams={{ id_training: trainingId }}
              n8nVersion={2}
              title="label"
              idKey="value"
              style={{ width: "100%" }}
              showSearch={true}
              filterOption={defaultSearchFilter}
              deps={[trainingId]}
            />
          </Form.Item>
          <Form.Item label="Сценарий" name="id_scenario" rules={[{ required: true }]}>
            <AsyncSelect2
              jrpcMethod="admin.scenario.list.v1"
              jrpcParams={{ id_training: trainingId }}
              title="title"
              style={{ width: "100%" }}
              showSearch={true}
              filterOption={defaultSearchFilter}
              deps={[trainingId]}
            />
          </Form.Item>
        </>
      )}

      {isEditFormMode && (
        <Space direction="vertical" size={0} style={{ marginBottom: 24 }}>
          <Form.Item name="is_manually_dates" valuePropName="checked" style={{ marginBottom: 0 }}>
            <Checkbox onChange={onManualDatesCheckboxChange} disabled={hasIncreases}>
              Установить и зафиксировать независимые даты покупки
            </Checkbox>
          </Form.Item>
          <Text type="secondary">
            Можно установить даты, не зависимые от потока и подписки.
            <br />
            При пересчёте они не будут изменены.
            <br />
            Установка независимых дат недоступна, если есть продления покупки.
          </Text>
        </Space>
      )}

      {isManualDates && (
        <>
          <br />
          <Form.Item label="Пред-старт" name="pre_started_at">
            <InputDate disabled={hasIncreases} />
          </Form.Item>
          <Form.Item label="Старт" name="started_at">
            <InputDate disabled={hasIncreases} />
          </Form.Item>
          <Form.Item label="Финиш" name="ended_at">
            <InputDate disabled={hasIncreases} />
          </Form.Item>
        </>
      )}

      <Divider />

      <PurchaseBaseFormButtons>
        {isEditFormMode ? (
          <>
            <Button type="default" htmlType="button" onClick={onCancel}>
              Отмена
            </Button>
            <Popconfirm
              title="После сохранения запустится процесс пересчёта покупки. Продолжить?"
              onConfirm={onConfirmChange}
              okText="Продолжить"
              cancelText="Отмена"
            >
              <Button type="primary">Сохранить</Button>
            </Popconfirm>
          </>
        ) : (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        )}
      </PurchaseBaseFormButtons>
    </Form>
  );
}

function CreatePurchaseByOfferPackageForm({ data, submitForm }) {
  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Пользователь" name="id_user" rules={[{ required: true }]}>
        <SelectUser maxItemsToDisplay={5} />
      </Form.Item>

      <Form.Item
        label="Оффер-пакет"
        name="id_offer_package"
        style={{
          flex: "auto",
        }}
        rules={[{ required: true }]}
      >
        <AsyncSelect2WithLink
          jrpcMethod="admin.offer_package.list.v1"
          n8nVersion={2}
          title="title"
          autoFocus={false}
          showSearch={true}
          filterOption={defaultSearchFilter}
          path="/offers/offer_packages/"
          target="_blank"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function PurchasesCreateByOfferPackage() {
  const query = useQuery();

  const create = useN8n2Defer("admin.purchase.create_by_offer_package.v1");

  function submitForm(submission) {
    create(submission)
      .then((result) => {
        notification.success({
          description: result.total ? `Создано покупок и подписок (всего): ${result.total}` : "Покупки созданы",
        });

        history.push(`/users/${submission.id_user}/purchases`);
      })
      .catch((e) => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось создать покупки",
        });
      });
  }

  const formData = {};
  const id_user = query.get("id_user");
  if (id_user) {
    formData.id_user = id_user;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/purchases">Покупки</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Создание покупок по оффер-пакету</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <CreatePurchaseByOfferPackageForm data={formData} submitForm={submitForm} />
    </>
  );
}

export function PurchasesCreate() {
  const query = useQuery();

  const create = useN8n2Defer("admin.purchase.create_for_many_users.v1");

  function submitForm(submission) {
    create(submission)
      .then((result) => {
        notification.success({
          description: `Создано покупок: ${result.total}`,
        });
        history.push("/purchases");
      })
      .catch((e) => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось добавить покупку",
        });
      });
  }

  const formData = {};
  const id_user = query.get("id_user");
  if (id_user) {
    formData.id_user = [id_user];
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/purchases">Покупки</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Новая покупка</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <PurchaseBaseForm data={formData} submitForm={submitForm} />
    </>
  );
}

function PurchaseCardData({ data, error, isLoaded, onStatusClick }) {
  if (error) {
    return <PageError />;
  }

  if (!isLoaded) {
    return <Skeleton active />;
  }

  const isSamePreStartedAt = moment.utc(_.get(data, "pre_started_at")).isSame(moment.utc(_.get(data, "stream.pre_started_at")));
  const isSameStartedAt = moment.utc(_.get(data, "started_at")).isSame(moment.utc(_.get(data, "stream.started_at")));
  const isSameEndedAt = moment.utc(_.get(data, "ended_at")).isSame(moment.utc(_.get(data, "stream.ended_at")));

  return (
    <>
      <CardDescriptions title="Карточка покупки" layout="horizontal" bordered column={2} size="small">
        <Descriptions.Item label="Пользователь">
          <UserCellRenderer data={_.get(data, "user")} />
        </Descriptions.Item>
        <Descriptions.Item label="Дата создания">
          <DateCellRenderer value={_.get(data, "created_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>

        <Descriptions.Item label="Тренинг">
          <TrainingCellRenderer data={_.get(data, "training")} />
        </Descriptions.Item>
        <Descriptions.Item label="Дата изменения">
          <DateCellRenderer value={_.get(data, "updated_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>

        <Descriptions.Item label="Сценарий">
          <ScenarioCellRenderer trainingId={_.get(data, "training.id")} data={_.get(data, "scenario")} />
        </Descriptions.Item>
        <Descriptions.Item label="Пред-старт" contentStyle={{ color: isSamePreStartedAt ? blue.primary : red.primary }}>
          <DateCellRenderer value={_.get(data, "pre_started_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>

        <Descriptions.Item label="Поток">
          <StreamCellRenderer title={_.get(data, "stream.title")} link={_.get(data, "stream.link")} />
        </Descriptions.Item>
        <Descriptions.Item label="Старт" contentStyle={{ color: isSameStartedAt ? blue.primary : red.primary }}>
          <DateCellRenderer value={_.get(data, "started_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>

        <Descriptions.Item label="Статус">
          <Space size={0}>
            <PurchaseActiveCellRenderer value={_.get(data, "active")} />
            <Button type="link" onClick={onStatusClick}>
              {_.get(data, "active") ? "Деактивировать" : "Активировать"}
            </Button>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Финиш" contentStyle={{ color: isSameEndedAt ? blue.primary : red.primary }}>
          <DateCellRenderer value={_.get(data, "ended_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>

        {_.get(data, "active") ? (
          <Descriptions.Item label=""></Descriptions.Item>
        ) : (
          <Descriptions.Item label="Дата деактивации">
            <DateCellRenderer value={_.get(data, "deactivated_at")} format={DateFormats.withSeconds} />
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Даты установлены вручную">{_.get(data, "is_manually_dates") ? "Да" : "Нет"}</Descriptions.Item>
      </CardDescriptions>

      <CardDescriptions title="Данные потока" layout="horizontal" bordered column={1} size="small">
        <Descriptions.Item label="Пред-старт">
          <DateCellRenderer value={_.get(data, "stream.pre_started_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>
        <Descriptions.Item label="Старт">
          <DateCellRenderer value={_.get(data, "stream.started_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>
        <Descriptions.Item label="Финиш">
          <DateCellRenderer value={_.get(data, "stream.ended_at")} format={DateFormats.withSeconds} />
        </Descriptions.Item>
      </CardDescriptions>
    </>
  );
}

function CreatePurchaseIncreaseModal({ id, visible, onCancel, onSuccess }) {
  const [form] = Form.useForm();
  const { dict, loading } = usePurchaseIncreasePeriodDict([]);

  const submitForm = () => {
    form.submit();
  };

  const submitHandler = function (submission) {
    try {
      callAdminRpc("purchase_increase_create_v1", {
        id_purchase: id,
        period: submission.period,
      });

      notification.success({
        description: `Покупка продлена`,
      });

      typeof onSuccess === "function" && onSuccess();
    } catch (e) {
      notification.error({
        description: `Не удалось продлить покупку`,
      });
    }
  };

  const defaultPeriod = Array.isArray(dict) && dict.length && dict[0].id;

  return (
    <Modal
      title="Продление покупки"
      visible={visible}
      width={400}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button key="submit" onClick={submitForm} type="primary">
          Продлить
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={{ period: defaultPeriod }} onFinish={submitHandler}>
        <Form.Item label="Период" name="period">
          <Select loading={loading}>
            {dict.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.desc}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function PurchaseIncreases({ id, onChange }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loading, data = [], error, refetch } = useN8n("admin.purchase_increase.list.v1", { id }, [id]);
  const remove = useN8n2Defer("admin.purchase_increase.delete.v1");

  const handleDelete = (id) => {
    remove({ id })
      .then(() => {
        refetch();
        typeof onChange === "function" && onChange();
      })
      .catch(showFailureMessage);
  };

  const openCreateIncreaseModal = () => {
    setIsModalOpen(true);
  };

  const closeCreateIncreaseModal = () => {
    setIsModalOpen(false);
  };

  const onPurchaseIncreaseCreate = () => {
    setIsModalOpen(false);
    refetch();
    typeof onChange === "function" && onChange();
  };

  const allPeriods = [];
  const dataSource = data.map((dataItem) => {
    try {
      const dWithoutInc = moment.utc(dataItem.ended_at_without_increase);
      allPeriods.forEach((period) => {
        dWithoutInc.add(period[0], period[1]);
      });

      const dWithInc = moment.utc(dataItem.ended_at_without_increase);
      const periodParts = dataItem.period.split(" ");
      allPeriods.push(periodParts);
      allPeriods.forEach((period) => {
        dWithInc.add(period[0], period[1]);
      });

      return {
        ...dataItem,
        ended_at_without_increase: dWithoutInc.toISOString(),
        ended_at_with_increase: dWithInc.toISOString(),
      };
    } catch (e) {
      console.error(e);
      return {
        ...dataItem,
        ended_at_with_increase: "-",
      };
    }
  });

  const columns = [
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Дата окончания без продления",
      dataIndex: "ended_at_without_increase",
      key: "ended_at_without_increase",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Период продления",
      dataIndex: "period",
      key: "period",
      render: (value) => <PurchaseIncreasePeriodRenderer value={value} />,
    },
    {
      title: "Дата окончания с продлением",
      dataIndex: "ended_at_with_increase",
      key: "ended_at_with_increase",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      width: 50,
      dataIndex: "actions",
      render: (text, record) => (
        <Popconfirm title="Удалить?" onConfirm={() => handleDelete(record.id)}>
          <DeleteTwoTone style={{ fontSize: 20 }} />
        </Popconfirm>
      ),
    },
  ];

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Space align="baseline">
        <Title level={5}>Продления</Title>
        <Button size="small" type="primary" ghost={true} icon={<PlusOutlined />} onClick={openCreateIncreaseModal}>
          Добавить
        </Button>
        <CreatePurchaseIncreaseModal
          id={id}
          visible={isModalOpen}
          onCancel={closeCreateIncreaseModal}
          onSuccess={onPurchaseIncreaseCreate}
        />
      </Space>
      <Table columns={columns} dataSource={dataSource} rowKey="id" loading={loading} />
    </>
  );
}

export function PurchaseCard() {
  const { id } = useParams();

  const { loading, data = {}, error, refetch } = useN8n2("admin.purchase.details.v1", { id }, [id]);
  const recalc = useN8n2Defer("admin.purchase.recalc.v1");
  const toggleActive = useN8n2Defer("admin.purchase.toggle_active.v1");

  const onRecalc = () => {
    recalc({ id }, { isNotification: true })
      .then(() => {
        notification.success({
          description: "Процесс пересчёта покупки успешно запущен",
        });
      })
      .catch((e) => {
        notification.error({
          description: "Не удалось запустить процесс пересчёта покупки",
        });
      });
  };

  const onToggleActive = () => {
    toggleActive({ id }, { isNotification: true })
      .then(refetch)
      .catch((e) => {
        notification.error({
          description: "Не удалось изменить статус покупки",
        });
      });
  };

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/purchases">Покупки</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{id}</Breadcrumb.Item>
        </Breadcrumb>
        <CardButtons>
          <Link to={`/purchases/${id}/edit`} disabled={loading}>
            <Button type="primary" ghost={true} icon={<EditOutlined />} disabled={loading}>
              Редактировать покупку
            </Button>
          </Link>
          <Popconfirm
            disabled={loading}
            title="Запустится процесс пересчёта покупки. Продолжить?"
            onConfirm={onRecalc}
            okText="Пересчитать"
            cancelText="Отмена"
          >
            <Button type="danger" ghost={true} icon={<RedoOutlined />} disabled={loading}>
              Пересчитать покупку
            </Button>
          </Popconfirm>
        </CardButtons>
      </CardHeader>

      <Divider />

      <PurchaseCardData isLoaded={!loading} data={data} error={error} onStatusClick={onToggleActive} />
      <PurchaseIncreases id={id} onChange={refetch} />
    </>
  );
}

export function PurchasesEdit() {
  const { id } = useParams();

  const { loading, data = {}, error } = useN8n2("admin.purchase.details.v1", { id }, [id]);
  const update = useN8n2Defer("admin.purchase.update.v1");

  function goToPurchase(id) {
    history.push(`/purchases/${id}`);
  }

  function submitForm(submission) {
    update({ id, ...submission })
      .then(() => {
        notification.success({
          description: "Данные успешно сохранены",
        });
        goToPurchase(id);
      })
      .catch(() => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось сохранить данные",
        });
      });
  }

  if (loading) {
    return <p>Loading ...</p>;
  }

  if (error) {
    return <PageError />;
  }

  return (
    <PurchaseBaseForm
      data={{
        id_user: _.get(data, "user.id"),
        id_training: _.get(data, "training.id"),
        id_stream: _.get(data, "stream.id"),
        id_scenario: _.get(data, "scenario.id"),
        pre_started_at: _.get(data, "pre_started_at"),
        started_at: _.get(data, "started_at"),
        ended_at: _.get(data, "ended_at"),
        is_manually_dates: _.get(data, "is_manually_dates"),
      }}
      mode="edit"
      submitForm={submitForm}
      onCancel={() => {
        goToPurchase(id);
      }}
      hasIncreases={_.get(data, "increase_count") > 0}
    />
  );
}

function PurchaseSubscribesTable() {
  const query = useQuery();

  const queryPage = parseInt(query.get("page"));
  const currentPage = !isNaN(queryPage) ? queryPage : 1;

  const queryPageSize = parseInt(query.get("pageSize"));
  const pageSize = !isNaN(queryPageSize) ? queryPageSize : 50;

  const { loading, data = {}, error } = useN8n2(
    "admin.purchase_subscribe.list.v1",
    {
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
    },
    [pageSize, currentPage]
  );

  const handleTableChange = (pagination) => {
    query.set("page", pagination.current);
    history.push(`/purchases/subscribes?${query.toString()}`);
  };

  const columns = [
    {
      title: "Пользователь",
      dataIndex: "user",
      key: "user",
      render: (user) => <UserCellRenderer data={user} />,
    },
    {
      title: "Пакет",
      dataIndex: "training_package",
      key: "training_package",
      render: (tp) => <TrainingPackageCellRenderer data={tp} />,
    },
    {
      title: "Платформа и период",
      dataIndex: "platform",
      key: "platform",
      render: (platform, record) => {
        return (
          <Text style={{ whiteSpace: "nowrap" }}>
            {platform} / {_.get(record, "period.desc")}
          </Text>
        );
      },
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      render: (price) => price || "-",
    },
    {
      title: "Автопродление",
      dataIndex: "recurrence",
      key: "recurrence",
      render: (recurrence) => <OfferSubscribeRecurrenceCellRenderer value={recurrence} />,
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Действует до",
      dataIndex: "ended_at",
      key: "ended_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Статус",
      dataIndex: "active",
      key: "active",
      render: (value) => <PurchaseActiveCellRenderer value={value} />,
    },
    {
      key: "actions",
      fixed: "right",
      width: "50px",
      render: (text, record) => (
        <Link to={`/purchases/subscribes/${record.id}`}>
          <FolderOpenOutlined title="Открыть карточку покупки" />
        </Link>
      ),
    },
  ];

  if (error) {
    return (
      <PageError
        onRefresh={() => {
          history.push(`/purchases/subscribes`);
        }}
      />
    );
  }

  const onShowSizeChange = (current, size) => {
    query.set("pageSize", size);
    history.push(`/purchases/subscribes?${query.toString()}`);
  };

  return (
    <div>
      <Space>
        <Button type="primary" onClick={() => history.push("/purchases/subscribes/create")}>
          + Добавить
        </Button>
        <Button type="primary" onClick={() => history.push(`/purchases/create_by_offer_package`)}>
          + Добавить по оффер-пакету
        </Button>
      </Space>
      <Divider />
      <Table
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        onChange={handleTableChange}
        pagination={{
          current: currentPage,
          pageSize,
          total: data.total,
          onShowSizeChange,
        }}
        loading={loading}
      />
    </div>
  );
}

function PurchaseSubscribeBaseForm({ data, submitForm, onCancel }) {
  const isEditFormMode = !!data.id_user;

  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Пользователь" name="id_user" rules={[{ required: true }]}>
        <SelectUser isMultiple={!isEditFormMode} maxItemsToDisplay={5} isDisabled={isEditFormMode} />
      </Form.Item>

      {!isEditFormMode && (
        <Form.Item label="Оффер" name="id_offer_subscribe" rules={[{ required: true }]}>
          <AsyncSelect2
            jrpcMethod="admin.offer_subscribe.list_for_purchase.v1"
            n8nVersion={2}
            title="title"
            showSearch={true}
            filterOption={defaultSearchFilter}
          />
        </Form.Item>
      )}

      <Form.Item name="recurrence" valuePropName="checked">
        <Checkbox>Автопродление</Checkbox>
      </Form.Item>

      <Divider />

      <Space>
        <Form.Item>
          <Button type="default" htmlType="button" onClick={onCancel}>
            Отмена
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
}

export function PurchaseSubscribeCreate() {
  const jhtml = {
    type: "fragment",
    children: [
      {
        type: "antd_breadcrumb",
        children: [
          {
            type: "antd_breadcrumb_item",
            children: [
              {
                type: "router_link",
                props: {
                  to: "/purchases/subscribes",
                },
                children: [
                  {
                    type: "text_node",
                    props: {
                      value: "Подписки",
                    },
                  },
                ],
              },
            ],
          },
          {
            type: "antd_breadcrumb_item",
            children: [
              {
                type: "text_node",
                props: {
                  value: "Новая подписка",
                },
              },
            ],
          },
        ],
      },
      {
        type: "antd_divider",
      },
      {
        type: "antd_form",
        props: {
          initialValues: {
            recurrence: true,
          },
          layout: "vertical",
          onFinish: {
            fn: `
              callN8n2('admin.purchase_subscribe.create_for_many_users.v2', e)
                .then((result) => {
                  notification.success({
                    description: 'Создано подписок: ' + result.total,
                  });
                  history.push("/purchases/subscribes");
                })
                .catch((e) => {
                  notification.error({
                    message: "Ошибка",
                    description: "Не удалось добавить подписку",
                  });
                });
            `,
          },
        },
        children: [
          {
            type: "antd_form_item",
            props: {
              label: "Пользователь",
              name: "id_user",
              rules: [{ required: true }],
            },
            children: [
              {
                type: "select_user",
                props: {
                  isMultiple: true,
                  maxItemsToDisplay: 5,
                },
              },
            ],
          },
          {
            type: "antd_form_item",
            props: {
              label: "Оффер",
              name: "id_offer_subscribe",
              rules: [{ required: true }],
            },
            children: [
              {
                // FIXME: на бэке следует использовать antd_select вместо async_select
                type: "async_select",
                props: {
                  jrpcMethod: "admin.offer_subscribe.list_for_purchase.v1",
                  n8nVersion: 2,
                  title: "title",
                  showSearch: true,
                  filterOption: {
                    type: "default",
                  },
                },
              },
            ],
          },
          {
            type: "antd_form_item",
            props: {
              name: "recurrence",
              valuePropName: "checked",
            },
            children: [
              {
                type: "antd_checkbox",
                children: [
                  {
                    type: "text_node",
                    props: {
                      value: "Автопродление",
                    },
                  },
                ],
              },
            ],
          },
          {
            type: "antd_divider",
          },
          {
            type: "antd_space",
            children: [
              {
                type: "antd_form_item",
                children: [
                  {
                    type: "antd_button",
                    props: {
                      type: "default",
                      htmlType: "button",
                      onClick: {
                        fn: "return history.push('/purchases/subscribes')",
                      },
                    },
                    children: [
                      {
                        type: "text_node",
                        props: {
                          value: "Отмена",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                type: "antd_form_item",
                children: [
                  {
                    type: "antd_button",
                    props: {
                      type: "primary",
                      htmlType: "submit",
                    },
                    children: [
                      {
                        type: "text_node",
                        props: {
                          value: "Сохранить",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  return render(jhtml);
}

export function PurchaseSubscribeEdit() {
  const { id } = useParams();

  const { loading, data = {}, error } = useN8n2("admin.purchase_subscribe.details.v1", { id }, [id]);
  const update = useN8nDefer("admin.purchase_subscribe.update.v1");

  function goToPurchase(id) {
    history.push(`/purchases/subscribes/${id}`);
  }

  function submitForm(submission) {
    update({ id, ...submission })
      .then(() => {
        notification.success({
          description: "Данные успешно сохранены",
        });
        goToPurchase(id);
      })
      .catch(() => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось сохранить данные",
        });
      });
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/purchases/subscribes">Подписки</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.id}</Breadcrumb.Item>
        </Breadcrumb>
      </CardHeader>

      <Divider />

      {loading ? (
        <Skeleton active />
      ) : (
        <PurchaseSubscribeBaseForm
          data={{
            ...data,
            id_training_package: _.get(data, "training_package.id"),
            id_user: _.get(data, "user.id"),
            period: _.get(data, "period.id"),
          }}
          submitForm={submitForm}
          onCancel={() => {
            goToPurchase(id);
          }}
        />
      )}
    </>
  );
}
