export const addButton = (editor, opts) => {
  const script = function ({ text }) {
    const comp = this;
    comp.innerHTML = text;
  };

  editor.Components.addType("button", {
    model: {
      defaults: {
        script,
        tagName: "a",
        text: "Текст кнопки",
        traits: [
          "href",
          {
            type: "string",
            name: "text",
            changeProp: true,
          },
        ],
        classes: ["gt-button"],
        "script-props": ["text"],
        style: {
          cursor: "pointer",
          color: "#FFFFFF",
          background: "#EE4777",
          padding: "0px 24px",
          "border-radius": "6px",
          "line-height": "48px",
          display: "inline-block",
          "font-size": "18px",
          "font-weight": "400",
          "text-decoration": "none",
          transition: "opacity 0.25s ease",
        },
      },
    },
  });
};
