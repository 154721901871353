import styled from "styled-components";
import { Button, Input, Typography } from "antd";

import { Image } from "../components/Image";

import { filebrowserFiles } from "../constants";

const { Link: AntdLink } = Typography;

const InputImageContainer = styled.div`
  display: flex;
`;

const InputImageLeftSide = styled.div`
  width: 150px;
  flex-shrink: 0;
`;

const InputImageRightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  width: 100%;
`;

const InputImagePlaceholder = styled.div`
  width: 150px;
  height: 150px;
  background-color: #cccccc;
`;

const InputImageFileBrowserLink = styled.a`
  margin-top: 10px;
`;

const InputImageSaveButton = styled(Button)`
  margin-top: 10px;
`;

export function InputImage(props) {
  const { onSave, placeholderImage, ...inputProps } = props;
  const { value } = inputProps;

  const imgSrc = value || placeholderImage;

  return (
    <InputImageContainer>
      <InputImageLeftSide>
        {imgSrc ? (
          <AntdLink href={imgSrc} target="_blank">
            <Image src={imgSrc} width={150} preview={false} />
          </AntdLink>
        ) : (
          <InputImagePlaceholder />
        )}
      </InputImageLeftSide>
      <InputImageRightSide>
        <Input {...inputProps} />
        <InputImageFileBrowserLink href={filebrowserFiles} target="_blank">
          Выбрать...
        </InputImageFileBrowserLink>
        {onSave && <InputImageSaveButton onClick={onSave}>Сохранить</InputImageSaveButton>}
      </InputImageRightSide>
    </InputImageContainer>
  );
}
