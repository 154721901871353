import { Popconfirm, Space } from "antd";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { DeleteOutlined, MenuOutlined, CopyOutlined } from "@ant-design/icons";
import _ from "lodash";

import { EditableCell, EditableRow, EditableTable2 } from "./EditableTable2";
import "./EditableTable.css";

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999999", fontSize: 16 }} />);
const SortableItem = SortableElement((props) => <EditableRow {...props} />);
const SortableContainer2 = SortableContainer((props) => <tbody {...props} />);

export class EditableTable extends EditableTable2 {
  handleCopy = (row) => {
    const { count, dataSource } = this.state;
    const { handleCopy } = this.props;

    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    return new Promise((resolve) => {
      handleCopy({ ...item }, (n) => {
        const newElement = { ...item, ...n, key: _.uniqueId("ds_") };

        newData.splice(index + 1, 0, newElement);

        this.setState({
          dataSource: newData,
          count: count + 1,
        });

        resolve(newElement);
      });
    });
  };

  handleMove = ({ oldIndex, newIndex }) => {
    const { handleMove } = this.props;
    const { dataSource } = this.state;

    if (oldIndex === newIndex) {
      return;
    }

    const ds = [...dataSource];
    const foo = ds.splice(oldIndex, 1);

    ds.splice(newIndex, 0, ...foo);

    this.setState({
      dataSource: ds,
    });

    return new Promise((resolve) => {
      handleMove(oldIndex, newIndex, () => {
        resolve();
      });
    });
  };

  DraggableContainer = (props) => (
    <SortableContainer2 useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={this.handleMove} {...props} />
  );

  DraggableBodyRow = (props) => {
    const { dataSource } = this.state;
    const { rowKey } = this.props;

    const index = dataSource.findIndex((x) => x[rowKey || "key"] === props["data-row-key"]);

    return <SortableItem index={index} {...props} />;
  };

  makeExtraColumns() {
    const { dataSource } = this.state;
    const { handleCopy, handleMove } = this.props;

    return [
      {
        title: "",
        dataIndex: "actions",
        className: "row-actions",
        render: (text, record) =>
          dataSource.length >= 1 ? (
            <Space className="row-hidden-actions" style={{ float: "right" }}>
              {record.id && (
                <>
                  {handleCopy && <CopyOutlined style={{ color: "#999999", fontSize: 16 }} onClick={() => this.handleCopy(record)} />}
                  {handleMove && <DragHandle />}
                </>
              )}

              <Popconfirm title="Удалить?" onConfirm={() => this.handleDelete(record.key)}>
                <DeleteOutlined style={{ color: "#999999", fontSize: 18 }} />
              </Popconfirm>
            </Space>
          ) : null,
      },
    ];
  }

  makeComponents() {
    return {
      body: {
        wrapper: this.DraggableContainer,
        row: this.DraggableBodyRow,
        cell: EditableCell,
      },
    };
  }
}
