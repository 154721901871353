import { createBrowserHistory } from "history";
import { callAdminRpc } from "./helpers/helpers";

const history = createBrowserHistory();

if (!window.location.pathname.startsWith("/login")) {
  callAdminRpc("admin_check_v1");

  history.listen(({ action, location }) => {
    if (!window.location.pathname.startsWith("/login")) {
      callAdminRpc("admin_check_v1");
    }
  });
}

export { history };
