export const styles = (editor, config) => {
  setTimeout(() => {
    const sm = editor.StyleManager;
    const sectorExtra = sm.getSector("extra");

    sectorExtra.addProperty("cursor");

    // console.log("builtInAll", sm.getBuiltInAll()); // Список всех встроенных property

    // Добавление нового сектора
    // sm.addSector("gt", {
    //   name: "GT",
    //   open: false,
    //   properties: ["cursor"],
    // });

    // Добавление нового property вручную
    // sm.addProperty("extra", {
    //   property: "cursor",
    //   type: "select",
    //   label: "Cursor type",
    //   default: "default",
    //   options: [
    //     { id: "default", label: "Default" },
    //     { id: "pointer", label: "Pointer" },
    //     { id: "none", label: "None" },
    //   ],
    // });
  }, 1);
};
