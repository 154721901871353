import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Tag, Space, Typography } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";

import { HTML2Unicode } from "../components/HTML2Unicode";
import { DateFormats } from "../constants";
import { Image } from "./Image";
import { usePracticAnswerStatusDict } from "../hooks/usePracticAnswerStatusDict";
import { usePracticItemDict } from "../hooks/usePracticItemDict";
import { useLessonItemDisplayDict } from "../hooks/useLessonItemDisplayDict";
import { usePurchaseIncreasePeriodDict } from "../hooks/usePurchaseIncreasePeriodDict";
import { russianPlural } from "../helpers/russianPlural";

const { Text } = Typography;

const ImageCellRendererContainer = styled.div`
  display: flex;
`;

const ImageCellRendererLeftSide = styled.div`
  flex-shrink: 0;
`;

const ImageCellRendererRightSide = styled.div`
  flex-shrink: 1;
  margin-left: 10px;
  word-break: break-word;
  display: flex;
  align-items: baseline;
`;

const ImageCellRendererLink = styled(Button)`
  padding: 0;
  display: inline-block;
`;

export function ImageCellRenderer({ value }) {
  if (!value) {
    return "-";
  }

  return (
    <ImageCellRendererContainer>
      <ImageCellRendererLeftSide>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={value} target="_blank" onClick={(e) => e.stopPropagation()}>
          <Image src={value} width={150} preview={false} />
        </a>
      </ImageCellRendererLeftSide>
      <ImageCellRendererRightSide>
        <ImageCellRendererLink type="link" target="_blank" href={value} onClick={(e) => e.stopPropagation()} icon={<LinkOutlined />} />
        {value}
      </ImageCellRendererRightSide>
    </ImageCellRendererContainer>
  );
}

const VideoCellRendererLink = styled(Button)`
  padding: 0;
`;

export function VideoCellRenderer({ value }) {
  if (!value) {
    return "-";
  }

  return (
    <>
      <VideoCellRendererLink type="link" target="_blank" href={value} onClick={(e) => e.stopPropagation()} icon={<LinkOutlined />} />
      {value}
    </>
  );
}

export function DateCellRenderer({ value, format = DateFormats.default, suffix }) {
  if (!value) {
    return "-";
  }

  const date = moment.utc(value);
  if (!date.isValid()) {
    return "-";
  }

  let result = date.local().format(format);

  if (suffix) {
    result = `${result}${suffix}`;
  }

  return result;
}

export function UserCellRenderer({ data }) {
  let disp = `${_.get(data, "surname") || ""} ${_.get(data, "name") || ""}`.trim();
  if (!disp) {
    disp = _.get(data, "phone") || _.get(data, "id");
  }

  return <Link to={`/users/${_.get(data, "id")}`}>{disp}</Link>;
}

export function TrainingCellRenderer({ data, tab = "base" }) {
  const id = _.get(data, "id");
  const title = _.get(data, "title") || "Без названия";
  if (!id) {
    return "-";
  }

  return (
    <Link to={`/trainings/${id}/${tab}`}>
      <HTML2Unicode>{title}</HTML2Unicode>
    </Link>
  );
}

export function StreamCellRenderer({ title, link }) {
  return <Link to={link}>{title}</Link>;
}

export function ScenarioCellRenderer({ trainingId, data }) {
  return <Link to={`/trainings/${trainingId}/scenarios`}>{_.get(data, "title")}</Link>;
}

export function TrainingPackageCellRenderer({ data }) {
  return <Link to={`/trainings/training_packages/${_.get(data, "id")}`}>{_.get(data, "title")}</Link>;
}

export function PracticItemRenderer({ value }) {
  const { dict, loading } = usePracticItemDict();

  if (loading) {
    return "Loading...";
  }

  if (!Array.isArray(dict)) {
    return value;
  }

  const practicItem = dict.find((item) => item.id === value);
  if (!practicItem) {
    return value;
  }

  return practicItem.desc;
}

export function PracticAnswerStatusRenderer({ value }) {
  const { dict, loading } = usePracticAnswerStatusDict();

  if (!value) {
    return "-";
  }

  if (loading) {
    return "Loading...";
  }

  if (!Array.isArray(dict)) {
    return value;
  }

  const statusItem = dict.find((item) => item.id === value);
  if (!statusItem) {
    return value;
  }

  let color;
  switch (value) {
    case "pending":
      color = "red";
      break;
    case "declined":
      color = "gold";
      break;
    case "accepted":
      color = "green";
      break;
    default:
  }

  return <Tag color={color}>{statusItem.desc}</Tag>;
}

export function LessonItemDisplayRenderer({ value }) {
  const { dict, loading } = useLessonItemDisplayDict();

  if (!value) {
    return "-";
  }

  if (loading) {
    return "Loading...";
  }

  if (!Array.isArray(dict)) {
    return value;
  }

  const dictItem = dict.find((item) => item.id === value);
  if (!dictItem) {
    return value;
  }

  return dictItem.desc;
}

export function PracticCellRenderer({ data }) {
  return <Link to={`/practics/${_.get(data, "id")}`}>{_.get(data, "title")}</Link>;
}

export function LessonCellRenderer({ data }) {
  const id = _.get(data, "id");
  const title = _.get(data, "title") || "Без названия";
  if (!id) {
    return "-";
  }

  return <Link to={`/lessons/${id}`}>{title}</Link>;
}

export function PurchaseIncreasePeriodRenderer({ value }) {
  const { dict, loading } = usePurchaseIncreasePeriodDict();

  if (!value) {
    return "-";
  }

  if (loading) {
    return "Loading...";
  }

  if (!Array.isArray(dict)) {
    return value;
  }

  const dictItem = dict.find((item) => item.id === value);
  if (!dictItem) {
    return value;
  }

  return dictItem.desc;
}

export function OfferListCellRenderer({ value }) {
  if (!Array.isArray(value)) {
    return "-";
  }

  return value.map((offer) => {
    if (!offer) {
      return null;
    }

    let pathPrefix = "/offers";
    if (offer.type === "offer_subscribe") {
      pathPrefix = "/offers/subscribes";
    } else if (offer.type === "offer_package") {
      pathPrefix = "/offers/offer_packages";
    } else if (offer.type === "offer_increase") {
      pathPrefix = "/offers/offer_increase";
    }

    return (
      <Tag key={offer.id}>
        <Link to={`${pathPrefix}/${offer.id}`}>{offer.title}</Link>
      </Tag>
    );
  });
}

export function OfferSubscribeRecurrenceCellRenderer({ value }) {
  const color = value ? "green" : "red";
  const text = value ? "Включено" : "Выключено";

  return <Tag color={color}>{text}</Tag>;
}

export function PurchaseActiveCellRenderer({ value, ...props }) {
  const color = value ? "green" : "red";
  const text = value ? "Активна" : "Не активна";

  return (
    <Tag color={color} {...props}>
      {text}
    </Tag>
  );
}

export function OfferPriceCellRenderer({ price, marketing_price }) {
  return (
    <Space>
      {marketing_price > 0 && <Text delete>{marketing_price}</Text>}
      <Text>{price || 0}</Text>
    </Space>
  );
}

export function AnnouncementActiveCellRenderer({ value, ...props }) {
  const color = value ? "green" : "red";
  const text = value ? "Активен" : "Не активен";

  return (
    <Tag color={color} {...props}>
      {text}
    </Tag>
  );
}

export function DynamicStreamRuleRenderer({ value, rules, n }) {
  const item = rules.find((i) => i.value === value);
  if (!item) {
    return "-";
  }

  const label = item.label;
  const v = item.value;

  if (!v.startsWith("n_")) {
    return label;
  }

  if (v.endsWith("days")) {
    return `Через ${russianPlural(n, ["день", "дня", "дней"])}`;
  }

  if (v.endsWith("months")) {
    return `Через ${russianPlural(n, ["месяц", "месяца", "месяцев"])}`;
  }

  return `${label}: ${n}`;
}
