export function useManyJsonRpc(...requests) {
  const result = {};

  for (let request of requests) {
    const fields = Object.keys(request);

    for (let field of fields) {
      if (!result[field]) {
        result[field] = [];
      }

      result[field].push(request[field]);
    }
  }

  return {
    ...result,
    loading: result.loading.some((loading) => loading === true),
    error: result.error.find((error) => error),
  };
}
