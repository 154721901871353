import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "moment/locale/ru";
import _ from "lodash";
import styled from "styled-components";
import { Button, Table, Form, Input, Divider, Breadcrumb, Tag, Modal, Skeleton, Space, Typography, Row, Col, notification } from "antd";
import { EditOutlined, FolderOpenOutlined, LoadingOutlined, PlusOutlined, LinkOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { JsonRpcErrorCode } from "@com.marathonium/web2-utils";

import { InputImage } from "../components/InputImage";
import { UserTable, SearchUserForm } from "../components/SelectUser";
import {
  DateCellRenderer,
  TrainingCellRenderer,
  StreamCellRenderer,
  PracticAnswerStatusRenderer,
  PracticCellRenderer,
  LessonCellRenderer,
  TrainingPackageCellRenderer,
  OfferListCellRenderer,
  OfferSubscribeRecurrenceCellRenderer,
  PurchaseActiveCellRenderer,
} from "../components/CellRenderer";
import { Image } from "../components/Image";
import { PageError } from "../components/PageError";
import { MyTabs } from "../components/Tabs";
import EditableTable2 from "../components/EditableTable2";
import { AsyncSelect, defaultSearchFilter } from "../components/FormInput";

import { DateFormats, apiPath } from "../constants";
import { getSiteUrl, fetchWith401, fetchWithJsonRpc, callN8n2 } from "../helpers/helpers";
import { handleDelete, handleSave } from "../helpers/request";
import { useQuery } from "../hooks/useQuery";
import { useN8n2 } from "../hooks/useN8n2";
import { history } from "../history";
import { render } from "../jhtml";

const { Title, Text, Paragraph, Link: AntdLink } = Typography;

const UsersImportInputFile = styled.input`
  display: none;
`;

export function UsersImportModal({ visible, onCancel, onConfirm, data }) {
  const total = _.get(data, "total") || 0;
  const found = _.get(data, "found") || 0;
  const toCreate = total - found;

  return (
    <Modal
      title="Импорт пользователей"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            onConfirm();
          }}
        >
          Продолжить
        </Button>,
      ]}
    >
      <Paragraph>Будет создано пользователей: {toCreate}</Paragraph>
      <Paragraph>Будут пропущены (уже существуют): {found}</Paragraph>
      <Paragraph>Продолжить?</Paragraph>
    </Modal>
  );
}

function UsersImport() {
  const inputRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckInProgress, setIsCheckInProgress] = useState(false);
  const [modalData, setModalData] = useState({});
  const csvDataRef = useRef();

  const onImportButtonClick = () => {
    inputRef.current.click();
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onChangeHandler = async (e) => {
    try {
      setIsCheckInProgress(true);
      const file = _.get(e, "target.files[0]");
      csvDataRef.current = await convertFileToBase64(file);
      const data = await fetchWithJsonRpc("admin.user.import_check.v1", { file: csvDataRef.current });

      setModalData(data);
      openConfirmModal();
    } catch (e) {
      notification.error({
        description: "Не удалось выполнить импорт. Попробуйте ещё раз",
      });
    } finally {
      setIsCheckInProgress(false);
      inputRef.current.value = "";
    }
  };

  const openConfirmModal = () => {
    setIsModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsModalOpen(false);
  };

  const onConfirmHandler = async () => {
    try {
      const startKey = Date.now();

      notification.info({
        description: "Процесс импорта запущен",
        key: startKey,
        icon: <LoadingOutlined style={{ color: blue.primary }} />,
      });

      const importResp = await fetchWithJsonRpc("admin.user.import.v1", { file: csvDataRef.current });

      notification.close(startKey);

      notification.success({
        message: "Импорт успешно завершен",
        description: (
          <>
            <Text>Добавлена группа пользователей:</Text>
            <br />
            <Text>"{_.get(importResp, "user_group.title")}"</Text>
          </>
        ),
        btn: (
          <Button
            type="primary"
            size="small"
            onClick={() => history.push()}
            href={`/user_groups/${_.get(importResp, "user_group.id")}`}
            target="_blank"
          >
            Открыть карточку группы
          </Button>
        ),
      });
    } catch (e) {
      notification.error({
        description: "Ошибка импорта",
      });
    } finally {
      closeConfirmModal();
    }
  };

  return (
    <>
      <Button type="primary" onClick={onImportButtonClick} loading={isCheckInProgress}>
        + Импортировать
      </Button>
      <UsersImportInputFile type="file" ref={inputRef} onChange={onChangeHandler} />
      <UsersImportModal visible={isModalOpen} onCancel={closeConfirmModal} onConfirm={onConfirmHandler} data={modalData} />
    </>
  );
}

const UsersTableButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 10px;
`;

export function UsersTable() {
  const query = useQuery();
  const queryPage = parseInt(query.get("page"));
  const currentPage = !isNaN(queryPage) ? queryPage : 1;
  const sortBy = query.get("sortBy") || "created_at";
  const sortOrder = query.get("sortOrder") || "descend";
  const searchText = query.get("search") || "";
  const segmentId = query.get("segment");

  const [segmentData, setSegmentData] = useState({});
  const [segmentLoaded, setSegmentLoaded] = useState(false);

  useEffect(() => {
    if (!segmentId) {
      setSegmentData({});
      return;
    }

    callN8n2("admin.segment.details.v1", { id: segmentId })
      .then(setSegmentData)
      .finally(() => {
        setSegmentLoaded(true);
      });
  }, [segmentId]);

  const pageSize = 10;

  const handleTableChange = (pagination, filters, sorter) => {
    query.set("page", pagination.current);

    if (sorter.column && sorter.field && sorter.order) {
      query.set("sortBy", sorter.field);
      query.set("sortOrder", sorter.order);
    } else {
      query.delete("sortBy");
      query.delete("sortOrder");
    }

    history.push(`/users?${query.toString()}`);
  };

  function handleSearch(submission) {
    query.delete("page");

    if (submission.search) {
      query.set("search", submission.search);
    } else {
      query.delete("search");
    }

    history.push(`/users?${query.toString()}`);
  }

  function handleResetSegment() {
    query.delete("segment");
    history.push(`/users?${query.toString()}`);
  }

  const columns = [
    {
      title: "Фото",
      dataIndex: "photo",
      key: "photo",
      render: (image, record) => (
        <Link to={`/users/${record.id}`}>
          <Image width={80} src={image} preview={false} />
        </Link>
      ),
      width: 100,
    },
    {
      title: "Фамилия и имя",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const disp = `${record.surname || ""} ${text || ""}`.trim();
        return <Link to={`/users/${record.id}`}>{disp || "-"}</Link>;
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (t) => t || "-",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (t) => t || "-",
    },
    {
      title: "Группы",
      dataIndex: "user_group",
      key: "user_group",
      width: 250,
      render: (groups) => {
        if (!Array.isArray(groups) || !groups.length) {
          return "-";
        }

        const groupsNumber = groups.length;
        const visibleGroups = _.take(groups, 3);

        return (
          <>
            <div>
              {visibleGroups.map((item) => {
                return (
                  <Tag key={item.id}>
                    <Link to={`/user_groups/${item.id}`}>{item.title}</Link>
                  </Tag>
                );
              })}
            </div>
            {groupsNumber > 3 ? "..." : null}
          </>
        );
      },
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      render: (t) => t || "students",
    },
    {
      title: "Дата регистрации",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortBy === "created_at" && sortOrder ? sortOrder : undefined,
    },
    {
      title: "Дата авторизации",
      dataIndex: "last_login_by_sms",
      key: "last_login_by_sms",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder: sortBy === "last_login_by_sms" && sortOrder ? sortOrder : undefined,
    },
    {
      key: "actions",
      fixed: "right",
      width: "50px",
      render: (text, record) => (
        <Link to={`/users/${record.id}`}>
          <EditOutlined title="Редактировать" />
        </Link>
      ),
    },
  ];

  const onRefreshError = () => {
    history.push(`/users`);
  };

  return (
    <div>
      <UsersTableButtons>
        <Button type="primary" onClick={() => history.push("/users/create")}>
          + Добавить
        </Button>
        <UsersImport />
      </UsersTableButtons>

      <SearchUserForm initialValue={searchText} onSubmit={handleSearch} disabled={segmentId}>
        {segmentId && segmentLoaded && (
          <Space>
            <Text>Выбрать по сегменту {`"${segmentData.title}".`}</Text>
            <Button type="link" onClick={handleResetSegment}>
              Сбросить
            </Button>
          </Space>
        )}
      </SearchUserForm>

      <Divider />

      {searchText || segmentId ? (
        <UserTable
          segment={segmentId}
          columns={columns}
          page={currentPage}
          pageSize={pageSize}
          searchText={searchText}
          sortBy={sortBy}
          sortOrder={sortOrder}
          handleTableChange={handleTableChange}
          onRefreshError={onRefreshError}
          scroll={{ x: 1800 }}
        />
      ) : (
        <Title level={5}>Укажите критерии поиска для отображения пользователей</Title>
      )}
    </div>
  );
}

function UserBaseForm({ data, submitForm }) {
  const isEditFormMode = !_.isEmpty(data);

  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Имя" name="name">
            <Input />
          </Form.Item>

          <Form.Item label="Фамилия" name="surname">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Телефон" name="phone">
            <Input />
          </Form.Item>

          <Form.Item label="E-mail" name="email">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Фото" name="photo">
        <InputImage />
      </Form.Item>

      {isEditFormMode && (
        <Row gutter={24} style={{ marginBottom: 24 }}>
          <Col span={12}>
            <Row gutter={[24, 20]}>
              <Col span={24}>
                <Space direction="vertical" size={12}>
                  <Text>utm_source</Text>
                  <Text strong>{_.get(data, "utm_source") || "-"}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={12}>
                  <Text>utm_medium</Text>
                  <Text strong>{_.get(data, "utm_medium") || "-"}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={12}>
                  <Text>utm_content</Text>
                  <Text strong>{_.get(data, "utm_content") || "-"}</Text>
                </Space>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[24, 20]}>
              <Col span={24}>
                <Space direction="vertical" size={12}>
                  <Text>Дата регистрации</Text>
                  <Text strong>
                    <DateCellRenderer format={DateFormats.withSeconds} value={_.get(data, "created_at")} />
                  </Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={12}>
                  <Text>Точка регистрации</Text>
                  <Text strong>{_.get(data, "gateway") || "-"}</Text>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function UsersCreate() {
  function submitForm(submission) {
    fetchWithJsonRpc("admin.user.create.v1", submission).then(({ id }) => {
      history.push(`/users/${id}`);
    });
  }

  return <UserBaseForm data={{}} submitForm={submitForm} />;
}

function UserGroupsList({ id }) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = (id) => {
    setIsLoaded(false);
    fetchWith401(
      `${apiPath}/user_group_item?id_user=eq.${id}&select=id,id_user_group,user_group(id,title)&order=created_at.asc`,
      {},
      setData,
      history
    )
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  if (error) {
    return <PageError />;
  }

  const columns = [
    {
      width: "100%",
      title: "Название группы",
      dataIndex: "id_user_group",
      key: "id_user_group",
      editable: (r) => !_.get(r, "id"),
      alwaysEditable: true,
      render: (t, r) => {
        if (!r.user_group) {
          return "-";
        }
        return (
          <Button type="link" href={`/user_groups/${r.user_group.id}`}>
            {r.user_group.title}
          </Button>
        );
      },
      inputNode: (ref, save, r) => (
        <AsyncSelect
          onChange={save}
          onBlur={save}
          defaultValue={r.id_user_group}
          title="title"
          resource="user_group"
          autoFocus
          showSearch={true}
          filterOption={defaultSearchFilter}
        />
      ),
    },
  ];

  if (!isLoaded) {
    return <Table loading={true} />;
  }

  return (
    <EditableTable2
      size="small"
      buttonAddLabel="Добавить в группу"
      handleDelete={handleDelete(history, "user_group_item")}
      handleSave={(e, succ) => {
        if (!e.id_user_group) {
          return;
        }
        handleSave(history, "user_group_item", id, ["key"], {
          id_user: id,
        })(e, () => {
          succ();
          fetchData(id);
        }).catch((e) => {
          console.error(e);
          notification.error({
            message: "Ошибка",
            description: ((code) => {
              switch (code) {
                case JsonRpcErrorCode.Conflict:
                  return "Пользователь уже состоит в этой группе";
                default:
                  return "Не удалось добавить пользователя в группу";
              }
            })(parseInt(e.message)),
          });
        });
      }}
      columns={columns}
      dataSource={data}
      rowKey="id"
    />
  );
}

function UserSubscribesList({ id }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    callN8n2("admin.purchase_subscribe.search_by_user.v1", { id })
      .then((result) => result.items)
      .then((result) => {
        setData(result);
        setIsLoaded(true);
      })
      .catch((e) => {
        setError(e);
      });
  }, [id]);

  if (error) {
    return <PageError />;
  }

  const columns = [
    {
      title: "Продукт",
      dataIndex: "training_package",
      key: "training_package",
      render: (tp) => <TrainingPackageCellRenderer data={tp} />,
    },
    {
      title: "Платформа",
      dataIndex: "platform",
      key: "platform",
      render: (platform, record) => {
        return (
          <Text style={{ whiteSpace: "nowrap" }}>
            {platform} / {_.get(record, "period")}
          </Text>
        );
      },
    },
    {
      title: "Даты доступа",
      dataIndex: "dates",
      key: "dates",
      render: (dates, record) => {
        return (
          <>
            <DateCellRenderer value={_.get(record, "created_at")} format={DateFormats.dateOnly} />
            <Text> - </Text>
            <DateCellRenderer value={_.get(record, "ended_at")} format={DateFormats.dateOnly} />
          </>
        );
      },
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.default} />,
    },
    {
      title: "Автопродление",
      dataIndex: "recurrence",
      key: "recurrence",
      render: (recurrence) => <OfferSubscribeRecurrenceCellRenderer value={recurrence} />,
    },
    {
      title: "Следующее списание",
      dataIndex: "next_increase_date",
      key: "next_increase_date",
      render: (next_increase_date, record) => {
        const { recurrence, price } = record || {};
        if (!recurrence) {
          return "-";
        }

        return (
          <>
            <Text>{price} руб., </Text>
            <DateCellRenderer value={next_increase_date} format={DateFormats.dateOnly} />
          </>
        );
      },
    },
    {
      title: "Статус",
      dataIndex: "active",
      key: "active",
      render: (value) => <PurchaseActiveCellRenderer value={value} />,
    },
    {
      key: "actions",
      fixed: "right",
      width: "50px",
      render: (text, record) => (
        <Link to={`/purchases/subscribes/${record.id}`}>
          <FolderOpenOutlined title="Открыть карточку подписки" />
        </Link>
      ),
    },
  ];

  return (
    <>
      <Title level={5}>Подписки</Title>
      <Table columns={columns} dataSource={data} rowKey="id" loading={!isLoaded} />
    </>
  );
}

function UserPurchasesList({ id }) {
  const { loading, data = {}, error } = useN8n2("admin.purchase.list.v1", { id_user: id }, [id]);

  if (error) {
    return <PageError />;
  }

  const columns = [
    {
      title: "Тренинг",
      dataIndex: "training",
      key: "training",
      render: (training) => <TrainingCellRenderer data={training} />,
    },
    {
      title: "Поток",
      dataIndex: "stream",
      key: "stream",
      render: (stream) => <StreamCellRenderer title={stream.title} link={stream.link} />,
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Дата начала покупки",
      dataIndex: "started_at",
      key: "started_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Дата окончания покупки",
      dataIndex: "ended_at",
      key: "ended_at",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      title: "Статус",
      dataIndex: "active",
      key: "active",
      render: (value) => <PurchaseActiveCellRenderer value={value} />,
    },
    {
      key: "actions",
      fixed: "right",
      width: "50px",
      render: (text, record) => (
        <Link to={`/purchases/${record.id}`}>
          <FolderOpenOutlined title="Открыть карточку покупки" />
        </Link>
      ),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Space align="baseline" size="large">
        <Space align="baseline">
          <Title level={5}>Покупки</Title>
          <Button
            size="small"
            type="primary"
            ghost={true}
            icon={<PlusOutlined />}
            onClick={() => history.push(`/purchases/create?id_user=${id}`)}
          >
            Добавить
          </Button>
          <Button
            size="small"
            type="primary"
            ghost={true}
            icon={<PlusOutlined />}
            onClick={() => history.push(`/purchases/create_by_offer_package?id_user=${id}`)}
          >
            Добавить по оффер-пакету
          </Button>
        </Space>
      </Space>

      <Table
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize: 50,
        }}
      />
    </Space>
  );
}

function UserOrdersList({ id }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    callN8n2("admin.order.list.v1", {
      id_user: id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then(({ items }) => {
        setData(items);
        setIsLoaded(true);
      })
      .catch((e) => {
        setError(e);
      });
  }, [id]);

  if (error) {
    return <PageError />;
  }

  const columns = [
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (value, record) => (
        <Link to={`/orders/${record.id}`}>
          <Button type="primary" ghost={true} size="small" icon={<FolderOpenOutlined style={{ marginRight: 5 }} />}>
            {value}
          </Button>
        </Link>
      ),
    },
    {
      title: "Сумма",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (value) => value || "-",
    },
    {
      title: "Офферы",
      dataIndex: "offers",
      key: "offers",
      render: (value) => <OfferListCellRenderer value={value} />,
    },
    {
      title: "Оплачен?",
      dataIndex: "paid_at",
      key: "paid_at",
      render: (value, r) => {
        if (!value) {
          return "-";
        }
        return <Tag color="green">{value}</Tag>;
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      loading={!isLoaded}
      pagination={{
        pageSize: 100,
      }}
    />
  );
}

function UserAnswersList({ id }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchWithJsonRpc("admin.practic_answer.search_by_user.v1", { id })
      .then((result) => {
        setData(result);
        setIsLoaded(true);
      })
      .catch((e) => {
        setError(e);
      });
  }, [id]);

  if (error) {
    return <PageError />;
  }

  const columns = [
    {
      title: "Время создания",
      dataIndex: "created_at",
      key: "created_at",
      width: "180px",
      render: (value) => <DateCellRenderer value={value} format={DateFormats.withSeconds} />,
    },
    {
      key: "actions",
      width: "100px",
      render: (text, record) => (
        <Link to={`/practic_answers/${record.id}`}>
          <Button type="primary" ghost={true} size="small" icon={<FolderOpenOutlined />}>
            Карточка
          </Button>
        </Link>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (text, r) => <PracticAnswerStatusRenderer value={text} />,
    },

    {
      title: "Тренинг",
      dataIndex: "training",
      key: "training",
      render: (training) => <TrainingCellRenderer data={training} />,
    },
    {
      title: "Урок",
      dataIndex: "lesson",
      key: "lesson",
      render: (lesson) => <LessonCellRenderer data={lesson} />,
    },
    {
      title: "Практика",
      dataIndex: "practic",
      key: "practic",
      render: (practic) => <PracticCellRenderer data={practic} />,
    },
  ];

  return <Table columns={columns} dataSource={data} rowKey="id" loading={!isLoaded} />;
}

const UsersEditHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function LoginThroughUserButton({ id }) {
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    callN8n2("admin.platform.list.v2", { id, context: "login" }).then((result) => {
      setPlatforms(result);
    });
  }, [id]);

  const jhtml = {
    type: "antd_dropdown",
    props: {
      overlay: {
        type: "antd_menu",
        children: platforms.map((platform) => ({
          type: "antd_menu_item",
          props: {
            key: platform.id,
            onClick: {
              fn: `
  callN8n2("admin.admin.login_through_user.v1", { id: "${id}", platform: "${platform.id}" })
    .then((r) => r.token)
    .then((token) => {
      if ("${platform.id}" === "web") {
        const loginUrl = "${getSiteUrl()}/set_jwt/" + token;
        window.open(loginUrl, "_blank");
      } else {
        notification.success({
          message: "Успех",
          description: "На телефон отправлено уведомление для входа",
        });
      }
    })
    .catch((e) => {
      notification.error({
        message: "Ошибка",
        description: "Что-то пошло не так. Попробуйте ещё раз",
      });
    });
                `,
            },
          },
          children: [
            {
              type: "text_node",
              props: {
                value: platform.desc,
              },
            },
          ],
        })),
      },
    },
    children: [
      {
        type: "antd_button",
        props: {
          type: "primary",
          ghost: true,
          icon: "UserSwitchOutlined",
        },
        children: [
          {
            type: "text_node",
            props: {
              value: "Войти под пользователем",
            },
          },
        ],
      },
    ],
  };

  return render(jhtml);
}

function OmnideskButton({ id_user }) {
  const [textItems, setTextItems] = useState([]);

  useEffect(() => {
    callN8n2("admin.omnidesk_text.list.v1")
      .then((result) => {
        setTextItems(result);
      })
      .catch(() => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось загрузить список текстов Omnidesk",
        });
      });
  }, []);

  const jhtml = {
    type: "fragment",
    children: [
      {
        type: "react_use_state",
        props: {
          getter: "isModalVisible",
          setter: "setIsModalVisible",
          initialValue: false,
        },
        children: [
          {
            type: "react_use_state",
            props: {
              getter: "isConfirmLoading",
              setter: "setIsConfirmLoading",
              initialValue: false,
            },
            children: [
              {
                type: "react_use_state",
                props: {
                  getter: "textItemId",
                  setter: "setTextItemId",
                  initialValue: "",
                },
                children: [
                  {
                    type: "react_use_state",
                    props: {
                      getter: "textItemText",
                      setter: "setTextItemText",
                      initialValue: "",
                    },
                    children: [
                      {
                        type: "antd_dropdown",
                        props: {
                          placement: "bottomRight",
                          overlay: {
                            type: "antd_menu",
                            props: {
                              style: { maxWidth: 450 },
                            },
                            children: textItems.map((item) => ({
                              type: "antd_menu_item",
                              props: {
                                key: item.id,
                                onClick: {
                                  fn: `setTextItemId("${item.id}"); setTextItemText("${item.text}"); setIsModalVisible(true)`,
                                },
                                style: { overflow: "hidden", textOverflow: "ellipsis" },
                              },
                              children: [
                                {
                                  type: "text_node",
                                  props: {
                                    value: item.text,
                                  },
                                },
                              ],
                            })),
                          },
                        },
                        children: [
                          {
                            type: "antd_button",
                            props: {
                              type: "primary",
                              ghost: true,
                              icon: "MessageOutlined",
                            },
                            children: [
                              {
                                type: "text_node",
                                props: {
                                  value: "Создать обращение Omnidesk",
                                },
                              },
                            ],
                          },
                        ],
                      },

                      {
                        type: "antd_modal",
                        props: {
                          title: "Обращение Omnidesk",
                          visible: "{{isModalVisible}}",
                          onOk: {
                            fn: `
  setIsConfirmLoading(true); 
      setIsConfirmLoading(true);
  setIsConfirmLoading(true); 
                              
  callN8n2("admin.order_omnidesk_notification.create.v1", { id_user: "${id_user}", id_omnidesk_text: textItemId })
    .then(() => {
      setIsModalVisible(false);
  
      notification.success({
        message: "Успех",
        description: "Обращение в Omnidesk успешно создано",
      });
    })
    .catch((e) => {
      notification.error({
        message: "Ошибка",
        description: "Не удалось создать обращение в Omnidesk",
      });
    })
    .finally(() => {
      setIsConfirmLoading(false);
    });`,
                          },
                          onCancel: {
                            fn: "setIsModalVisible(false)",
                          },
                          okText: "Отправить",
                          cancelText: "Отмена",
                          confirmLoading: "{{isConfirmLoading}}",
                        },
                        children: [
                          {
                            type: "antd_paragraph",
                            children: [
                              {
                                type: "text_node",
                                props: {
                                  value: "Текст:",
                                },
                              },
                            ],
                          },
                          {
                            type: "div",
                            children: [
                              {
                                type: "text_node",
                                props: {
                                  value: "{{textItemText}}",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  return render(jhtml);
}

export function UsersEdit() {
  const { id, tab = "base" } = useParams();

  const [error, setError] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    fetchWithJsonRpc("admin.user.details.v1", { id }, {}, 2)
      .then(setData)
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, [id]);

  function submitForm(submission) {
    fetchWith401(
      // admin.user.update.v1
      `${apiPath}/user?id=eq.${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(_.mergeWith(submission, { id })),
      },
      null,
      history
    ).then(() => {
      notification.success({
        description: "Данные успешно сохранены",
      });
    });
  }

  if (!isLoaded) {
    return <Skeleton active />;
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <UsersEditHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/users">Пользователи</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Space size={20}>
              <Text>{`${data.phone || data.email}`}</Text>
              {data.referral_link && (
                <Space>
                  <Text>{data.referral_link}</Text>
                  <AntdLink href={data.referral_link} target="_blank">
                    <LinkOutlined style={{ color: blue.primary }} />
                  </AntdLink>
                </Space>
              )}
            </Space>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Space>
          <OmnideskButton id_user={id} />
          <LoginThroughUserButton id={id} />
        </Space>
      </UsersEditHeader>

      <Divider />

      <MyTabs
        activeKey={tab}
        resolvePath={(key) => `/users/${id}/${key}`}
        items={[
          { key: "base", title: "Основное", children: <UserBaseForm data={data} submitForm={submitForm} /> },
          { key: "groups", title: "Группы", children: <UserGroupsList id={id} /> },
          {
            key: "purchases",
            title: "Покупки",
            children: (
              <>
                <UserSubscribesList id={id} />
                <Divider />
                <UserPurchasesList id={id} />
              </>
            ),
          },
          { key: "orders", title: "Заказы", children: <UserOrdersList id={id} /> },
          { key: "answers", title: "Ответы", children: <UserAnswersList id={id} /> },
        ]}
      />
    </>
  );
}
