import { useContext } from "react";

import { JHTMLContext } from "../JHTMLContext";

export function Switch({ children, value, cases, _default }) {
  const { options, render } = useContext(JHTMLContext);

  const index = cases.findIndex((i) => i === value);

  if (index === -1) {
    return render(
      _default || {
        type: "null",
      },
      options
    );
  }

  return children[index];
}
