import { addAnchor } from "./anchor";
import { addButton } from "./button";
import { addDiv } from "./div";
import { addImage } from "./image";
import { addNestedPage } from "./nestedPage";
import { addOfferForm } from "./offerForm";
import { addRedirect } from "./redirect";
import { addVideoClip } from "./videoclip";

export const components = (editor, config) => {
  addAnchor(editor, config);
  addButton(editor, config);
  addDiv(editor, config);
  addImage(editor, config);
  addNestedPage(editor, config);
  addOfferForm(editor, config);
  addRedirect(editor, config);
  addVideoClip(editor, config);
};
