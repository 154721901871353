import { useContext, useState } from "react";

import { JHTMLContext } from "../JHTMLContext";

export function ReactUseState({ getter, setter, initialValue }) {
  const { options, children, renderChildren } = useContext(JHTMLContext);
  const [getterValue, setterValue] = useState(initialValue);

  const childrenOpts = {
    ...options,
    vars: {
      ...options.vars,
      [getter]: getterValue,
      [setter]: setterValue,
    },
  };

  return <>{renderChildren(children, childrenOpts)}</>;
}
