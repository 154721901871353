import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";

const jwtChangedEventName = "jwt-changed";
const jwtChangedEvent = new Event(jwtChangedEventName);

export function getJWT() {
  return localStorage.getItem("jwt");
}

export function setJWT(jwt) {
  if (!jwt) {
    localStorage.removeItem("jwt");
  } else {
    localStorage.setItem("jwt", jwt);
  }

  window.dispatchEvent(jwtChangedEvent);
}

export function hasJWT() {
  return !!getJWT();
}

export function getUserData() {
  const jwt = getJWT();
  if (!jwt) {
    return null;
  }

  return jwtDecode(jwt);
}

export function useIsAuthorized() {
  const [hasToken, setHasToken] = useState(hasJWT());

  useEffect(() => {
    const onJWTChange = () => {
      setHasToken(hasJWT());
    };

    window.addEventListener(jwtChangedEventName, onJWTChange);
    return () => {
      window.removeEventListener(jwtChangedEventName, onJWTChange);
    };
  });

  return hasToken;
}

export function Anonymous({ children }) {
  const isAnonymous = !useIsAuthorized();
  return isAnonymous ? children : null;
}

export function Authorized({ children }) {
  const isAuthorized = useIsAuthorized();
  return isAuthorized ? children : null;
}
