import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import {
  Col,
  Row,
  Radio,
  Breadcrumb,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
  notification,
} from "antd";
import { DeleteOutlined, LinkOutlined } from "@ant-design/icons";

import { TrainingPackageCellRenderer, OfferPriceCellRenderer } from "../components/CellRenderer";
import { AsyncSelect2, AsyncSelectWithLink, defaultSearchFilter } from "../components/FormInput";
import { PageError } from "../components/PageError";
import { CardHeader } from "../components/Card";
import { MyTabs } from "../components/Tabs";
import { AsyncSelect as ActivityAsyncSelect, createActivityRef } from "../components/Activity";

import { showFailureMessage, showFailureSaveMessage, showSuccessSaveMessage } from "../helpers/notification";
import { handleDeleteAdminRpc, handleSaveAdminRpc } from "../helpers/request";
import { useQuery } from "../hooks/useQuery";
import { useN8n } from "../hooks/useN8n";
import { useN8n2 } from "../hooks/useN8n2";
import { useN8n2Defer } from "../hooks/useN8n2Defer";
import { useManyJsonRpc } from "../hooks/useManyJsonRpc";
import { history } from "../history";
import { render } from "../jhtml";

const { Title, Text } = Typography;

export function Offers() {
  const { tab = "list" } = useParams();

  return (
    <MyTabs
      activeKey={tab}
      resolvePath={(key) => `/offers/${key}`}
      items={[
        { key: "offer_packages", title: "Пакеты", children: <OfferPackageTable /> },
        { key: "subscribes", title: "Подписки", children: <OfferSubscribesTable /> },
        { key: "list", title: "Сингл-офферы", children: <OffersTable /> },
        { key: "offer_increase", title: "Продления", children: <OfferIncreaseTable /> },
      ]}
    />
  );
}

function OfferIncreaseTable() {
  const query = useQuery();

  const queryPage = parseInt(query.get("page"));
  const currentPage = !isNaN(queryPage) ? queryPage : 1;

  const queryPageSize = parseInt(query.get("pageSize"));
  const pageSize = !isNaN(queryPageSize) ? queryPageSize : 50;

  function handleTableChange(pagination) {
    query.set("page", pagination.current);
    history.push(`/offers/offer_increase?${query.toString()}`);
  }

  function onShowSizeChange(current, size) {
    query.set("pageSize", size);
    history.push(`/offers/offer_increase?${query.toString()}`);
  }

  const { loading, data = {}, error } = useN8n2(
    "admin.offer_increase.list.v1",
    {
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
    },
    [pageSize, currentPage]
  );

  if (error) {
    return <PageError />;
  }

  if (loading) {
    return <Table loading={true} />;
  }

  const data2 = {
    type: "fragment",
    children: [
      {
        type: "router_link",
        props: {
          to: "/offers/offer_increase/create",
        },
        children: [
          {
            type: "antd_button",
            props: {
              type: "primary",
            },
            children: [
              {
                type: "text_node",
                props: {
                  value: "+ Добавить",
                },
              },
            ],
          },
        ],
      },
      {
        type: "antd_divider",
      },
      {
        type: "antd_table",
        props: {
          columns: [
            {
              title: "Статус",
              dataIndex: "status",
              key: "status",
              render_type: "tag",
            },
            {
              title: "Название",
              dataIndex: "title",
              key: "title",
              render_type: "title_link",
            },
            {
              title: "Тренинг",
              dataIndex: "training",
              key: "training",
              render_type: "training_link",
            },
            {
              title: "Период",
              dataIndex: "period",
              key: "period",
              render_type: "cell_desc",
            },
            {
              title: "Цена",
              dataIndex: "price",
              key: "price",
              align: "right",
              render_type: "price_and_marketing_price",
            },
            {
              title: "Платформа",
              dataIndex: "platform",
              key: "platform",
              render_type: "cell_desc",
            },
          ],
          dataSource: data.items.map((item) => ({
            ...item,
            link: `/offers/offer_increase/${item.id}`,
          })),
          rowKey: "id",
          onChange: {
            fn: "return handleTableChange(e)",
          },
          pagination: {
            current: "{{currentPage}}",
            pageSize: "{{pageSize}}",
            total: data.total,
            onShowSizeChange: {
              fn: "return onShowSizeChange(current, size)",
            },
          },
          rowClassName: {
            fn: "return record.muted ? 'tr_muted' : null",
          },
        },
      },
    ],
  };

  return render(data2, {
    vars: {
      currentPage,
      pageSize,
      handleTableChange,
      onShowSizeChange,
    },
  });
}

function OfferIncreaseBaseForm({ data, submitForm }) {
  const [form] = Form.useForm();
  const [target, setTarget] = useState(_.get(data, "id_segment") ? "segment" : "all");
  const [selectedSegment, setSelectedSegment] = useState(_.get(data, "id_segment") || "");
  const isEditFormMode = !_.isEmpty(data);

  return (
    <Form
      form={form}
      initialValues={data}
      layout="vertical"
      onFinish={() => {
        submitForm(form.getFieldsValue());
      }}
    >
      <Row gutter={[50, 0]}>
        <Col xs={24} md={12}>
          <Form.Item label="Название" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Входит в активность" name="id_activity" rules={[{ required: true }]}>
            <ActivityAsyncSelect id_offer_increase={data.id} />
          </Form.Item>

          <Form.Item label="Статус" name="status" rules={[{ required: true }]}>
            <AsyncSelect2
              jrpcMethod="admin.selector.any_offer_status.v1"
              n8nVersion={2}
              title="label"
              idKey="value"
              style={{ width: "100%" }}
              defaultActiveFirstOption
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="Тренинг" name="id_training" rules={[{ required: true }]}>
            <AsyncSelect2
              jrpcMethod="admin.training.list.v1"
              n8nVersion={2}
              title="title"
              showSearch={true}
              filterOption={defaultSearchFilter}
              style={{ width: "100%" }}
              disabled={isEditFormMode}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col>
          <Form.Item label="Отображение">
            <Radio.Group
              options={[
                { label: "Всем", value: "all" },
                { label: "Сегменту", value: "segment" },
              ]}
              onChange={(e) => {
                form.resetFields(["id_segment"]);
                return setTarget(e.target.value);
              }}
              value={target}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            display: "flex",
            flex: "auto",
            alignItems: "center",
            gridGap: 10,
          }}
        >
          {target === "segment" && (
            <>
              <Form.Item
                label=" "
                name="id_segment"
                style={{
                  flex: "auto",
                }}
                rules={[{ required: true }]}
                required={false}
              >
                <AsyncSelect2
                  jrpcMethod="admin.segment.list.v1"
                  n8nVersion={2}
                  showSearch={true}
                  filterOption={defaultSearchFilter}
                  onChange={(e) => setSelectedSegment(e)}
                />
              </Form.Item>
              {selectedSegment && (
                <Link
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  to={`/segments/${selectedSegment}`}
                >
                  <Space>
                    <>Открыть сегмент</>
                    <LinkOutlined />
                  </Space>
                </Link>
              )}
            </>
          )}
        </Col>
      </Row>

      <Form.Item label="Изображение" name="image">
        <Input />
      </Form.Item>

      <Form.Item label="Цена" name="price" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item label="Маркетинговая цена" name="marketing_price">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Платформа" name="platforms" rules={[{ required: true }]}>
        <AsyncSelect2 mode="multiple" jrpcMethod="admin.platform.list.v1" title="desc" style={{ width: 300 }} />
      </Form.Item>
      <Form.Item label="Период" name="period" rules={[{ required: true }]}>
        <AsyncSelect2 n8nVersion={2} jrpcMethod="admin.purchase_increase_period.list.v1" title="desc" style={{ width: 300 }} />
      </Form.Item>
      <Form.Item label="Описание для чека" name="desc_for_receipt" rules={[{ required: true }]}>
        <Input.TextArea autoSize={{ minRows: 3 }} maxLength={128} showCount />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function OfferIncreaseCreate() {
  const create = useN8n2Defer("admin.offer_increase.create.v1");

  function submitForm(submission) {
    create(submission)
      .then(({ id }) => {
        createActivityRef(null, { id_offer_increase: id });
        history.push(`/offers/offer_increase/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/offers/offer_increase">Продления</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Новое продление</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <OfferIncreaseBaseForm data={{}} submitForm={submitForm} />
    </>
  );
}

export function OfferIncreaseEdit() {
  let { id } = useParams();

  const { loading, data = {}, error } = useN8n2("admin.offer_increase.details.v1", { id }, [id]);
  const update = useN8n2Defer("admin.offer_increase.update.v1");
  const remove = useN8n2Defer("admin.offer_increase.delete.v1");

  function submitForm(submission) {
    update({ ...submission, id })
      .then(() => {
        showSuccessSaveMessage();
        history.push(`/offers/offer_increase/${id}`);
      })
      .catch((e) => {
        showFailureMessage(e.message);
      });
  }

  async function onDelete() {
    try {
      await remove({ id });
      notification.success({
        description: "Продление удалёно",
      });
      history.push(`/offers/offer_increase`);
    } catch (e) {
      notification.error({
        description: "Не удалось удалить продление",
      });
    }
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/offers/offer_increase">Продления</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <OfferIdView id={data.id} />
          </Breadcrumb.Item>
        </Breadcrumb>
        <Popconfirm disabled={loading} title="Удалить продление?" onConfirm={onDelete} okText="Удалить" cancelText="Отмена">
          <Button type="danger" ghost={true} icon={<DeleteOutlined />} disabled={loading}>
            Удалить продление
          </Button>
        </Popconfirm>
      </CardHeader>

      <Divider />

      {loading ? <Skeleton active /> : <OfferIncreaseBaseForm data={data} submitForm={submitForm} />}
    </>
  );
}

function OfferPackageTable() {
  const { loading, data = [], error } = useN8n2("admin.offer_package.list.v1");

  function createNew() {
    history.push("/offers/offer_packages/create");
  }

  const columns = [
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (value) => <Tag color={_.get(value, "color")}>{_.get(value, "title")}</Tag>,
    },
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      editable: true,
      render: (title, record) => <Link to={`/offers/offer_packages/${record.id}`}>{title}</Link>,
    },
    {
      title: "Платформа",
      dataIndex: "platform",
      key: "platform",
      render: (platform) => _.get(platform, "desc") || "-",
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (price, record) => <OfferPriceCellRenderer price={price} marketing_price={record.marketing_price} />,
    },
  ];

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Button type="primary" onClick={createNew}>
        + Добавить
      </Button>
      <Divider />
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{
          pageSize: 50,
        }}
        loading={loading}
        rowClassName={(record) => (record.muted ? "tr_muted" : null)}
      />
    </>
  );
}

function OfferPackageBaseForm({ data, submitForm }) {
  const [target, setTarget] = useState(_.get(data, "id_segment") ? "segment" : "all");
  const [selectedSegment, setSelectedSegment] = useState(_.get(data, "id_segment") || "");
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      initialValues={data}
      layout="vertical"
      onFinish={() => {
        submitForm(form.getFieldsValue());
      }}
    >
      <Row gutter={[50, 0]}>
        <Col xs={24} md={12}>
          <Form.Item label="Название" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Входит в активность" name="id_activity" rules={[{ required: true }]}>
            <ActivityAsyncSelect id_offer_package={data.id} />
          </Form.Item>

          <Form.Item label="Статус" name="status" rules={[{ required: true }]}>
            <AsyncSelect2
              jrpcMethod="admin.selector.any_offer_status.v1"
              n8nVersion={2}
              title="label"
              idKey="value"
              style={{ width: "100%" }}
              defaultActiveFirstOption
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="Описание для пользователя" name="description">
            <Input.TextArea autoSize={{ minRows: 2 }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col>
          <Form.Item label="Отображение">
            <Radio.Group
              options={[
                { label: "Всем", value: "all" },
                { label: "Сегменту", value: "segment" },
              ]}
              onChange={(e) => {
                form.resetFields(["id_segment"]);
                return setTarget(e.target.value);
              }}
              value={target}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            display: "flex",
            flex: "auto",
            alignItems: "center",
            gridGap: 10,
          }}
        >
          {target === "segment" && (
            <>
              <Form.Item
                label=" "
                name="id_segment"
                style={{
                  flex: "auto",
                }}
                rules={[{ required: true }]}
                required={false}
              >
                <AsyncSelect2
                  jrpcMethod="admin.segment.list.v1"
                  n8nVersion={2}
                  showSearch={true}
                  filterOption={defaultSearchFilter}
                  onChange={(e) => setSelectedSegment(e)}
                />
              </Form.Item>
              {selectedSegment && (
                <Link
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  to={`/segments/${selectedSegment}`}
                >
                  <Space>
                    <>Открыть сегмент</>
                    <LinkOutlined />
                  </Space>
                </Link>
              )}
            </>
          )}
        </Col>
      </Row>

      <Form.Item label="Изображение" name="image">
        <Input />
      </Form.Item>

      <Form.Item label="Платформа" name="platforms" rules={[{ required: true }]}>
        <AsyncSelect2 mode="multiple" jrpcMethod="admin.platform.list.v1" title="desc" style={{ width: 300 }} />
      </Form.Item>

      <Form.Item label="Цена" name="price" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>

      <Form.Item label="Маркетинговая цена" name="marketing_price">
        <InputNumber />
      </Form.Item>

      <Form.Item label="Описание для чека" name="desc_for_receipt" rules={[{ required: true }]}>
        <Input.TextArea autoSize={{ minRows: 3 }} maxLength={128} showCount />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function OfferPackageCreate() {
  const create = useN8n2Defer("admin.offer_package.create.v1");

  function submitForm(submission) {
    create(submission)
      .then(({ id }) => {
        history.push(`/offers/offer_packages/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/offers/offer_packages">Пакеты</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Новый пакет</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <OfferPackageBaseForm data={{}} submitForm={submitForm} />
    </>
  );
}

export function OfferPackageEdit() {
  let { id } = useParams();

  const { loading, data = {}, error } = useN8n2("admin.offer_package.details.v1", { id }, [id]);
  const update = useN8n2Defer("admin.offer_package.update.v1");
  const remove = useN8n2Defer("admin.offer_package.delete.v1");

  function submitForm(submission) {
    update({ ...submission, id })
      .then(() => {
        showSuccessSaveMessage();
        history.push(`/offers/offer_packages/${id}`);
      })
      .catch((e) => {
        showFailureMessage(e.message);
      });
  }

  async function onDelete() {
    try {
      await remove({ id });
      notification.success({
        description: "Пакет удалён",
      });
      history.push(`/offers/offer_packages`);
    } catch (e) {
      notification.error({
        description: "Не удалось удалить пакет",
      });
    }
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/offers/offer_packages">Пакет</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <OfferIdView id={data.id} />
          </Breadcrumb.Item>
        </Breadcrumb>
        <Popconfirm disabled={loading} title="Удалить пакет?" onConfirm={onDelete} okText="Удалить" cancelText="Отмена">
          <Button type="danger" ghost={true} icon={<DeleteOutlined />} disabled={loading}>
            Удалить пакет
          </Button>
        </Popconfirm>
      </CardHeader>

      <Divider />

      {!loading ? <OfferPackageBaseForm data={data} submitForm={submitForm} /> : <Skeleton active />}

      <Divider />

      <Title level={5}>Состав пакета</Title>
      {!loading ? <OfferPackageItems id={id} platforms={_.get(data, "platforms")} /> : <Skeleton active />}
    </>
  );
}

function OfferPackageItems({ id, platforms }) {
  const { loading, data = [], error } = useN8n2("admin.offer_package_item.list.v1", { id }, [id]);
  const remove = handleDeleteAdminRpc("offer_package_item");

  const deleteHandler = (row, succ) => {
    remove({ ...row, id_offer_package: id })
      .then(succ)
      .catch((e) => showFailureMessage(e.message));
  };

  const saveHandler = (row, succ) => {
    handleSaveAdminRpc("offer_package_item", { id_offer_package: id }, null, "v1")(row, succ).catch(showFailureSaveMessage);
  };

  if (error) {
    return <PageError />;
  }

  if (loading) {
    return <Table loading={true} />;
  }

  const jhtml = {
    type: "fragment",
    children: [
      {
        type: "editable_table",
        props: {
          handleDelete: {
            fn: "return deleteHandler(row, succ)",
          },
          handleSave: {
            fn: "return saveHandler(row, succ)",
          },
          buttonAddLabel: "Добавить позицию",
          columns: [
            {
              title: "#",
              dataIndex: "position",
              editable: true,
              defaultValueFn: {
                fn: "return helpers.incrementPosition(10)",
              },
            },
            {
              title: "Оффер",
              dataIndex: "id_offer",
              editable: true,
              defaultValue: null,
              width: "45%",
              jhtml_render: {
                type: "fragment",
                children: [
                  {
                    type: "reference_field",
                    props: {
                      jrpcMethod: "admin.offer.details_by_platform.v1",
                      n8nVersion: 2,
                      source: "{{row.id_offer}}",
                      idKey: "id",
                      title: "title",
                      style: { width: "100%", maxWidth: "100%" },
                      link: `{{e! return '/offers/' + row.id_offer; }}`,
                      linkTarget: "_blank",
                    },
                  },
                ],
              },
              jhtml_input: {
                type: "async_select",
                props: {
                  jrpcMethod: "admin.offer.list_by_platform.v1",
                  jrpcParams: {
                    platforms: "{{platforms}}",
                  },
                  n8nVersion: 2,
                  autoFocus: true,
                  defaultValue: "{{row.id_offer}}",
                  title: "title",
                  onBlur: {
                    fn: "return save()",
                  },
                  showSearch: true,
                  filterOption: {
                    type: "default",
                  },
                  onChange: {
                    fn: "return row.id_offer_subscribe = null;",
                  },
                },
              },
            },
            {
              title: "Оффер-подписка",
              dataIndex: "id_offer_subscribe",
              editable: true,
              defaultValue: null,
              width: "45%",
              jhtml_render: {
                type: "fragment",
                children: [
                  {
                    type: "reference_field",
                    props: {
                      jrpcMethod: "admin.offer_subscribe.details_by_platform.v1",
                      n8nVersion: 2,
                      source: "{{row.id_offer_subscribe}}",
                      idKey: "id",
                      title: "title",
                      style: { width: "100%", maxWidth: "100%" },
                      link: `{{e! return '/offers/subscribes/' + row.id_offer_subscribe; }}`,
                      linkTarget: "_blank",
                    },
                  },
                ],
              },
              jhtml_input: {
                type: "async_select",
                props: {
                  jrpcMethod: "admin.offer_subscribe.list_by_platform.v1",
                  jrpcParams: {
                    platforms: "{{platforms}}",
                  },
                  n8nVersion: 2,
                  autoFocus: true,
                  defaultValue: "{{row.id_offer_subscribe}}",
                  title: "title",
                  onBlur: {
                    fn: "return save()",
                  },
                  showSearch: true,
                  filterOption: {
                    type: "default",
                  },
                  onChange: {
                    fn: "return row.id_offer = null;",
                  },
                },
              },
            },
          ],
          dataSource: data,
          pagination: false,
          style: {
            marginBottom: 10,
          },
        },
      },
    ],
  };

  return render(jhtml, {
    vars: {
      saveHandler,
      deleteHandler,
      platforms,
    },
  });
}

function OffersTable() {
  const query = useQuery();

  const platform = query.get("platform");
  const trainingsIds = query.get("trainings");
  const trainings = trainingsIds ? trainingsIds.split(",").map((i) => i.trim()) : [];

  const queryPage = parseInt(query.get("page"));
  const currentPage = !isNaN(queryPage) ? queryPage : 1;

  const queryPageSize = parseInt(query.get("pageSize"));
  const pageSize = !isNaN(queryPageSize) ? queryPageSize : 50;

  const handleChangePlatform = (value) => {
    if (value) {
      query.set("platform", value);
    } else {
      query.delete("platform");
    }

    query.delete("page");

    history.push(`/offers?${query.toString()}`);
  };

  const handleChangeTrainings = (value) => {
    if (Array.isArray(value) && value.length) {
      query.set("trainings", value.join(","));
    } else {
      query.delete("trainings");
    }

    query.delete("page");

    history.push(`/offers?${query.toString()}`);
  };

  function handleTableChange(pagination) {
    query.set("page", pagination.current);
    history.push(`/offers?${query.toString()}`);
  }

  function onShowSizeChange(current, size) {
    query.set("pageSize", size);
    history.push(`/offers?${query.toString()}`);
  }

  // FIXME use useN8n2("admin.screen.offer_list.v1") instead
  const { loading, data = [], error } = useManyJsonRpc(
    useN8n2(
      "admin.offer.list.v1",
      {
        platform,
        id_trainings: trainingsIds && trainingsIds.split(",").map((i) => i.trim()),
        limit: pageSize,
        offset: pageSize * (currentPage - 1),
      },
      [platform, trainingsIds, pageSize, currentPage]
    ),
    useN8n2("admin.platform.list.v2"),
    useN8n2("admin.training.list.v1")
  );

  if (error) {
    return <PageError />;
  }

  if (loading) {
    return <Table loading={true} />;
  }

  const [offers, platformList, trainingList] = data;

  const data2 = {
    type: "fragment",
    children: [
      {
        type: "antd_space",
        props: {
          size: 50,
        },
        children: [
          {
            type: "router_link",
            props: {
              to: "/offers/create",
            },
            children: [
              {
                type: "antd_button",
                props: {
                  type: "primary",
                },
                children: [
                  {
                    type: "text_node",
                    props: {
                      value: "+ Добавить",
                    },
                  },
                ],
              },
            ],
          },
          {
            type: "antd_space",
            children: [
              {
                type: "antd_text",
                children: [
                  {
                    type: "text_node",
                    props: {
                      value: "Платформа:",
                    },
                  },
                ],
              },
              {
                type: "antd_select",
                props: {
                  options: platformList.map((i) => ({ label: i.desc, value: i.id })),
                  value: "{{platform}}",
                  placeholder: "Не выбрана",
                  allowClear: true,
                  style: { width: 200 },
                  onChange: {
                    fn: "return handleChangePlatform(e)",
                  },
                },
              },
            ],
          },

          {
            type: "antd_space",
            children: [
              {
                type: "antd_text",
                children: [
                  {
                    type: "text_node",
                    props: {
                      value: "Тренинг:",
                    },
                  },
                ],
              },
              {
                type: "antd_select",
                props: {
                  options: trainingList.items.map((i) => ({ label: i.title, value: i.id })),
                  value: "{{trainings}}",
                  placeholder: "Не выбран",
                  allowClear: true,
                  style: { width: 600 },
                  mode: "multiple",
                  showSearch: true,
                  filterOption: {
                    type: "default",
                  },
                  onChange: {
                    fn: "return handleChangeTrainings(e)",
                  },
                },
              },
            ],
          },
        ],
      },
      {
        type: "antd_divider",
      },
      {
        type: "antd_table",
        props: {
          columns: [
            {
              title: "Статус",
              dataIndex: "status",
              key: "status",
              render_type: "tag",
            },
            {
              title: "Платформа",
              dataIndex: "platform",
              key: "platform",
              render_type: "cell_desc",
            },
            {
              title: "Цена",
              dataIndex: "price",
              key: "price",
              align: "right",
              render_type: "price_and_marketing_price",
            },
            {
              title: "Название",
              dataIndex: "title",
              key: "title",
              render_type: "title_link",
            },
            {
              title: "Тренинг",
              dataIndex: "training",
              key: "training",
              render_type: "training_link",
            },
            {
              title: "Поток",
              dataIndex: "stream",
              key: "stream",
              render_type: "stream_link",
            },
            {
              title: "Сценарий",
              dataIndex: "scenario",
              key: "scenario",
              render_type: "scenario_link",
            },
            {
              title: "Данные чека",
              dataIndex: "desc_for_receipt",
              key: "desc_for_receipt",
            },
          ],
          dataSource: offers.items.map((item) => ({
            ...item,
            link: `/offers/${item.id}`,
          })),
          rowKey: "id",
          onChange: {
            fn: "return handleTableChange(e)",
          },
          pagination: {
            current: "{{currentPage}}",
            pageSize: "{{pageSize}}",
            total: offers.total,
            onShowSizeChange: {
              fn: "return onShowSizeChange(current, size)",
            },
          },
          rowClassName: {
            fn: "return record.muted ? 'tr_muted' : null",
          },
        },
      },
    ],
  };

  return render(data2, {
    vars: {
      platform,
      trainings,
      currentPage,
      pageSize,
      handleChangePlatform,
      handleChangeTrainings,
      handleTableChange,
      onShowSizeChange,
    },
  });
}

function getOfferFormJHTML(mode, trainings, platforms, initIdTraining, initSegment, initIdStream, initIdScenario, id) {
  return {
    type: "react_use_state",
    props: {
      getter: "trainingId",
      setter: "setTrainingId",
      initialValue: initIdTraining, // На бэке здесь просто нужно будет проставить статическое значение
    },
    children: [
      {
        type: "antd_form",
        props: {
          initialValues: "{{initialValues}}",
          layout: "vertical",
          onFinish: {
            fn: "return submitForm(form.getFieldsValue())",
          },
        },
        children: [
          {
            type: "antd_row",
            props: {
              gutter: [50, 0],
            },
            children: [
              {
                type: "antd_col",
                props: {
                  xs: 24,
                  md: 12,
                },
                children: [
                  {
                    type: "antd_form_item",
                    props: {
                      label: "Название",
                      name: "title",
                      rules: [{ required: true }],
                    },
                    children: [
                      {
                        type: "antd_input",
                      },
                    ],
                  },
                  {
                    type: "antd_form_item",
                    props: {
                      label: "Входит в активность",
                    },
                    children: [
                      {
                        type: "activity_async_select",
                        props: {
                          id_offer: id,
                        },
                      },
                    ],
                  },
                  {
                    type: "antd_form_item",
                    props: {
                      label: "Описание для пользователя",
                      name: "desc_for_user",
                    },
                    children: [
                      {
                        type: "antd_textarea",
                        props: {
                          autoSize: { minRows: 2 },
                        },
                      },
                    ],
                  },
                  {
                    type: "antd_form_item",
                    props: {
                      label: "Статус",
                      name: "status",
                      rules: [{ required: true }],
                    },
                    children: [
                      {
                        type: "async_select",
                        props: {
                          jrpcMethod: "admin.selector.any_offer_status.v1",
                          n8nVersion: 2,
                          title: "label",
                          idKey: "value",
                          style: { width: "100%" },
                          defaultActiveFirstOption: true,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                type: "antd_col",
                props: {
                  xs: 24,
                  md: 12,
                },
                children: [
                  {
                    type: "antd_row",
                    props: {
                      gutter: 12,
                    },
                    children: [
                      {
                        type: "antd_col",
                        props: {
                          style: {
                            display: "flex",
                            flex: "auto",
                            alignItems: "center",
                            gridGap: 10,
                          },
                        },
                        children: [
                          {
                            type: "antd_form_item",
                            props: {
                              label: "Тренинг",
                              name: "id_training",
                              rules: [{ required: true }],
                              style: {
                                flex: "auto",
                              },
                            },
                            children: [
                              {
                                type: "antd_select",
                                props: {
                                  disabled: mode === "edit",
                                  options: trainings.items.map((i) => ({ label: i.title, value: i.id })),
                                  style: { width: "100%" },
                                  showSearch: true,
                                  filterOption: {
                                    type: "default",
                                  },
                                  onChange: {
                                    fn: `
                                      form.setFieldsValue({ id_stream: '', id_scenario: '' }); 
                                      return setTrainingId(e)
                                    `,
                                  },
                                },
                              },
                            ],
                          },
                          {
                            type: "if",
                            props: {
                              conditions: [
                                {
                                  type: "string",
                                  props: {
                                    operation: "not-empty",
                                    value1: "{{trainingId}}",
                                  },
                                },
                              ],
                            },
                            children: [
                              {
                                type: "router_link",
                                props: {
                                  style: {
                                    whiteSpace: "nowrap",
                                  },
                                  // eslint-disable-next-line
                                  to: "{{e! return `/trainings/${trainingId}`;}}",
                                  target: "_blank",
                                },
                                children: [
                                  {
                                    type: "antd_space",
                                    children: [
                                      {
                                        type: "antd_icon",
                                        props: {
                                          name: "LinkOutlined",
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                type: "fragment",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "if",
                    props: {
                      conditions: [
                        {
                          type: "string",
                          props: {
                            operation: "not-empty",
                            value1: "{{trainingId}}",
                          },
                        },
                      ],
                    },
                    children: [
                      {
                        type: "fragment",
                        children: [
                          {
                            type: "react_use_state",
                            props: {
                              getter: "streamId",
                              setter: "setStreamId",
                              initialValue: initIdStream, // На бэке здесь просто нужно будет проставить статическое значение
                            },
                            children: [
                              {
                                type: "antd_row",
                                props: {
                                  gutter: 12,
                                },
                                children: [
                                  {
                                    type: "antd_col",
                                    props: {
                                      style: {
                                        display: "flex",
                                        flex: "auto",
                                        alignItems: "center",
                                        gridGap: 10,
                                      },
                                    },
                                    children: [
                                      {
                                        type: "antd_form_item",
                                        props: {
                                          label: "Поток",
                                          name: "id_stream",
                                          rules: [{ required: true }],
                                          style: {
                                            flex: "auto",
                                          },
                                        },
                                        children: [
                                          {
                                            type: "async_select",
                                            props: {
                                              jrpcMethod: "admin.selector.any_stream.v1",
                                              jrpcParams: {
                                                id_training: "{{trainingId}}",
                                              },
                                              n8nVersion: 2,
                                              title: "label",
                                              idKey: "value",
                                              style: { width: "100%" },
                                              showSearch: true,
                                              filterOption: {
                                                type: "default",
                                              },
                                              deps: ["{{trainingId}}"],
                                              onChange: {
                                                fn: `return setStreamId(e);`,
                                              },
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        type: "if",
                                        props: {
                                          conditions: [
                                            {
                                              type: "string",
                                              props: {
                                                operation: "not-empty",
                                                value1: "{{streamId}}",
                                              },
                                            },
                                          ],
                                        },
                                        children: [
                                          {
                                            type: "router_link",
                                            props: {
                                              style: {
                                                whiteSpace: "nowrap",
                                              },
                                              // eslint-disable-next-line
                                              to: "{{e! return `/trainings/${trainingId}/streams/${streamId}`;}}",
                                              target: "_blank",
                                            },
                                            children: [
                                              {
                                                type: "antd_space",
                                                children: [
                                                  {
                                                    type: "antd_icon",
                                                    props: {
                                                      name: "LinkOutlined",
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                          {
                                            type: "fragment",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },

                          {
                            type: "react_use_state",
                            props: {
                              getter: "scenarioId",
                              setter: "setScenarioId",
                              initialValue: initIdScenario, // На бэке здесь просто нужно будет проставить статическое значение
                            },
                            children: [
                              {
                                type: "antd_row",
                                props: {
                                  gutter: 12,
                                },
                                children: [
                                  {
                                    type: "antd_col",
                                    props: {
                                      style: {
                                        display: "flex",
                                        flex: "auto",
                                        alignItems: "center",
                                        gridGap: 10,
                                      },
                                    },
                                    children: [
                                      {
                                        type: "antd_form_item",
                                        props: {
                                          label: "Сценарий",
                                          name: "id_scenario",
                                          rules: [{ required: true }],
                                          style: {
                                            flex: "auto",
                                          },
                                        },
                                        children: [
                                          {
                                            type: "async_select",
                                            props: {
                                              jrpcMethod: "admin.scenario.list.v1",
                                              jrpcParams: {
                                                id_training: "{{trainingId}}",
                                              },
                                              title: "title",
                                              style: { width: "100%" },
                                              showSearch: true,
                                              filterOption: {
                                                type: "default",
                                              },
                                              deps: ["{{trainingId}}"],
                                              onChange: {
                                                fn: `return setScenarioId(e);`,
                                              },
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        type: "if",
                                        props: {
                                          conditions: [
                                            {
                                              type: "string",
                                              props: {
                                                operation: "not-empty",
                                                value1: "{{scenarioId}}",
                                              },
                                            },
                                          ],
                                        },
                                        children: [
                                          {
                                            type: "router_link",
                                            props: {
                                              style: {
                                                whiteSpace: "nowrap",
                                              },
                                              // eslint-disable-next-line
                                              to: "{{e! return `/trainings/${trainingId}/lessons?scenario=${scenarioId}`;}}",
                                              target: "_blank",
                                            },
                                            children: [
                                              {
                                                type: "antd_space",
                                                children: [
                                                  {
                                                    type: "antd_icon",
                                                    props: {
                                                      name: "LinkOutlined",
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                          {
                                            type: "fragment",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: "null",
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            type: "react_use_state",
            props: {
              getter: "target",
              setter: "setTarget",
              initialValue: initSegment ? "segment" : "all",
            },
            children: [
              {
                type: "antd_row",
                props: {
                  gutter: 12,
                },
                children: [
                  {
                    type: "antd_col",
                    children: [
                      {
                        type: "antd_form_item",
                        props: {
                          label: "Отображение",
                        },
                        children: [
                          {
                            type: "antd_radio_group",
                            props: {
                              options: [
                                { label: "Всем", value: "all" },
                                { label: "Сегменту", value: "segment" },
                              ],
                              onChange: {
                                fn: "form.resetFields(['id_segment']); return setTarget(e.target.value)",
                              },
                              value: "{{target}}",
                              optionType: "button",
                              buttonStyle: "solid",
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "antd_col",
                    props: {
                      style: {
                        display: "flex",
                        flex: "auto",
                        alignItems: "center",
                        gridGap: 10,
                      },
                    },
                    children: [
                      {
                        type: "if",
                        props: {
                          conditions: [
                            {
                              type: "string",
                              props: {
                                operation: "eq",
                                value1: "{{target}}",
                                value2: "segment",
                              },
                            },
                          ],
                        },
                        children: [
                          {
                            type: "react_use_state",
                            props: {
                              getter: "selectedSegment",
                              setter: "setSelectedSegment",
                              initialValue: initSegment || "",
                            },
                            children: [
                              {
                                type: "antd_form_item",
                                props: {
                                  label: " ",
                                  name: "id_segment",
                                  style: {
                                    flex: "auto",
                                  },
                                  rules: [{ required: true }],
                                  required: false,
                                },
                                children: [
                                  {
                                    type: "async_select",
                                    props: {
                                      jrpcMethod: "admin.segment.list.v1",
                                      n8nVersion: 2,
                                      showSearch: true,
                                      filterOption: {
                                        type: "default",
                                      },
                                      onChange: {
                                        fn: "setSelectedSegment(e)",
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                type: "if",
                                props: {
                                  conditions: [
                                    {
                                      type: "string",
                                      props: {
                                        operation: "eq",
                                        value1: "{{selectedSegment}}",
                                        value2: "",
                                      },
                                    },
                                  ],
                                },
                                children: [
                                  {
                                    type: "fragment",
                                  },
                                  {
                                    type: "router_link",
                                    props: {
                                      style: {
                                        whiteSpace: "nowrap",
                                      },
                                      // eslint-disable-next-line
                                      to: "{{e! return `/segments/${selectedSegment}`;}}",
                                    },
                                    children: [
                                      {
                                        type: "antd_space",
                                        children: [
                                          {
                                            type: "text_node",
                                            props: {
                                              value: "Открыть сегмент",
                                            },
                                          },
                                          {
                                            type: "antd_icon",
                                            props: {
                                              name: "LinkOutlined",
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            type: "fragment",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            type: "antd_form_item",
            props: {
              label: "Изображение",
              name: "image",
            },
            children: [
              {
                type: "antd_input",
              },
            ],
          },

          {
            type: "antd_form_item",
            props: {
              label: "Цена",
              name: "price",
              rules: [{ required: true }],
            },
            children: [
              {
                type: "antd_input_number",
              },
            ],
          },
          {
            type: "antd_form_item",
            props: {
              label: "Маркетинговая цена",
              name: "marketing_price",
            },
            children: [
              {
                type: "antd_input_number",
              },
            ],
          },
          {
            type: "antd_form_item",
            props: {
              label: "Платформа",
              name: "platforms",
              rules: [{ required: true }],
            },
            children: [
              {
                type: "antd_select",
                props: {
                  options: _.get(platforms, "items", []).map((i) => ({ label: i.desc, value: i.id })),
                  style: { width: 300 },
                  mode: "multiple",
                },
              },
            ],
          },
          {
            type: "antd_form_item",
            props: {
              label: "Описание для чека",
              name: "desc_for_receipt",
              rules: [{ required: true }],
            },
            children: [
              {
                type: "antd_textarea",
                props: {
                  autoSize: { minRows: 3 },
                  maxLength: 128,
                  showCount: true,
                },
              },
            ],
          },
          {
            type: "antd_form_item",
            children: [
              {
                type: "antd_button",
                props: {
                  type: "primary",
                  htmlType: "submit",
                },
                children: [
                  {
                    type: "text_node",
                    props: {
                      value: "Сохранить",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
}

export function OfferCreate() {
  const query = useQuery();
  const origId = query.get("id_offer") || null;

  // FIXME use useN8n2("admin.screen.offer_create.v1", { id_orig: origId }, [origId]) instead
  const { loading, error, data = [] } = useManyJsonRpc(
    useN8n2("admin.offer.details.v1", { id: origId }, [origId], { condition: !!origId }),
    useN8n2("admin.training.list.v1"),
    useN8n("admin.platform.list.v1")
  );

  const create = useN8n2Defer("admin.offer.create.v1");

  function submitForm(submission) {
    create(submission)
      .then(({ id }) => {
        history.push(`/offers/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  if (loading) {
    return <Skeleton active />;
  }

  if (error) {
    return <PageError />;
  }

  const [origData, trainings, platforms] = data;

  const jhtml = {
    type: "fragment",
    children: [
      {
        type: "antd_breadcrumb",
        children: [
          {
            type: "antd_breadcrumb_item",
            children: [
              {
                type: "router_link",
                props: {
                  to: "/offers",
                },
                children: [
                  {
                    type: "text_node",
                    props: {
                      value: "Сингл-офферы",
                    },
                  },
                ],
              },
            ],
          },
          {
            type: "antd_breadcrumb_item",
            children: [
              {
                type: "text_node",
                props: {
                  value: "Новый оффер",
                },
              },
            ],
          },
        ],
      },
      {
        type: "antd_divider",
      },
      getOfferFormJHTML(
        "create",
        trainings,
        platforms,
        _.get(origData, "id_training"),
        _.get(origData, "id_segment"),
        _.get(origData, "id_stream"),
        _.get(origData, "id_scenario"),
        origId
      ),
    ],
  };

  return render(jhtml, {
    vars: {
      initialValues: origData,
      submitForm,
    },
  });
}

export function OfferEdit() {
  const { id, tab = "form" } = useParams();

  // FIXME use useN8n2("admin.screen.offer_update.v1", { id }, [id]) instead
  const { loading, error, data = [] } = useManyJsonRpc(
    useN8n2("admin.offer.details.v1", { id }, [id]),
    useN8n2("admin.training.list.v1"),
    useN8n("admin.platform.list.v1")
  );

  const update = useN8n2Defer("admin.offer.update.v1");
  const remove = useN8n2Defer("admin.offer.delete.v1");

  function submitForm(submission) {
    update({ ...submission, id })
      .then(() => {
        showSuccessSaveMessage();
        history.push(`/offers/${id}`);
      })
      .catch((e) => {
        showFailureMessage(e.message);
      });
  }

  async function onDelete() {
    try {
      await remove({ id });
      notification.success({
        description: "Оффер удалён",
      });
      history.push(`/offers`);
    } catch (e) {
      notification.error({
        description: "Не удалось удалить оффер",
      });
    }
  }

  async function onCopy() {
    history.push(`/offers/create?id_offer=${id}`);
  }

  if (loading) {
    return <Skeleton active />;
  }

  if (error) {
    return <PageError />;
  }

  const [offerData, trainings, platforms] = data;

  const jhtml = {
    type: "fragment",
    children: [
      {
        type: "antd_space",
        props: {
          style: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
        },
        children: [
          {
            type: "antd_breadcrumb",
            children: [
              {
                type: "antd_breadcrumb_item",
                children: [
                  {
                    type: "router_link",
                    props: {
                      to: "/offers",
                    },
                    children: [
                      {
                        type: "text_node",
                        props: {
                          value: "Сингл-офферы",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                type: "antd_breadcrumb_item",
                children: [
                  {
                    type: "antd_space",
                    props: {
                      size: 10,
                    },
                    children: [
                      {
                        type: "antd_text",
                        children: [
                          {
                            type: "text_node",
                            props: {
                              value: offerData.id,
                            },
                          },
                        ],
                      },
                      {
                        type: "antd_text",
                        props: {
                          type: "secondary",
                        },
                        children: [
                          {
                            type: "text_node",
                            props: {
                              value: offerData.id.replaceAll("-", "_"),
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: "antd_space",
            children: [
              {
                type: "antd_popconfirm",
                props: {
                  title: "Создать копию?",
                  okText: "Да",
                  cancelText: "Нет",
                  onConfirm: { fn: "return onCopy(e)" },
                },
                children: [
                  {
                    type: "antd_button",
                    props: {
                      type: "primary",
                      ghost: true,
                    },
                    children: [
                      {
                        type: "text_node",
                        props: {
                          value: "Создать копию",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                type: "antd_popconfirm",
                props: {
                  title: "Удалить оффер?",
                  okText: "Удалить",
                  cancelText: "Отмена",
                  onConfirm: { fn: "return onDelete(e)" },
                },
                children: [
                  {
                    type: "antd_button",
                    props: {
                      type: "danger",
                      ghost: true,
                      icon: "DeleteOutlined",
                    },
                    children: [
                      {
                        type: "text_node",
                        props: {
                          value: "Удалить оффер",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: "antd_divider",
      },
      {
        type: "async_tabs",
        props: {
          activeKey: "{{tab}}",
          resolvePath: {
            fn: `return '/offers/${id}/' + key`,
          },
          items: [
            {
              key: "form",
              async: false,
              title: "Форма",
              children: getOfferFormJHTML(
                "edit",
                trainings,
                platforms,
                _.get(offerData, "id_training"),
                _.get(offerData, "id_segment"),
                _.get(offerData, "id_stream"),
                _.get(offerData, "id_scenario"),
                id
              ),
            },
            {
              key: "packages",
              async: false,
              title: `В пакетах (${_.get(offerData, "packages.count")})`,
              children: _.get(offerData, "packages.item_table"),
            },
          ],
        },
      },
    ],
  };

  return render(jhtml, {
    vars: {
      tab,
      initialValues: offerData,
      submitForm,
      onCopy,
      onDelete,
    },
  });
}

function OfferSubscribesTable() {
  const { loading, data = [], error } = useN8n2("admin.offer_subscribe.list.v1");

  function createNew() {
    history.push("/offers/subscribes/create");
  }

  const columns = [
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (value) => <Tag color={_.get(value, "color")}>{_.get(value, "title")}</Tag>,
    },
    {
      title: "#",
      dataIndex: "position",
      key: "position",
      width: 40,
      render: (pos) => pos || "-",
    },
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      editable: true,
      render: (title, record) => <Link to={`/offers/subscribes/${record.id}`}>{title}</Link>,
    },
    {
      title: "Пакет тренингов",
      dataIndex: "training_package",
      key: "training_package",
      render: (tp) => <TrainingPackageCellRenderer data={tp} />,
    },
    {
      title: "Период",
      dataIndex: "period",
      key: "period",
      render: (period) => _.get(period, "desc") || "-",
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (price, record) => <OfferPriceCellRenderer price={price} marketing_price={record.marketing_price} />,
    },
    {
      title: "Платформа",
      dataIndex: "platform",
      key: "platform",
      render: (platform) => _.get(platform, "desc") || "-",
    },
  ];

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Button type="primary" onClick={createNew}>
        + Добавить
      </Button>
      <Divider />
      <Table
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        pagination={{
          pageSize: 50,
        }}
        loading={loading}
        rowClassName={(record) => (record.muted ? "tr_muted" : null)}
      />
    </>
  );
}

function OfferSubscribeBaseForm({ data, submitForm }) {
  const [target, setTarget] = useState(_.get(data, "id_segment") ? "segment" : "all");
  const [selectedSegment, setSelectedSegment] = useState(_.get(data, "id_segment") || "");
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      initialValues={data}
      layout="vertical"
      onFinish={() => {
        submitForm(form.getFieldsValue());
      }}
    >
      <Row gutter={[50, 0]}>
        <Col xs={24} md={12}>
          <Form.Item label="Название" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Входит в активность" name="id_activity" rules={[{ required: true }]}>
            <ActivityAsyncSelect id_offer_subscribe={data.id} />
          </Form.Item>

          <Form.Item label="Статус" name="status" rules={[{ required: true }]}>
            <AsyncSelect2
              jrpcMethod="admin.selector.any_offer_status.v1"
              n8nVersion={2}
              title="label"
              idKey="value"
              style={{ width: "100%" }}
              defaultActiveFirstOption
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="Пакет тренингов" name="id_training_package" rules={[{ required: true }]}>
            <AsyncSelect2
              jrpcMethod="admin.training_package.list.v1"
              title="title"
              showSearch={true}
              filterOption={defaultSearchFilter}
              style={{ width: 300 }}
            />
          </Form.Item>

          <Form.Item label="Период" name="period" rules={[{ required: true }]}>
            <AsyncSelect2 jrpcMethod="admin.offer_subscribe_period.list.v1" title="desc" style={{ width: 300 }} />
          </Form.Item>

          <Form.Item label="Группа промокодов" name="id_promo_group">
            <AsyncSelectWithLink path="/promo_groups/" target="_blank" title="name" resource="promo_group" autoFocus={false} allowClear />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col>
          <Form.Item label="Отображение">
            <Radio.Group
              options={[
                { label: "Всем", value: "all" },
                { label: "Сегменту", value: "segment" },
              ]}
              onChange={(e) => {
                form.resetFields(["id_segment"]);
                return setTarget(e.target.value);
              }}
              value={target}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            display: "flex",
            flex: "auto",
            alignItems: "center",
            gridGap: 10,
          }}
        >
          {target === "segment" && (
            <>
              <Form.Item
                label=" "
                name="id_segment"
                style={{
                  flex: "auto",
                }}
                rules={[{ required: true }]}
                required={false}
              >
                <AsyncSelect2
                  jrpcMethod="admin.segment.list.v1"
                  n8nVersion={2}
                  showSearch={true}
                  filterOption={defaultSearchFilter}
                  onChange={(e) => setSelectedSegment(e)}
                />
              </Form.Item>
              {selectedSegment && (
                <Link
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  to={`/segments/${selectedSegment}`}
                >
                  <Space>
                    <>Открыть сегмент</>
                    <LinkOutlined />
                  </Space>
                </Link>
              )}
            </>
          )}
        </Col>
      </Row>

      <Form.Item label="Изображение" name="image">
        <Input />
      </Form.Item>

      <Form.Item label="Цена" name="price" rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>

      <Form.Item label="Маркетинговая цена" name="marketing_price">
        <InputNumber />
      </Form.Item>

      <Form.Item label="Платформа" name="platforms" rules={[{ required: true }]}>
        <AsyncSelect2 mode="multiple" jrpcMethod="admin.platform.list.v1" title="desc" style={{ width: 300 }} />
      </Form.Item>

      <Form.Item label="Описание для чека" name="desc_for_receipt" rules={[{ required: true }]}>
        <Input.TextArea autoSize={{ minRows: 3 }} maxLength={128} showCount />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function OfferSubscribeCreate() {
  const create = useN8n2Defer("admin.offer_subscribe.create.v1");

  function submitForm(submission) {
    create(submission)
      .then(({ id }) => {
        history.push(`/offers/subscribes/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/offers/subscribes">Офферы-подписки</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Новый оффер</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <OfferSubscribeBaseForm data={{}} submitForm={submitForm} />
    </>
  );
}

function OfferIdView({ id }) {
  return (
    <Space size={10}>
      <Text>{id}</Text>
      {id && <Text type="secondary">({id.replaceAll("-", "_")})</Text>}
    </Space>
  );
}

export function OfferSubscribeEdit() {
  const { id, tab = "form" } = useParams();

  const { loading, data = {}, error } = useN8n2("admin.offer_subscribe.details.v1", { id }, [id]);
  const update = useN8n2Defer("admin.offer_subscribe.update.v1");
  const remove = useN8n2Defer("admin.offer_subscribe.delete.v1");

  function submitForm(submission) {
    update({ ...submission, id })
      .then(() => {
        showSuccessSaveMessage();
        history.push(`/offers/subscribes/${id}`);
      })
      .catch((e) => {
        showFailureMessage(e.message);
      });
  }

  async function onDelete() {
    try {
      await remove({ id });
      notification.success({
        description: "Оффер-подписка удалён",
      });
      history.push(`/offers/subscribes`);
    } catch (e) {
      notification.error({
        description: "Не удалось удалить оффер-подписку",
      });
    }
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/offers/subscribes">Офферы-подписки</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <OfferIdView id={data.id} />
          </Breadcrumb.Item>
        </Breadcrumb>
        <Popconfirm disabled={loading} title="Удалить оффер-подписку?" onConfirm={onDelete} okText="Удалить" cancelText="Отмена">
          <Button type="danger" ghost={true} icon={<DeleteOutlined />} disabled={loading}>
            Удалить оффер-подписку
          </Button>
        </Popconfirm>
      </CardHeader>

      <Divider />

      {loading ? (
        <Skeleton active />
      ) : (
        <MyTabs
          activeKey={tab}
          resolvePath={(key) => `/offers/subscribes/${id}/${key}`}
          items={[
            {
              key: "form",
              title: "Форма",
              children: <OfferSubscribeBaseForm data={data} submitForm={submitForm} />,
            },
            {
              key: "packages",
              title: `В пакетах (${_.get(data, "packages.count")})`,
              children: render(_.get(data, "packages.item_table")),
            },
          ]}
        />
      )}
    </>
  );
}
