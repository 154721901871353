import React, { useRef, useState } from "react";
import { Typography, Space } from "antd";
import ReactPlayer from "react-player";

import { Duration } from "./VideoClip";

const { Link } = Typography;

function VideoTimeCodeLink({ time, title, onClick }) {
  return (
    <Space size={5} align="flex-start" style={{ lineHeight: "1.2rem" }}>
      <Duration seconds={time / 1000} />
      <Link onClick={onClick} style={{ textDecoration: "underline", whiteSpace: "pre-wrap" }}>
        {title}
      </Link>
    </Space>
  );
}

export function VideoTimeCodesPreviewer({ url, items }) {
  const playerRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);

  const seekTo = (ms) => {
    if (!duration) {
      return;
    }

    const target = ms / 1000 / duration;

    setPlaying(true);
    playerRef.current.seekTo(target);
  };

  return (
    <Space direction="vertical" style={{ marginTop: "1px" }}>
      <ReactPlayer
        ref={playerRef}
        width="250px"
        height="250px"
        url={url}
        controls={true}
        playing={playing}
        playsinline={true}
        onPlay={() => {
          setPlaying(true);
        }}
        onDuration={setDuration}
      />

      {Array.isArray(items) && (
        <Space direction="vertical" size={2}>
          {items.map((item, idx) => (
            <VideoTimeCodeLink key={idx} time={item.started_at} title={item.title} onClick={() => seekTo(item.started_at)} />
          ))}
        </Space>
      )}
    </Space>
  );
}
