import { callN8n2 } from "../../helpers/helpers";

export function gtStoragePlugin(editor, { id }) {
  editor.Storage.add("gt-storage", {
    async load() {
      return callN8n2("admin.page.load_template.v1", { id }).then((r) => r.data);
    },

    async store(data) {
      const component = editor.Pages.getSelected().getMainComponent();

      return callN8n2("admin.page.save_template.v1", {
        id,
        data: {
          ...data,
          html: editor.getHtml({ component }),
          css: editor.getCss({ component }),
        },
      });
    },
  });
}
