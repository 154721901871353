import React, { useState } from "react";
import { Button as AntdButton, notification } from "antd";
import _ from "lodash";

import { imgpushPath } from "../constants";
import { fetchWithJsonRpc } from "../helpers/helpers";

function AsyncFileLink({ href, ...props }) {
  const [opening, setOpening] = useState(false);

  const openFile = async (e) => {
    e.preventDefault();

    if (opening) {
      return;
    }

    try {
      setOpening(true);

      if (!href) {
        return null;
      }

      const resp = await fetchWithJsonRpc("admin.imgpush.get_token.v1");

      window.open(`${href}?token=${resp.token}`);
    } catch (e) {
      notification.error({
        description: "Не удалось открыть файл. Попробуйте снова",
      });
    } finally {
      setOpening(false);
    }
  };

  return <AntdButton href={href} onClick={openFile} {...props} loading={opening} />;
}

export function FileLink(props) {
  const { href } = props;
  const isImgpush = _.isString(href) && href.includes(imgpushPath);

  const LinkComponent = isImgpush ? AsyncFileLink : AntdButton;
  return <LinkComponent type="link" target="_blank" {...props} />;
}
