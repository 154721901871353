import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import presetWebpage from "grapesjs-preset-webpage";
import countdown from "grapesjs-component-countdown";
import parserPostCSS from "grapesjs-parser-postcss";
import _ from "lodash";

import customCode from "./plugins/grapesjs-custom-code-1.0.1";
import { gtStoragePlugin } from "./plugins/gt-storage";
import { gtCustomPlugin } from "./plugins/gt-custom";
import { BLUEPRINT_CSS, BOOTSTRAP_CSS, BOOTSTRAP_THEME_CSS, GTLIB_SCRIPT } from "./constants";

const escapeName = (name) => `${name}`.trim().replace(/([^a-z0-9\w-:/]+)/gi, "-"); // fix for tailwind classes like "w-1/2"

export function initGrapesJSEditor(container, options) {
  return grapesjs.init({
    allowScripts: true,
    container,
    height: "100vh",
    showOffsets: true,
    noticeOnUnload: true,
    fromElement: false,
    plugins: [presetWebpage, countdown, customCode, parserPostCSS, gtStoragePlugin, gtCustomPlugin],
    pluginsOpts: {
      [gtStoragePlugin]: options,
      [gtCustomPlugin]: options,
      [presetWebpage]: {
        blocks: ["link-block"],
        showStylesOnChange: false,
      },
      [customCode]: {
        blockCustomCode: {
          category: "Extra",
          label: "HTML & CSS",
        },
      },
    },
    storageManager: {
      id: "",
      type: "gt-storage",
      autosave: false,
    },
    selectorManager: {
      componentFirst: true,
      escapeName,
    },
    canvas: {
      scripts: [GTLIB_SCRIPT],
      styles: [BOOTSTRAP_CSS, BOOTSTRAP_THEME_CSS, BLUEPRINT_CSS],
      frameContent: `<!DOCTYPE html><head>${_.get(options, "page.head_mixin")}</head>`,
    },
  });
}
