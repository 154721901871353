export const addAnchor = (editor, opts) => {
  const script = function () {
    this.innerHTML = `<p>Anchor</p>`;
  };

  editor.Components.addType("anchor", {
    model: {
      defaults: {
        script,
        "script-export": function () {},
        tagName: "a",
        attributes: {
          name: "",
          class: "gt-anchor",
        },
        traits: ["name"],
        highlightable: true,
      },
    },
  });
};
